import { Project } from '../types';
import { ProjectActionTypes } from './action.types';
import { ActionBluePrint } from '../core/types';

export enum ProjectModalType {
    EDIT = 'EDIT',
    ADD_EXPERIMENTS = 'ADD_EXPERIMENTS',
    REMOVE_EXPERIMENTS = 'REMOVE_EXPERIMENTS'
};

export type ProjectState = {
  loading: boolean,
  project?: Project,
  showModal: boolean,
  modalType: ProjectModalType,
};

export type EditProjectParams = {
  name?: string,
  keywords?: string,
  description?: string,
  [key: string]: string | undefined
}

export type ProjectAction<Action> = ActionBluePrint<Action, ProjectState>;
export type ProjectActions = ActionBluePrint<ProjectActionTypes, ProjectState>;
export type ProjectFailureActions =
  | ProjectActionTypes.FetchFailure
  | ProjectActionTypes.EditFailure
  | ProjectActionTypes.DeleteFailure
  | ProjectActionTypes.AddExperimentFailure
  | ProjectActionTypes.RemoveExperimentFailure;
export type ProjectPendingActions =
  | ProjectActionTypes.FetchPending
  | ProjectActionTypes.EditPending
  | ProjectActionTypes.DeletePending
  | ProjectActionTypes.AddExperimentPending
  | ProjectActionTypes.RemoveExperimentPending;