import React, { FC, FormEvent } from 'react';
import { Form, Input, Select } from 'antd';

import Item from '../../components/form.item.component';
import { OnChangeEvent } from '../../core/types';
import { UserRole } from '../../types';

const { Option } = Select;

type Inputs = {
  firstName?: string,
  lastName?: string,
  email?: string,
  role?: UserRole
}

type Props = {
  inputs: Inputs,
  onChange: (event: OnChangeEvent) => void,
}

const CreateUserForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value })
  }

  return <>
    <Form layout="vertical">
      <Item label="First name" required>
        <Input name="firstName" value={props.inputs.firstName} onChange={onChange}/>
      </Item>
      <Item label="Last name" required>
        <Input name="lastName" value={props.inputs.lastName} onChange={onChange}/>
      </Item>
      <Item label="Email" required>
        <Input name="email" value={props.inputs.email} onChange={onChange}/>
      </Item>
      <Item label="Role" required>
        <Select
          showSearch
          value={props.inputs.role || undefined}
          placeholder="Select a role"
          onSelect={(value: string) => { props.onChange({ name: 'role', value }); }}
        >
          {Object.values(UserRole).map(role => 
            <Option key={role} value={role}>{role}</Option>
          )}
        </Select>
      </Item>
    </Form>
  </>
} 

export default CreateUserForm;