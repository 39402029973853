import { ActionBluePrint } from '../core/types';
import { ExperimentActionTypes } from './action.types';
import { Experiment } from '../types';

export type Data = {
  value: string,
  time: string
}

export type AddSensorExperimentParam = {
  sensor: string,
  location?: string,
  position?: string
}

export type UpdateSensorExperimentParam = {
  location?: string,
  position?: string,
  [key: string]: string | undefined
}

export type SelectedColumnSensor = {
  [key: number]: string
}

export enum DataUploadSteps {
  File,
  Data,
  Sensor,
  Upload
}

export enum ExperimentModalType {
  EDIT = 'EDIT',
  ADD_BUILDINGS = 'ADD_BUILDINGS',
  REMOVE_BUILDINGS = 'REMOVE_BUILDINGS',
  ADD_PARTICIPANTS = 'ADD_PARTICIPANTS',
  REMOVE_PARTICIPANTS = 'REMOVE_PARTICIPANTS',
  ADD_SENSOR = 'ADD_SENSOR',
  UPDATE_SENSOR = 'UPDATE_SENSOR',
  REMOVE_SENSOR = 'REMOVE_SENSOR',
  UPLOAD_DATA = 'UPLOAD_DATA',
  DOWNLOAD_DATA = 'DOWNLOAD_DATA'
};

export type ExperimentState = {
  loading: boolean,
  showModal: boolean,
  experiment?: Experiment,
  modalType: ExperimentModalType,
};

export type ExperimentAction<Action> = ActionBluePrint<Action, ExperimentState>;

export type ExperimentActions = ActionBluePrint<ExperimentActionTypes, ExperimentState>;
export type ExperimentFailureActions =
  | ExperimentActionTypes.FetchFailure
  | ExperimentActionTypes.EditFailure
  | ExperimentActionTypes.DeleteFailure
  | ExperimentActionTypes.AddBuildingFailure
  | ExperimentActionTypes.RemoveBuildingFailure
  | ExperimentActionTypes.AddParticipantFailure
  | ExperimentActionTypes.RemoveParticipantFailure
  | ExperimentActionTypes.AddSensorFailure
  | ExperimentActionTypes.RemoveSensorFailure
  | ExperimentActionTypes.UpdateSensorFailure
  | ExperimentActionTypes.UploadDataFailure
  | ExperimentActionTypes.RemoveDataFailure
  | ExperimentActionTypes.FetchDataFailure;

export type ExperimentPendingActions =
  | ExperimentActionTypes.EditPending
  | ExperimentActionTypes.DeletePending
  | ExperimentActionTypes.FetchPending
  | ExperimentActionTypes.AddBuildingPending
  | ExperimentActionTypes.RemoveBuildingPending
  | ExperimentActionTypes.AddParticipantPending
  | ExperimentActionTypes.RemoveParticipantPending
  | ExperimentActionTypes.AddSensorPending
  | ExperimentActionTypes.UpdateSensorPending
  | ExperimentActionTypes.RemoveSensorPending
  | ExperimentActionTypes.UploadDataSuccess
  | ExperimentActionTypes.UploadDataPending
  | ExperimentActionTypes.RemoveDataPending
  | ExperimentActionTypes.FetchDataPending;


