import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { Layout, Row, Col, Typography, Popconfirm } from 'antd';

import Menu from '../../core/components/menu.component';
import Text from '../../components/text.component';
import EditBuildingView from './edit.building.view';
import Label from '../../components/label.component';
import FloatRight from '../../components/float.right.component';
import { BuildingStateRedux } from '../types';
import { CreateBuildingParam } from '../../buildings/types';
import Loading from '../../components/loading.component';
import HeatingSystemView from '../../heatingsystem/components';
import FilesView from '../../files/components/';
import { Container, Button } from '../../components';
import { Iuuid } from '../../types';
import { useFetchField } from '../../hooks/field';
import { buildingTypePath, buildingStatesPath } from '../../server';

const { Content } = Layout;
const { Title } = Typography;

const titleColumns = {
  xs: { span: 7},
  sm: { span: 10},
  md: { span: 14},
  lg: { span: 17},
  xl: { span: 18},
}
const buttonColumns = {
  xs: { span: 17},
  sm: { span: 14},
  md: { span: 10},
  lg: { span: 7},
  xl: { span: 6},
}

const typeColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const stateColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const perfColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const plansColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const blowerColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const accubColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const gasColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const ownerColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const descrColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const construColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

type Props = RouteComponentProps<Iuuid> & BuildingStateRedux & {
  toggleModal: () => void,
  getBuilding: (uuid: string) => void,
  deleteBuilding: (uuid: string) => Promise<void>,
  updateBuilding: (uuid: string, param: Partial<CreateBuildingParam>) => Promise<void>,
}

const BuildingView: FC<Props> = (props) => {
  const [deleted, setDeleted] = useState(false);

  const buildingTypes = useFetchField(buildingTypePath);
  const buildingStates = useFetchField(buildingStatesPath);
  const getBuilding = props.getBuilding;
  const buildingUuid = props.match.params.uuid;
  useEffect(() => getBuilding(buildingUuid), [getBuilding, buildingUuid]);
  
  const onDelete = async () => {
    await props.deleteBuilding(props.match.params.uuid);
    setDeleted(true);
  }

  return <>
    {deleted && <Redirect to="/buildings"/>}
    <Layout>
      <Menu selected="buildings"/>
      <Content style={{ padding: '20px 50px' }}>
        <Loading spinning={props.loading}>
          <Row>
            <Col>
            <Title level={3}>
              {
                props.building &&
                `${props.building.street} ${props.building.number}, ${props.building.postal} ${props.building.city}, ${props.building.country}`  
              }
            </Title>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col {...titleColumns}>
                <Title level={4}>
                  General information
                </Title>
              </Col>
              <Col {...buttonColumns}>
                <FloatRight>
                  <Button style={{marginRight: '10px'}} onClick={props.toggleModal}>
                    edit building
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this building?"
                    onConfirm={onDelete}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <Button delete={1}>
                      delete building
                    </Button>
                  </Popconfirm>
                </FloatRight>
              </Col>
            </Row>
            <Row>
              <Col {...typeColumns}>
                <Row>
                  <Label>
                    TYPE
                  </Label>
                  <Text>
                    {props.building && props.building.type.name}
                  </Text>
                </Row>
              </Col>
              <Col {...stateColumns}>
                  <Row>
                    <Label>
                      STATE
                    </Label>
                    <Text>
                      {props.building && props.building.state && props.building.state.name}
                    </Text>
                  </Row>
              </Col>
              <Col {...perfColumns}>
                <Row>
                  <Label>
                    EPC
                  </Label>
                  <Text>
                    {props.building && props.building.performanceCalculations ? 'Yes' : 'No'}
                  </Text>
                </Row>
              </Col>
              <Col {...plansColumns}>
                <Row>
                  <Label>
                  PLANS
                  </Label>
                  <Text>
                    {props.building && props.building.plans ? 'Yes' : 'No'}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col {...blowerColumns}>
                <Row>
                  <Label>
                    BLOWER DOOR MEASUREMENTS
                  </Label>
                  <Text>
                    {props.building && props.building.blowerdoor ? 'Yes' : 'No'}
                  </Text>
                </Row>
              </Col>
              <Col {...accubColumns}>
                <Row>
                  <Label>
                    ACCUBALANCE MEASUREMENTS
                  </Label>
                  <Text>
                    {props.building && props.building.accubalance ? 'Yes' : 'No'}
                  </Text>
                </Row>
              </Col>
              <Col {...gasColumns}>
                <Row>
                  <Label>
                    SMOKE GAS ANALYSIS
                  </Label>
                  <Text>
                    {props.building && props.building.smokeGas ? 'Yes' : 'No'}
                  </Text>
                </Row>
              </Col>
              <Col {...ownerColumns}>
                <Row>
                  <Label>
                    OWNER
                  </Label>
                  <Text>
                    {
                      props.building &&
                      `${props.building.creator.firstName} ${props.building.creator.lastName}`
                    }
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col {...descrColumns}>
                <Row>
                  <Label>
                    DESCRIPTION
                  </Label>
                  <Text>
                    {props.building && props.building.description}
                  </Text>
                </Row>
              </Col>
              <Col {...construColumns}>
                <Row>
                  <Label>
                    CONSTRUCTION OR RENOVATION DATE
                  </Label>
                  <Text>
                    {props.building && props.building.constructionDate && moment(Number(props.building.constructionDate)).format('DD/MM/YYYY')}
                  </Text>
                </Row>
              </Col>
            </Row>
            { props.building &&
              <EditBuildingView 
                building={props.building}
                loading={props.loading}
                showModal={props.showModal}
                buildingTypes={buildingTypes}
                buildingStates={buildingStates}
                toggleModal={props.toggleModal}
                editBuilding={props.updateBuilding}
              />
            }
          </Container>
          {
            props.building &&
            <HeatingSystemView
              type='building'
              parentUuid={props.building.uuid}
              building={props.building}
            />
          }
          { props.building && 
          <FilesView
            type='building'
            building={props.building}
            parentUuid={props.building.uuid}
          />
        }
        </Loading>
      </Content>
    </Layout>
  </>
}

export default BuildingView;