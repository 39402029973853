import { BuildingsActionTypes } from './action.types';
import { BuildingsAction, BuildingsState, BuildingsPendingActions, BuildingsFailureActions } from './types';
import { Filter } from '../filter/types';
import { Building } from '../types';

export const toggleModal = (): BuildingsAction<BuildingsActionTypes.ToggleModal> => ({
  type: BuildingsActionTypes.ToggleModal,
  reducer(state): BuildingsState {
    return {
      ...state,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: BuildingsPendingActions): BuildingsAction<BuildingsPendingActions> => ({
  type,
  reducer(state): BuildingsState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: BuildingsFailureActions): BuildingsAction<BuildingsFailureActions> => ({
  type,
  reducer(state): BuildingsState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const updateFilters = (filters: Filter[]): BuildingsAction<BuildingsActionTypes.UpdateFilter> => ({
  type: BuildingsActionTypes.UpdateFilter,
  reducer(state): BuildingsState {
    return {
      ...state,
      filters,
    }
  }
});

export const fetchSuccess = (buildings: Building[]): BuildingsAction<BuildingsActionTypes.FetchSuccess> => ({
  type: BuildingsActionTypes.FetchSuccess,
  reducer(state): BuildingsState {
    return {
      ...state,
      buildings,
      loading: false
    }
  }
});

export const createSuccess = (building: Building): BuildingsAction<BuildingsActionTypes.CreateSuccess> => ({
  type: BuildingsActionTypes.CreateSuccess,
  reducer(state): BuildingsState {
    return {
      ...state,
      loading: false,
      showModal: false,
      buildings: [building].concat(state.buildings),
    }
  }
});