import { Col } from "antd";
import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import { SensorExperiment } from "../../types";
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";

const columns = {
  xs: { span: 6 },
  sm: { span: 6 },
  md: { span: 6 },
  lg: { span: 6 },
  xl: { span: 6 }
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  experimentSensors: SensorExperiment[],
  onRemoveSensors: (sensors: SensorExperiment[]) => Promise<void>,
}

const ExperimentRemoveSensor: FC<Props> = (props) => {

  const [selectedSensors, selectSensors] = useState<SensorExperiment[]>([]);

  const onSubmit = async () => {
    await props.onRemoveSensors(selectedSensors);
    selectSensors([]);
  }

  const onSelect = (sensor: SensorExperiment) => {
    if (selectedSensors.includes(sensor)) {
      selectSensors(selectedSensors.filter(sen => sen.uuid !== sensor.uuid));
    } else {
      selectSensors(selectedSensors.concat([sensor]));
    }
  }

  const onCancel = () => {
    selectSensors([]);
    props.onCancel();
  }

  const getSensors = () => {
    const filteredSen = props.experimentSensors.filter(sen => !selectedSensors.includes(sen));
    return filteredSen;
  }
  
  return <> 
    <Modal
      title='Remove sensors'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      <Table>
        <TableHeader>
          <TableHeaderItem {...columns}>
            NAME
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            TYPE
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            LOCATION
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            POSITION
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedSensors.map(expSensor => (
            <TableItem clickable={1} color="#DC493A" key={expSensor.uuid} onClick={() => onSelect(expSensor)}>
              <Col {...columns}>
                {expSensor.sensor.name}
              </Col>
              <Col {...columns}>
                {expSensor.sensor.type}
              </Col>
              <Col {...columns}>
                {expSensor.location && expSensor.location.name}
              </Col>
              <Col {...columns}>
                {expSensor.position && expSensor.position.name}
              </Col>
            </TableItem>
          ))}
        { getSensors().map(expSensor => (
            <TableItem clickable={1} key={expSensor.uuid} onClick={() => onSelect(expSensor)}>
              <Col {...columns}>
                {expSensor.sensor.name}
              </Col>
              <Col {...columns}>
                {expSensor.sensor.type}
              </Col>
              <Col {...columns}>
                {expSensor.location && expSensor.location.name}
              </Col>
              <Col {...columns}>
                {expSensor.position && expSensor.position.name}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ExperimentRemoveSensor;