import React, { FC, useEffect } from 'react';
import { Layout, Row, Col, Typography } from 'antd';

import Menu from '../../core/components/menu.component';
import CreateUserView from './create.user.view';
import UserView from './user.component';
import { UsersState, CreateUserParams } from '../types';
import Loading from '../../components/loading.component';
import Scrollable from '../../core/components/scrollable.component';
import userOptions from '../../filter/options/user';
import { Filter } from '../../filter/types';
import FilterView from '../../filter/components/filter.view';
import { Button, Table, TableHeader, TableHeaderItem } from '../../components';
import { UserRole, User } from '../../types';

const { Content } = Layout;
const { Title } = Typography;

const columns = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 8 },
  lg: { span: 8 },
  xl: { span: 8 }
}

type Props = UsersState & {
  userRole?: UserRole,
  getUsers: () => void,
  toggleModal: () => void,
  setFilters: (filters: Filter[]) => void,
  createUser: (param: CreateUserParams) => Promise<void>,
}

const UsersView: FC<Props> = ({
  users,
  loading,
  filters,
  userRole,
  showModal,
  getUsers: serverGetUsers,
  toggleModal,
  setFilters,
  createUser
}) => {
  useEffect(() => serverGetUsers(), [serverGetUsers]);

  const getUsers = () => {
    return filters.reduce<User[]>((acc, curr) => {
      return acc.filter(usr => curr.exec(usr))
    }, users)
  }

  return <>
    <Layout>
      <Menu selected="users"/>
      <Content style={{ padding: '20px 50px' }}>

        {/* Header */}
        <Row>
          <Col xs={{ span: 18 }} sm={{ span: 19}} md={{ span: 20 }} lg={{ span: 21 }} xl={{ span: 22}}>
            <Title level={3}>Users</Title>
          </Col>
          {
            userRole && userRole === UserRole.admin &&
            <Col xs={{ span: 6 }} sm={{ span: 5 }} md={{ span: 4 }} lg={{ span: 3 }} xl={{ span: 2 }}>
              <Button style={{float: 'right'}} create={1} onClick={toggleModal}>
                create user
              </Button>
            </Col>
          }
        </Row>

        {/* Filter  */}
        <FilterView
          options={userOptions}
          filters={filters}
          setFilters={setFilters}
        />

        {/* Table */}
        <Loading spinning={loading}>
          <Table>
            <TableHeader>
              <TableHeaderItem {...columns}>
                FIRSTNAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                LASTNAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                ROLE
              </TableHeaderItem>
            </TableHeader>
            <Scrollable>
              { getUsers().map(user => (
                <UserView uuid={user.uuid} key={user.uuid}>
                  <Col {...columns}>
                    {user.firstName}
                  </Col>
                  <Col {...columns}>
                    {user.lastName}
                  </Col>
                  <Col {...columns}>
                    {user.role}
                  </Col>
                </UserView>
              ))}
            </Scrollable>
          </Table>
        </Loading>

        {/* Modal */}
        <CreateUserView
          loading={loading}
          visible={showModal}
          onCancel={toggleModal}
          onCreate={createUser}
        />
      </Content>
    </Layout>
  </>
}

export default UsersView;