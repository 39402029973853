import React, { FormEvent, FC, useState, useEffect } from 'react';

import { Project } from '../../types';
import { EditProjectParams } from '../types';
import EditProjectForm from './edit.project.form';
import Modal from '../../components/modal.component';

type Props = {
  project: Project,
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  onEdit: (uuid: string, param: Pick<Project, 'name' | 'description' | 'keywords'>) => Promise<void>
}

const EditProjectView: FC<Props> = (props) => {

  const init: EditProjectParams = {
    name: props.project.name,
    keywords: props.project.keywords,
    description: props.project.description,
  }
  const [inputs, setInputs] = useState<EditProjectParams>({ ...init });

  useEffect(() => setInputs({
    name: props.project.name,
    keywords: props.project.keywords,
    description: props.project.description
  }), [props.project.name, props.project.keywords, props.project.description]);
  
  const onSubmit = async () => {
    if (!disableOkButton() && inputs.name) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.onEdit(props.project.uuid, params);
    }
  }
  const onChange = (event: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputs({ ...inputs, [event.currentTarget.name]: event.currentTarget.value});
  }
  const onCancel = () => {
    setInputs({ ...init });
    props.onCancel();
  }
  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
   return disable;
  }
  return <> 
    <Modal
      title='Edit project'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Edit"
      cancelText="Cancel"
      confirmLoading={props.loading}
      disableOkButton={disableOkButton()}
    >
      <EditProjectForm
        inputs={inputs}
        onChange={onChange}
      />
    </Modal>
  </>  
}

export default EditProjectView;