export enum UserActionTypes {
  FetchPending = 'user/FETCH_PENDING',
  FetchSuccess = 'user/FETCH_SUCCESS',
  FetchFailure = 'user/FETCH_FAILURE',
  UpgradePending = 'user/UPGRADE_PENDING',
  UpgradeSuccess = 'user/UPGRADE_SUCCESS',
  UpgradeFailure = 'user/UPGRADE_FAILURE',
  DowngradePending = 'user/DOWNGRADE_PENDING',
  DowngradeSuccess = 'user/DOWNGRADE_SUCCESS',
  DowngradeFailure = 'user/DOWNGRADE_FAILURE',
}