import { message } from 'antd';
import { Dispatch } from 'redux';

import { reportError } from '../core/utils';
import { pending, failure, deleteSuccess, editSuccess, createSuccess, fetchSuccess } from './action.creators';
import { File as TypeOfFile, FileParent, CreateFileParam } from './types';
import { FileActionTypes } from './action.types';
import { postFile, uploadFile, putFile, delFile } from './server';

export const getFiles = (files: TypeOfFile[]) => async (dispatch: Dispatch) => {
  dispatch(pending(FileActionTypes.FetchPending));
  try {
    dispatch(fetchSuccess(files))
  } catch (e) {
    dispatch(failure(FileActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const uploadFileOp = (type: FileParent, parentUuid: string, param: CreateFileParam, file: File) => async (dispatch: Dispatch) => {
  dispatch(pending(FileActionTypes.CreatePending));
  try {
    const response: TypeOfFile = await postFile(type, parentUuid, param);
    const fileUploadLink: string = response.link;
    await uploadFile(fileUploadLink, file);
    message.success(`File uploaded`);
    dispatch(createSuccess(response))
  } catch (e) {
    dispatch(failure(FileActionTypes.CreateFailure));
    await reportError(e);
  }
};

export const editFile = (type: FileParent, parentUuid: string, fileUuid: string, param: Partial<CreateFileParam>) => async (dispatch: Dispatch) => {
  dispatch(pending(FileActionTypes.EditPending));
  try {
    const response = await putFile(type, parentUuid, fileUuid, param);
    message.success(`File edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(FileActionTypes.EditFailure))
    await reportError(e);
  }
};

export const deleteFile = (type: FileParent, parentUuid: string, fileUuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(FileActionTypes.DeletePending));
  try {
    await delFile(type, parentUuid, fileUuid);
    message.success(`File deleted`);
    dispatch(deleteSuccess(fileUuid))
  } catch (e) {
    dispatch(failure(FileActionTypes.DeleteFailure))
    await reportError(e);
  }
};