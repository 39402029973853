import { InputTypes, NodeTypes, FilterOptionType } from '../types';

const heatingSystemTypeOptions: FilterOptionType[] = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
];

export default heatingSystemTypeOptions;