import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import UserView from './user.view';
import { AppState } from '../../core/types';
import { getUser, downgradeUser, upgradeUser } from '../operations';

const mapStateToProps = (state: AppState) => {
  return {
    ...state[NAME],
    userRole: state.profile.user && state.profile.user.role
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    getUser: (uuid: string) => {
      dispatch(getUser(uuid));
    },
    downgradeUser: async (uuid: string) => {
      await dispatch(downgradeUser(uuid));
    },
    upgradeUser: async (uuid: string) => {
      await dispatch(upgradeUser(uuid));
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(UserView);