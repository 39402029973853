import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import { AppState } from '../../core/types';
import { HeatingSystem } from '../../types';
import { toggleModal } from '../action.creators';
import HeatingSystemView from './heating.system.view';
import { ModalType, CreateHeatingSystemParam } from '../types';
import { createHeatingSystem, editHeatingSystem, deleteHeatingSystem, getHeatingSystem } from '../operations';

const mapStateToProps = (state: AppState) => {
  return { 
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: (modalType: ModalType) => {
      dispatch(toggleModal(modalType));
    },
    getHeatingSystem: (heatingSystem?: HeatingSystem) => {
      dispatch(getHeatingSystem(heatingSystem))
    },
    createHeatingSystem: async (type: 'experiment' | 'building', parentUuid: string, param: CreateHeatingSystemParam) => {
      await dispatch(createHeatingSystem(type, parentUuid, param));
    },
    updateHeatingSystem: async (type: 'experiment' | 'building', parentUuid: string, uuid: string, param: Partial<CreateHeatingSystemParam>) => {
      //todo
      await dispatch(editHeatingSystem(type, parentUuid, uuid, param));
    },
    deleteHeatingSystem: async (type: 'experiment' | 'building', parentUuid: string, uuid: string) => {
      await dispatch(deleteHeatingSystem(type, parentUuid, uuid));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeatingSystemView);
