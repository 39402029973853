import { NodeTypes, InputTypes, FilterOptionType } from '../types';

const sensorOptions: FilterOptionType[] = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'device',
    label: 'Device',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'unit',
    label: 'Unit',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'type',
    label: 'Type',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  }
];

export default sensorOptions;