import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { Filter } from '../filter/types';

type FilterState = {
  experiments: Filter[]
  project: Filter[]
  sensor: Filter[]
}

type FilterProvider = [ FilterState, Dispatch<SetStateAction<FilterState>> | undefined ];

export const FilterContext = React.createContext<FilterProvider>([{
  experiments: [],
  project: [],
  sensor: []
}, undefined]);

export const FilterProvider: FC = ({ children }) => {

  const [filters, setFilter] = useState<FilterState>({
    experiments: [],
    project: [],
    sensor: []
  });
  
  return <>
    <FilterContext.Provider value={[filters, setFilter]}>
      { children }
    </FilterContext.Provider>
  </>
}