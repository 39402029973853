import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import { AppState } from '../../core/types';
import BuildingsView from './buildings.view';
import { CreateBuildingParam } from '../types';
import { toggleModal, updateFilters } from '../action.creators';
import { getBuildings, createBuilding } from '../operations';
import { Filter } from '../../filter/types';

const mapStateToProps = (state: AppState) => {
  return { 
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: () => { dispatch(toggleModal()); },
    getBuildings: () => { dispatch(getBuildings()); },
    setFilters: (filters: Filter[]) => dispatch(updateFilters(filters)),
    createBuilding: async (param: CreateBuildingParam) => {
      await dispatch(createBuilding(param));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingsView);
