import { Col } from "antd";
import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import { AddSensorExperimentParam } from "../types";
import sensorOptions from "../../filter/options/sensor";
import FilterView from "../../filter/components/filter.view";
import { Filter } from "../../filter/types";
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";
import { fetchSensors } from "../../server";
import { Sensor, SensorExperiment } from "../../types";
import { useFetch } from "../../hooks/fetcher";

const columns = {
  xs: { span: 6 },
  sm: { span: 6 },
  md: { span: 6 },
  lg: { span: 6 },
  xl: { span: 6 }
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  experimentSensors: SensorExperiment[],
  onAddSensors: (sensors: AddSensorExperimentParam[]) => void,
}

const ExperimentAddSensor: FC<Props> = (props) => {
  const [filters, setFilters] = useState<Filter[]>([]);
  const [selectedSensors, selectSensors] = useState<Sensor[]>([]);
  const sensors = useFetch<Sensor[]>(fetchSensors);

  const onSubmit = async () => {
    await props.onAddSensors(selectedSensors.map(sen => ({ sensor: sen.uuid })));
    selectSensors([]);
  }

  const onSelect = (sensor: Sensor) => {
    if (selectedSensors.includes(sensor)) {
      selectSensors(selectedSensors.filter(sen => sen.uuid !== sensor.uuid));
    } else {
      selectSensors(selectedSensors.concat([sensor]));
    }
  }

  const onCancel = () => {
    selectSensors([]);
    props.onCancel();
  }

  const getSensors = () => {
    const experimentSensorUids = props.experimentSensors.map(sen => sen.sensor.uuid);
    const selectedSensorUuids = selectedSensors.map(sen => sen.uuid);
    const filteredSensor = (sensors ?? []).filter(sen => !selectedSensorUuids.includes(sen.uuid) && !experimentSensorUids.includes(sen.uuid));
    return filters.reduce<Sensor[]>((acc, curr) => {
      return acc.filter(sen => curr.exec(sen))
    }, filteredSensor);
  }
  
  return <> 
    <Modal
      title='Add Sensors'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      { selectedSensors.length > 0 &&
        <div style={{marginBottom: '10px'}}>
          <h3>Selected sensors</h3>
          <Table>
            <TableHeader>
              <TableHeaderItem {...columns}>
                NAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                DEVICE
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                UNITs
              </TableHeaderItem>
            </TableHeader>
            <div
              style={{
                overflow: 'auto',
                maxHeight: '224px',
                paddingBottom: '5px',
              }}
            >
            {selectedSensors.map(sensor => (
                <TableItem clickable={1} color="#77B28C" key={sensor.uuid} onClick={() => onSelect(sensor)}>
                  <Col {...columns}>
                    {sensor.name}
                  </Col>
                  <Col {...columns}>
                    {sensor.type}
                  </Col>
                  <Col {...columns}>
                    {sensor.device}
                  </Col>
                  <Col {...columns}>
                    {sensor.unit}
                  </Col>
                </TableItem>
              ))}
            </div>
          </Table>
        </div>
      }
      
      { getSensors().length > 0 && 
        <div>
          { selectedSensors.length > 0 ? <h3>Other sensors</h3> : <h3>Sensors</h3> }
          {/* Filter */}
          <FilterView
            options={sensorOptions}
            filters={filters}
            setFilters={setFilters}
          />
          <Table>
            <TableHeader>
              <TableHeaderItem {...columns}>
                NAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                DEVICE
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                UNIT
              </TableHeaderItem>
            </TableHeader>
            <div
              style={{
                overflow: 'auto',
                maxHeight: '224px',
                paddingBottom: '5px',
              }}
            >
            { getSensors().map(sensor => (
                <TableItem clickable={1} key={sensor.uuid} onClick={() => onSelect(sensor)}>
                  <Col {...columns}>
                    {sensor.name}
                  </Col>
                  <Col {...columns}>
                    {sensor.type}
                  </Col>
                  <Col {...columns}>
                    {sensor.device}
                  </Col>
                  <Col {...columns}>
                    {sensor.unit}
                  </Col>
                </TableItem>
              ))
            }
            </div>
          </Table>
        </div>
      }
      
    </Modal>
  </>  
}

export default ExperimentAddSensor;