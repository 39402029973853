import { NodeTypes, InputTypes, FilterOptionType } from "../types";

const buildingStateOptions: FilterOptionType[] = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
];

export default buildingStateOptions;