import styled from 'styled-components';
import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { Row, Col, Icon, Menu, Layout, Dropdown } from 'antd';
import authenticationController from '../../controllers/authentication.controller';
import { AppState } from '../types';
import { NAME } from '../../profile/constants';
import { connect } from 'react-redux';
import { User, UserRole } from '../../types';


type Props = {
  user?: User
  selected: string
}

type StyledMenuItemProps = {
  selected: boolean
}

const StyledHeader = styled(Layout.Header)`
  background-color: #267899;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const StyledMenu = styled(Menu)`
  line-height: 60px;
  height: 64px;
  border-bottom: none;
  background-color: #267899;
`;

const StyledMenuItem = styled(Menu.Item)<StyledMenuItemProps>`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: ${props => props.selected ? '#ffffff' : '#d8dBe2'} !important;
  border-bottom: 4px solid ${props => props.selected ? '#ffffff' : 'transparent'} !important;
  :hover {
    color: #ffffff !important;
    border-bottom: 4px solid #ffffff !important;
  }
`;

const StyledLink = styled(Link)<StyledMenuItemProps>`
  color: ${props => props.selected ? '#ffffff' : '#d8dBe2'} !important;
  :hover {
    color: #ffffff !important;
  }
`;

const StyledUserMenu = styled(Menu)`
  background-color: #267899;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;

const StyledUserLink = styled(Link)<StyledMenuItemProps>`
  color: ${props => props.selected ? '#ffffff' : '#d8dBe2'} !important;
  :hover {
    color: #ffffff !important;
  }
`;

const LogoutMenuItem = styled(Menu.Item)`
  color: #DC493A
  :hover {
    color: #DC493A !important;
  }
`;

const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
`;

const DropdownTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
`;

const mapStateToProps = (state: AppState) => {
  return { 
    user: state[NAME].user
  };
};


const HestiaMenu: FC<Props> = (props) => {

  const UserMenu = <>
  <StyledUserMenu>
    {props.user && props.user.role === UserRole.admin && <Menu.Item>
      <StyledUserLink to="/fields" selected={props.selected === 'fields'}>
        Fields
      </StyledUserLink>
    </Menu.Item>}
    <Menu.Item>
      <StyledUserLink to="/users" selected={props.selected === 'users'}>
        Users
      </StyledUserLink>
    </Menu.Item>
    <Menu.Item>
      <StyledUserLink to="/profile" selected={props.selected === 'profile'}>
        Profile
      </StyledUserLink>
    </Menu.Item>
    <LogoutMenuItem onClick={() => { authenticationController.logout(); }}>
      Logout
    </LogoutMenuItem>
  </StyledUserMenu>
  </>;

  return <>
  <StyledHeader>
    <Row>
      <Col span={4}>
        <Title>HESTIA</Title>
      </Col>
      <Col span={8} offset={4}>
        <StyledMenu theme="light" mode="horizontal">
          <StyledMenuItem selected={props.selected === 'projects'}>
            <StyledLink to="/projects" selected={props.selected === 'projects'}>
              Projects
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem selected={props.selected === 'experiments'}>
            <StyledLink to="/experiments" selected={props.selected === 'experiments'}>
              Experiments
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem selected={props.selected === 'buildings'}>
            <StyledLink to="/buildings" selected={props.selected === 'buildings'}>
              Buildings
            </StyledLink>
          </StyledMenuItem>
          <StyledMenuItem selected={props.selected === 'sensors'}>
            <StyledLink to="/sensors" selected={props.selected === 'sensors'}>
              Sensors
            </StyledLink>
          </StyledMenuItem>
        </StyledMenu>
      </Col>
      <Col span={2} offset={6}>
        <Dropdown overlay={UserMenu}>
          <DropdownTitle>
            {props.user && props.user.firstName}
            <Icon type="caret-down" />
          </DropdownTitle>
        </Dropdown>
      </Col>
    </Row>
  </StyledHeader>
        </>

}

export default connect(mapStateToProps)(HestiaMenu);

