import { UsersState, UsersActions } from "./types";
import { UsersActionTypes } from "./action.types";

const initialState: UsersState = {
  loading: false,
  showModal: false,
  users: [],
  filters: []
}

export default (state: UsersState = initialState, action: UsersActions): UsersState => {
  if (Object.values(UsersActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
