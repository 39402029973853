import { useState, useEffect } from "react"

import { Field } from "../types"
import { fetchFieldType } from "../server";
import { reportError } from "../core/utils";

export const useFetchField = (apiPath: string) => {
  const [fields, setFields] = useState<Field[]>([]);

  useEffect(() => {
    const retrieveFields = async () => {
      try {
        const fields = await fetchFieldType(apiPath);
        setFields(fields);
      } catch (error) {
        reportError(error);
      }
    }
    retrieveFields();
  }, [apiPath]);

  return fields;
}
