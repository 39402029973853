import React, { FC, useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { Layout, Row, Col, Typography, Popconfirm } from 'antd';

import { Iuuid, User, CreateExperimentParam } from '../../types';
import Menu from '../../core/components/menu.component';
import Text from '../../components/text.component';
import Label from '../../components/label.component';
import FloatRight from '../../components/float.right.component';
import { ExperimentState, ExperimentModalType, SelectedColumnSensor, AddSensorExperimentParam, UpdateSensorExperimentParam } from "../types";
import Loading from "../../components/loading.component";
import moment from "moment";
import EditExperimentView from './edit.experiment.view';
import FilesView from '../../files/components/';
import ExperimentBuildings from './experiment.building.view';
import ExperimentSensors from './experiment.sensor.view';
import ExperimentParticipants from "./experiment.participant.view";
import { Container, Button } from "../../components";
import { Building, SensorExperiment } from "../../types";
import { experimentTypePath, experimentHeatingPath, sensorPositionPath, sensorLocationPath } from "../../server";
import { useFetchField } from "../../hooks/field";
import { Data } from '../../routes/experiments/components/data';

const { Content } = Layout;
const { Title } = Typography;

const titleColumns = {
  xs: { span: 14},
  sm: { span: 10},
  md: { span: 13},
  lg: { span: 16},
  xl: { span: 17},
}
const buttonColumns = {
  xs: { span: 10},
  sm: { span: 14},
  md: { span: 11},
  lg: { span: 8},
  xl: { span: 7},
}

const columns = {
  xs: { span: 24},
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 6},
  xl: { span: 6},
}

type Props = RouteComponentProps<Iuuid> & ExperimentState & {
  users: User[]
  getUsers: () => void
  buildings: Building[]
  getBuildings: () => void
  toggleModal: (modalType: ExperimentModalType) => void,
  getExperiment: (uuid: string) => void,
  deleteExperiment: (uuid: string) => Promise<void>,
  updateExperiment: (uuid: string, param: Partial<CreateExperimentParam>) => Promise<void>,
  onAddSensors: (uuid: string, sensors: AddSensorExperimentParam[]) => Promise<void>,
  onRemoveSensors: (uuid: string, sensors: SensorExperiment[]) => Promise<void>,
  onEditSensor: (uuid: string, sensorUuid: string, params: UpdateSensorExperimentParam) => Promise<void>
  onAddBuildings: (uuid: string, buildings: Building[]) => Promise<void>
  onRemoveBuildings: (uuid: string, buildings: Building[]) => Promise<void>
  onAddParticipants: (uuid: string, users: User[]) => Promise<void>
  onRemoveParticipants: (uuid: string, users: User[]) => Promise<void>
  uploadData: (uuid: string, fileName: string, parsedData: any, selectedRow: number, selectedSensors: SelectedColumnSensor) => Promise<void>,
  deleteData: (uuid: string, dataId: string) => Promise<void>,
  downloadData: (uuid: string, sensors: SensorExperiment[]) => Promise<void>
}

const ExperimentView: FC<Props> = (props) => {
  const [deleted, setDeleted] = useState(false);

  const experimentTypes = useFetchField(experimentTypePath);
  const experimentHeatingTypes = useFetchField(experimentHeatingPath);
  const locations = useFetchField(sensorLocationPath);
  const positions = useFetchField(sensorPositionPath);
  const getExperiment = props.getExperiment;
  const experimentUuid = props.match.params.uuid;
  useEffect(() => getExperiment(experimentUuid), [experimentUuid, getExperiment]);

  const onDelete = async () => {
    await props.deleteExperiment(props.match.params.uuid);
    setDeleted(true);
  }

  return <>
    {deleted && <Redirect to="/experiments"/>}
    <Layout>
      <Menu selected="experiments"/>
      <Content style={{ padding: '20px 50px' }}>
        <Loading spinning={props.loading}>
          <Row>
            <Col>
            <Title level={3}>
              {props.experiment && props.experiment.name}
            </Title>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col {...titleColumns}>
                <Title level={4}>
                  General information
                </Title>
              </Col>
              <Col {...buttonColumns}>
                <FloatRight>
                  <Button style={{marginRight: '10px'}} onClick={() => props.toggleModal(ExperimentModalType.EDIT)}>
                    edit experiment
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this experiment?"
                    onConfirm={onDelete}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <Button delete={1}>
                      delete experiment
                    </Button>
                  </Popconfirm>
                </FloatRight>
              </Col>
            </Row>
            <Row>
              <Col {...columns}>
                <Row>
                  <Label>
                    TYPE
                  </Label>
                  <Text>
                    {props.experiment && props.experiment.type.name}
                  </Text>
                </Row>
              </Col>
              <Col {...columns}>
                <Row>
                  <Label>
                  OWNER
                  </Label>
                  <Text>
                    {props.experiment && props.experiment.creator.firstName} { props.experiment && props.experiment.creator.lastName}
                  </Text>
                </Row>
              </Col>
              <Col {...columns}>
                  <Row>
                    <Label>
                      START DATE
                    </Label>
                    <Text>
                      {props.experiment && props.experiment.start && moment(Number(props.experiment.start)).format('DD/MM/YYYY')}
                    </Text>
                  </Row>
              </Col>
              <Col {...columns}>
                <Row>
                  <Label>
                    END DATE
                  </Label>
                  <Text>
                    {props.experiment && props.experiment.stop && moment(Number(props.experiment.stop)).format('DD/MM/YYYY')}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col {...columns}>
                <Row>
                  <Label>
                    DESCRIPTION
                  </Label>
                  <Text>
                    {props.experiment && props.experiment.description}
                  </Text>
                </Row>
              </Col>
              <Col {...columns}>
                <Row>
                  <Label>
                    HEATING SYSTEM
                  </Label>
                  <Text>
                    {props.experiment && props.experiment.heating && props.experiment.heating.name}
                  </Text>
                </Row>
              </Col>
            </Row>
            { props.experiment &&
              <EditExperimentView 
                experiment={props.experiment}
                loading={props.loading}
                showModal={props.showModal && props.modalType === ExperimentModalType.EDIT}
                experimentTypes={experimentTypes}
                experimentHeatingTypes={experimentHeatingTypes}
                toggleModal={() => props.toggleModal(ExperimentModalType.EDIT)}
                editExperiment={props.updateExperiment}
              />
            }
          </Container>
        </Loading>
        { props.experiment && 
          <FilesView
            type='experiment'
            experiment={props.experiment}
            parentUuid={props.experiment.uuid}
          />
        }
        { props.experiment &&
          <ExperimentBuildings
            buildings={props.buildings}
            getBuildings={props.getBuildings}
            experimentBuildings={props.experiment.buildings || []}
            toggleModal={props.toggleModal}
            loading={props.loading}
            onRemoveBuildings={(buildings: Building[]) => props.onRemoveBuildings(props.match.params.uuid, buildings)}
            onAddBuildings={(buildings: Building[]) => props.onAddBuildings(props.match.params.uuid, buildings)}
            showModal={props.showModal}
            modalType={props.modalType}
          />
        }
        { props.experiment &&
          <ExperimentParticipants
            users={props.users}
            getUsers={props.getUsers}
            participants={props.experiment.participants || []}
            toggleModal={props.toggleModal}
            loading={props.loading}
            onRemoveParticipants={(users: User[]) => props.onRemoveParticipants(props.match.params.uuid, users)}
            onAddParticipants={(users: User[]) => props.onAddParticipants(props.match.params.uuid, users)}
            showModal={props.showModal}
            modalType={props.modalType}
          />
        }
        { props.experiment &&
          <ExperimentSensors
            locations={locations}
            positions={positions}
            experimentSensors={props.experiment.sensors || []}
            toggleModal={props.toggleModal}
            loading={props.loading}
            onRemoveSensors={(sensors: SensorExperiment[]) => props.onRemoveSensors(props.match.params.uuid, sensors)}
            onEditSensor={(sensorUuid: string, params: UpdateSensorExperimentParam) => props.onEditSensor(props.match.params.uuid, sensorUuid, params)}
            onAddSensors={(sensors: AddSensorExperimentParam[]) => props.onAddSensors(props.match.params.uuid, sensors)}
            showModal={props.showModal}
            modalType={props.modalType}
          />
        }
        {
         props.experiment && props.experiment.data &&
         <Data 
            experimentUuid={props.experiment.uuid}
         />
        }
      </Content>
    </Layout>
  </>
}

export default ExperimentView;