import fileOptions from "./file";
import userOptions from "./user";
import buildingOptions from "./building";
import { NodeTypes, InputTypes } from "../types";
import heatingSystemOptions from './heatingSystem';
import experimentTypeOptions from "./experimentTypeOptions";

const experimentOptions = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'start',
    label: 'Start',
    node: NodeTypes.LEAF,
    input: InputTypes.DATE
  },
  {
    value: 'stop',
    label: 'Stop',
    node: NodeTypes.LEAF,
    input: InputTypes.DATE
  },
  {
    value: 'description',
    label: 'Description',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'type',
    label: 'Type',
    node: NodeTypes.ELEMENT,
    children: experimentTypeOptions,
  },
  {
    value: 'files',
    label: 'File',
    node: NodeTypes.ELEMENT,
    children: fileOptions,
  },
  {
    value: 'heatingSystem',
    label: 'Heating System',
    node: NodeTypes.ELEMENT,
    children: heatingSystemOptions,
  },
  {
    value: 'participants',
    label: 'Participant',
    node: NodeTypes.ELEMENT,
    children: userOptions,
  },
  {
    value: 'buildings',
    label: 'Building',
    node: NodeTypes.ARRAY,
    children: buildingOptions,
  },
  {
    value: 'creator',
    label: 'Creator',
    node: NodeTypes.ELEMENT,
    children: userOptions,
  }
]

export default experimentOptions;