import userOptions from './user';
import fileTypeOptions from './fileTypeOptions';
import { NodeTypes, InputTypes, FilterOptionType } from '../types';

const fileOptions: FilterOptionType[] = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'type',
    label: 'Type',
    node: NodeTypes.ELEMENT,
    children: fileTypeOptions,
  },
  {
    value: 'creator',
    label: 'Creator',
    node: NodeTypes.ELEMENT,
    children: userOptions,
  }
];

export default fileOptions;