import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import CreateBuildingForm from './create.building.form';
import { CreateBuildingParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

type Props = {
  loading: boolean,
  showModal: boolean,
  buildingTypes: Field[],
  buildingStates: Field[],
  toggleModal: () => void,
  createBuilding: (param: CreateBuildingParam) => Promise<void>,
}

type Inputs = Partial<CreateBuildingParam>

const CreateBuildingView: FC<Props> = (props) => {
  const [inputs, setInputs] = useState<Inputs>({});
  const handleSubmit = async () => {
    if (inputs.type && inputs.city && inputs.country && inputs.postal && inputs.street && inputs.number) {
      await props.createBuilding({
        type: inputs.type,
        country: inputs.country,
        city: inputs.city,
        postal: inputs.postal,
        street: inputs.street,
        number: inputs.number,
        state: inputs.state,
        constructionDate: inputs.constructionDate,
        description: inputs.description,
        performanceCalculations: inputs.performanceCalculations,
        blowerdoor: inputs.blowerdoor,
        accubalance: inputs.accubalance,
        smokeGas: inputs.smokeGas,
        plans: inputs.plans,
      });
      setInputs({});
    }
  }
  const handleInputChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }
  const onCancel = () => {
    setInputs({});
    props.toggleModal(); 
  }
  return <> 
  <Modal
    title='Create building'
    visible={props.showModal}
    onOk={handleSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Create"
    cancelText="Cancel"
    confirmLoading={props.loading}
    width='75%'
    disableOkButton={!inputs.type || !inputs.city || !inputs.country || !inputs.postal || !inputs.street || !inputs.number}
  >
    <CreateBuildingForm
      inputs={inputs}
      onChange={handleInputChange}
      buildingTypes={props.buildingTypes}
      buildingStates={props.buildingStates}
    />
  </Modal>
</>
}

export default CreateBuildingView;