import React, { FC, useState } from 'react';

import CreateUserForm from './create.user.form';
import Modal from '../../components/modal.component';
import { CreateUserParams } from '../types';
import { OnChangeEvent } from '../../core/types';
import { UserRole } from '../../types';

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  onCreate: (params: CreateUserParams) => Promise<void>
}

type State = {
  firstName?: string,
  lastName?: string,
  email?: string,
  role?: UserRole
}

const CreateUserView: FC<Props> = (props) => {

  const [inputs, setInputs] = useState<State>({});

  const handleInputChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({});
    props.onCancel();
  }

  const onOk = async () => {
    if (inputs && inputs.firstName && inputs.lastName && inputs.email && inputs.role) {
      await props.onCreate({
        firstName: inputs.firstName,
        lastName: inputs.lastName,
        email: inputs.email,
        role: inputs.role
      });
      setInputs({});
    }
  }
 
  return <> 
    <Modal
      title='Create user'
      visible={props.visible}
      onOk={onOk}
      onCancel={onCancel}
      destroyOnClose={true}
      okText="Create"
      cancelText="Cancel"
      confirmLoading={props.loading}
      disableOkButton={!inputs.firstName || !inputs.lastName || !inputs.email || !inputs.role}
    >
      <CreateUserForm
        inputs={inputs}
        onChange={handleInputChange}
      />

    </Modal>
  </>  
}

export default CreateUserView;