import React, { FC, useState, useEffect } from 'react';

import Modal from '../../components/modal.component';
import EditBuildingForm from './edit.building.form';
import { CreateBuildingParam } from '../../buildings/types';
import { Building, Field } from '../../types';
import { OnChangeEvent  } from '../../core/types';

type Props = {
  loading: boolean,
  showModal: boolean,
  building: Building,
  buildingTypes: Field[],
  buildingStates: Field[],
  toggleModal: () => void,
  editBuilding: (uuid: string, param: Partial<CreateBuildingParam>) => Promise<void>,
}

const EditBuildingView: FC<Props> = (props) => {
  const { files, creator, editor, heatingSystem, ...otherBuildingProps } = props.building;
  const initialParams: CreateBuildingParam = {
    ...otherBuildingProps,
    type: props.building.type.uuid,
    state: props.building.state ? props.building.state.uuid : undefined
  }
  
  const [inputs, setInputs] = useState<Partial<CreateBuildingParam>>({});
  
  // eslint-disable-next-line
  useEffect(() => setInputs({ ...initialParams }), [props.building.uuid]);

  const onSubmit = async () => {
    if (!disableOkButton() && inputs.type && inputs.city && inputs.country && inputs.postal && inputs.street && inputs.number) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== initialParams[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.editBuilding(props.building.uuid, params)
    }
  }
  const onChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }
  const onCancel = () => {
    setInputs({ ...initialParams});
    props.toggleModal(); 
  }
  const disableOkButton = () => {
    const disable = Object.keys(initialParams).reduce((acc, curr) => {
      return acc && initialParams[curr] === inputs[curr]
    }, true);
   return disable;
  }

  return <> 
  <Modal
    title='Edit building'
    visible={props.showModal}
    onOk={onSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Edit"
    cancelText="Cancel"
    confirmLoading={props.loading}
    width='75%'
    disableOkButton={disableOkButton()}
  >
    <EditBuildingForm
      inputs={inputs}
      onChange={onChange}
      buildingTypes={props.buildingTypes}
      buildingStates={props.buildingStates}
    />
  </Modal>
</>
}

export default EditBuildingView;