import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import FilesView from './files.view';
import { AppState } from '../../core/types';
import { ModalType, File as TypeOfFile, FileParent, CreateFileParam } from '../types';
import { toggleModal } from '../action.creators';
import { getFiles, uploadFileOp, editFile, deleteFile } from '../operations';

const mapStateToProps = (state: AppState) => {
  return {
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: (modalType: ModalType) => {
      dispatch(toggleModal(modalType));
    },
    getFiles: (files: TypeOfFile[]) => {
      dispatch(getFiles(files));
    },
    createFile: async (type: FileParent, parentUuid: string, param: CreateFileParam, file: File) => {
      await dispatch(uploadFileOp(type, parentUuid, param, file));
    },
    editFile: async (type: FileParent, parentUuid: string, fileUuid: string, param: Partial<CreateFileParam>) => {
      await dispatch(editFile(type, parentUuid, fileUuid, param));
    },
    deleteFile: async (type: FileParent, parentUuid: string, fileUuid: string) => {
      await dispatch(deleteFile(type, parentUuid, fileUuid));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesView);;