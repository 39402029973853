import { api } from '../core/utils';
import { AddSensorExperimentParam, UpdateSensorExperimentParam, Data } from './types';
import { Experiment, CreateExperimentParam, DataEntry } from '../types';

export const fetchExperiment = async (uuid:string) => {
  return api(`experiment/${uuid}`, 'GET');
}

export const putExperiment = async (uuid:string, params: Partial<CreateExperimentParam>) => {
  return api(`experiment/${uuid}`, 'PUT', params);
}
 
export const delExperiment = async (uuid:string) => {
  return api(`experiment/${uuid}`, 'DELETE');
}

export const addBuilding = async (uuid: string, buildingUuid: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/building/${buildingUuid}`, 'POST')
}

export const removeBuilding = async (uuid: string, buildingUuid: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/building/${buildingUuid}`, 'DELETE')
}

export const addParticipant = async (uuid: string, participantUuid: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/participant/${participantUuid}`, 'POST')
}

export const removeParticipant = async (uuid: string, participantUuid: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/participant/${participantUuid}`, 'DELETE')
}

export const addSensor = async (uuid: string, params: AddSensorExperimentParam): Promise<Experiment> => {
  return api(`experiment/${uuid}/sensor`, 'POST', params);
}

export const putSensor = async (uuid: string, sensorUuid: string, params: UpdateSensorExperimentParam): Promise<Experiment> => {
  return api(`experiment/${uuid}/sensor/${sensorUuid}`, 'PUT', params);
}

export const removeSensor = async (uuid: string, sensorUuid: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/sensor/${sensorUuid}`, 'DELETE');
}

export const postDataEntry = async (experimentUuid: string, dataName: string): Promise<DataEntry> => {
  return api(`experiment/${experimentUuid}/data`, 'POST', { name: dataName });
}

export const postData = async (experimentUuid: string, dataEntryUuid: string, params: any): Promise<Experiment> => {
  return api(`experiment/${experimentUuid}/data/${dataEntryUuid}`, 'POST', params);
}

export const delData = async (uuid: string, dataId: string): Promise<Experiment> => {
  return api(`experiment/${uuid}/data/${dataId}`, 'DELETE');
}

export const getData = async (uuid: string, sensorId: string): Promise<Data[]> => {
  return api(`experiment/${uuid}/data/${sensorId}`, 'GET');
}