import React, { FC, useState, useEffect } from 'react';

import Modal from '../../components/modal.component';
import EditExperimentForm from './edit.experiment.form';
import { Experiment, Field, CreateExperimentParam } from '../../types';
import { OnChangeEvent } from '../../core/types';

type Props = {
  loading: boolean,
  showModal: boolean,
  experiment: Experiment,
  experimentTypes: Field[],
  experimentHeatingTypes: Field[],
  toggleModal: () => void,
  editExperiment: (uuid: string, param: Partial<CreateExperimentParam>) => Promise<void>,
}

const EditExperimentView: FC<Props> = (props) => {

  const init: CreateExperimentParam = {
    type: props.experiment.type.uuid,
    name: props.experiment.name,
    start: props.experiment.start,
    stop: props.experiment.stop,
    heating: props.experiment.heating && props.experiment.heating.uuid,
    description: props.experiment.description
  }

  const [inputs, setInputs] = useState<Partial<CreateExperimentParam>>({});

  // eslint-disable-next-line
  useEffect(() => setInputs(init), [props.experiment.uuid]);
  
  const onSubmit = async () => {
    if (!disableOkButton() && inputs.type && inputs.name) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.editExperiment(props.experiment.uuid, params)
    }
  }

  const onChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({ ...init});
    props.toggleModal(); 
  }

  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
   return disable || !inputs.name || !inputs.type;
  }

  return <> 
  <Modal
    title='Edit experiment'
    visible={props.showModal}
    onOk={onSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Edit"
    cancelText="Cancel"
    confirmLoading={props.loading}
    disableOkButton={disableOkButton()}
  >
    <EditExperimentForm
      inputs={inputs}
      onChange={onChange}
      experimentTypes={props.experimentTypes}
      experimentHeatingTypes={props.experimentHeatingTypes}
    />
  </Modal>
</>
}

export default EditExperimentView;