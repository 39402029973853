import moment from 'moment';
import { message } from 'antd';
import * as Sentry from '@sentry/browser';

import apiConfiguration from "../configurations/api.configuration";
import authenticationController from "../controllers/authentication.controller";
import { ConnectionChangeEvent } from '../utils/ConnectionChangeEvent';

export const api = async (path: string, method: string, params?: any, type: 'json' | 'text' = 'json') => {
  const token = await authenticationController.getToken();
  try {
    const response = await fetch(`${apiConfiguration.url}/${path}`, {
      method,
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }),
      body: params ? JSON.stringify(params) : undefined
    });
    window.dispatchEvent(new ConnectionChangeEvent(true));
    if (response.ok) {
      try {
        if (type === 'json') {
          return await response.json();  
        } else {
          return await response.text();
        }
      } catch (error) {
        return
      }
    }
    const error = await response.text();
    throw new Error(error);
  } catch (error) {
    window.dispatchEvent(new ConnectionChangeEvent(false));
    throw error;
  }
};

export const sortTime = (a: number, b: number) => {
  if (moment(a).isAfter(moment(b))) {
    return -1
  }
  if (moment(a).isSame(moment(b))) {
    return 0
  }
  return 1
}

export const reportError = async (err: Error) => {
  if (err.message === 'Failed to fetch') {
    return;
  }
  if (process.env.NODE_ENV === 'production' && !window.location.hostname.includes('stage')) {
    Sentry.captureException(err);
  }
  await message.error(err.message)
  await message.info('Craftzer has been notified');
}
