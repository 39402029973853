import React, { FC } from "react";
import { Row, Col, Typography } from "antd";

import { sortTime } from '../../core/utils';
import { ExperimentModalType } from '../types';
import { Building } from '../../types';
import Loading from "../../components/loading.component";
import BuildingCom from '../../components/building.component';
import FloatRight from '../../components/float.right.component';
import ExperimentAddBuilding from "./experiment.add.building.view";
import ExperimentRemoveBuilding from "./experiment.remove.building.view";
import { Container, Button, TableHeaderItem, Table, TableHeader } from "../../components";

const { Title } = Typography;

const titleColumns = {
  xs: { span: 12 },
  sm: { span: 8 },
  md: { span: 6 },
  lg: { span: 4 },
  xl: { span: 3 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 16 },
  md: { span: 18 },
  lg: { span: 20 },
  xl: { span: 21 },
}

const typeColumns = { span: 6 };
const statecolumns = { span: 6 };
const ownerColumns = { span: 4 };
const addressColumns = { span: 8 };

type Props = {
  loading: boolean,
  showModal: boolean,
  buildings: Building[],
  getBuildings: () => void,
  modalType: ExperimentModalType,
  experimentBuildings: Building[],
  toggleModal: (modalType: ExperimentModalType) => void,
  onAddBuildings: (buildings: Building[]) => Promise<void>,
  onRemoveBuildings: (buildings: Building[]) => Promise<void>,
}

const ExperimentBuildings: FC<Props> = (props) => {
  return <>
  <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Experiment buildings title } */}
        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Buildings</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              <Button create={1} onClick={() => props.toggleModal(ExperimentModalType.ADD_BUILDINGS)}>
                add buildings
              </Button>
              { props.experimentBuildings.length > 0 && 
                <Button style={{ marginLeft: '10px' }} delete={1} onClick={() => props.toggleModal(ExperimentModalType.REMOVE_BUILDINGS)}>
                  remove buildings
                </Button>
              }
            </FloatRight>
          </Col>
        </Row>

        {/* { Buildings table } */}
        { props.experimentBuildings && props.experimentBuildings.length > 0 &&
          <Table>
            <TableHeader>
              <TableHeaderItem {...addressColumns}>
                ADDRESS
              </TableHeaderItem>
              <TableHeaderItem {...typeColumns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...statecolumns}>
                STATE
              </TableHeaderItem>
              <TableHeaderItem {...ownerColumns}>
                OWNER
              </TableHeaderItem>
            </TableHeader>
            <div>
            { props.experimentBuildings.sort((a,b) =>sortTime(Number(a.updatedAt), Number(b.updatedAt))).map(building => (
                <BuildingCom key={building.uuid} id={building.uuid}>
                  <Col {...addressColumns}>
                    {building.street} {building.number}, {building.postal} {building.city}, {building.country}
                  </Col>
                  <Col {...typeColumns}>
                    {building.type.name}
                  </Col>
                  <Col {...statecolumns}>
                    {building.state && building.state.name}
                  </Col>
                  <Col {...ownerColumns}>
                    {building.creator.firstName} {building.creator.lastName}
                  </Col>
                </BuildingCom>
              ))
            }
            </div>
          </Table>
        }

        {/* { Add buildings } */}
        <ExperimentAddBuilding
          loading={props.loading}
          buildings={props.buildings}
          getBuildings={props.getBuildings}
          experimentBuildings={props.experimentBuildings}
          visible={props.showModal && props.modalType === ExperimentModalType.ADD_BUILDINGS}
          onCancel={() => props.toggleModal(ExperimentModalType.ADD_BUILDINGS)}
          onAddBuildings={props.onAddBuildings}
        />

        {/* { Remove buildings } */}
        <ExperimentRemoveBuilding
          loading={props.loading}
          experimentBuildings={props.experimentBuildings}
          visible={props.showModal && props.modalType === ExperimentModalType.REMOVE_BUILDINGS}
          onCancel={() => props.toggleModal(ExperimentModalType.REMOVE_BUILDINGS)}
          onRemoveBuildings={props.onRemoveBuildings}
        />
      </Container>
    </Loading>
  </>
}

export default ExperimentBuildings;