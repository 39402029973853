export enum ExperimentActionTypes {
  ToggleModal = 'experiment/TOGGLE_MODAL',
  FetchPending = 'experiment/FETCH_PENDING',
  FetchSuccess = 'experiment/FETCH_SUCCESS',
  FetchFailure = 'experiment/FETCH_FAILURE',
  EditPending = 'experiment/EDIT_PENDING',
  EditSuccess = 'experiment/EDIT_SUCCESS',
  EditFailure = 'experiment/EDIT_FAILURE',
  DeletePending = 'experiment/DELETE_PENDING',
  DeleteSuccess = 'experiment/DELETE_SUCCESS',
  DeleteFailure = 'experiment/DELETE_FAILURE',
  AddBuildingPending = 'experiment/ADD_BUILDING_PENDING',
  AddBuildingSuccess = 'experiment/ADD_BUILDING_SUCCESS',
  AddBuildingFailure = 'experiment/ADD_BUILDING_FAILURE',
  RemoveBuildingPending = 'experiment/REMOVE_BUILDING_PENDING',
  RemoveBuildingSuccess = 'experiment/REMOVE_BUILDING_SUCCESS',
  RemoveBuildingFailure = 'experiment/REMOVE_BUILDING_FAILURE',
  AddParticipantPending = 'experiment/ADD_PARTICIPANT_PENDING',
  AddParticipantSuccess = 'experiment/ADD_PARTICIPANT_SUCCESS',
  AddParticipantFailure = 'experiment/ADD_PARTICIPANT_FAILURE',
  RemoveParticipantPending = 'experiment/REMOVE_PARTICIPANT_PENDING',
  RemoveParticipantSuccess = 'experiment/REMOVE_PARTICIPANT_SUCCESS',
  RemoveParticipantFailure = 'experiment/REMOVE_PARTICIPANT_FAILURE',
  AddSensorPending = 'experiment/ADD_SENSOR_PENDING',
  AddSensorSuccess = 'experiment/ADD_SENSOR_SUCCESS',
  AddSensorFailure = 'experiment/ADD_SENSOR_FAILURE',
  UpdateSensorPending = 'experiment/UPDATE_SENSOR_PENDING',
  UpdateSensorSuccess = 'experiment/UPDATE_SENSOR_SUCCESS',
  UpdateSensorFailure = 'experiment/UPDATE_SENSOR_FAILURE',
  RemoveSensorPending = 'experiment/REMOVE_SENSOR_PENDING',
  RemoveSensorSuccess = 'experiment/REMOVE_SENSOR_SUCCESS',
  RemoveSensorFailure = 'experiment/REMOVE_SENSOR_FAILURE',
  UploadDataPending = 'experiment/UPLOAD_DATA_PENDING',
  UploadDataSuccess = 'experiment/UPLOAD_DATA_SUCCESS',
  UploadDataFailure = 'experiment/UPLOAD_DATA_FAILURE',
  RemoveDataPending = 'experiment/REMOVE_DATA_PENDING',
  RemoveDataSuccess = 'experiment/REMOVE_DATA_SUCCESS',
  RemoveDataFailure = 'experiment/REMOVE_DATA_FAILURE',
  FetchDataPending = 'experiment/FETCH_DATA_PENDING',
  FetchDataSuccess = 'experiment/FETCH_DATA_SUCCESS',
  FetchDataFailure = 'experiment/FETCH_DATA_FAILURE'
}