import { ActionBluePrint } from "../core/types"
import { HeatingSystemActionTypes } from "./action.types"
import { HeatingSystem } from "../types";

export type CreateHeatingSystemParam = {
  type: string,
  description?: string,
  storageTank?: boolean,
  productionUnit?: string,
  emissionSystem?: string,
  domesticHotWater?: boolean,
  [key: string]: string | boolean | undefined
}

export enum ModalType {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
};

export type HeatingSystemState = {
  loading: boolean,
  showModal: boolean,
  modalType: ModalType
  heatingSystem?: HeatingSystem,
};

export type HeatingSystemAction<Action> = ActionBluePrint<Action, HeatingSystemState>;

export type HeatingSystemActions = ActionBluePrint<HeatingSystemActionTypes, HeatingSystemState>;
export type HeatingSystemFailureActions = HeatingSystemActionTypes.FetchFailure | HeatingSystemActionTypes.createFailure | HeatingSystemActionTypes.DeleteFailure | HeatingSystemActionTypes.EditFailure;
export type HeatingSystemPendingActions = HeatingSystemActionTypes.FetchPending | HeatingSystemActionTypes.createPending | HeatingSystemActionTypes.EditPending | HeatingSystemActionTypes.DeletePending;