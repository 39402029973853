import React, { FC } from 'react';
import { Form, Select } from 'antd';

import Item from '../../components/form.item.component';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';
import { UpdateSensorExperimentParam } from '../types';

const { Option } = Select;

type Props = {
  locations: Field[],
  positions: Field[],
  inputs: UpdateSensorExperimentParam,
  onChange: (event: OnChangeEvent) => void,
}

const ExperimentEditSensorForm: FC<Props> = (props) => {
  
  return <>
  <Form layout="vertical">
    <Item label="Location" required>
      <Select
        showSearch
        value={props.inputs.location || undefined}
        placeholder="Select a location"
        onChange={(value: string) => { props.onChange({ name: 'location', value }); }}
      >
        {props.locations.map(location => 
          <Option key={location.uuid} value={location.uuid}>{location.name}</Option>
        )}
      </Select>
    </Item>
    <Item label="Position" required>
      <Select
        showSearch
        value={props.inputs.position || undefined}
        placeholder="Select a position"
        onChange={(value: string) => { props.onChange({ name: 'position', value }); }}
      >
        {props.positions.map(position => 
          <Option key={position.uuid} value={position.uuid}>{position.name}</Option>
        )}
      </Select>
    </Item>
  </Form>
</>
} 

export default ExperimentEditSensorForm;