import { Row } from 'antd'
import styled from 'styled-components';

type TableItemProps = {
  clickable: 0 | 1
  color?: string
}

export const TableItem = styled(Row)`
  background: ${(props: TableItemProps) => {
    if (props.color) {
      return props.color
    } else {
      return '#267899'
    }
  }};
  border-radius: 25px;
  color: #FFFFFF;
  margin-top: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  ${(props: TableItemProps) => {
    if (props.clickable) {
      return `
        cursor: pointer;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        :hover {
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
        }
      `;
    } 
  }}
`;