import { HeatingSystemActionTypes } from "./action.types";
import {  HeatingSystemState, ModalType, HeatingSystemActions } from "./types";

const initialState: HeatingSystemState = {
  loading: false,
  showModal: false,
  heatingSystem: undefined,
  modalType: ModalType.CREATE,
}

export default (state: HeatingSystemState = initialState, action: HeatingSystemActions): HeatingSystemState => {
  if (Object.values(HeatingSystemActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
