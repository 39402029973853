import { BuildingsState, BuildingsActions } from "./types";
import { BuildingsActionTypes } from "./action.types";

const initialState: BuildingsState = {
  loading: false,
  showModal: false,
  buildings: [],
  filters: [],
}

export default (state: BuildingsState = initialState, action: BuildingsActions): BuildingsState => {
  if (Object.values(BuildingsActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
