import { useState, useEffect } from "react"

import { reportError } from "../core/utils";

export function useFetch<T>(func: () => Promise<T>) {
  const [result, setResult] = useState<T | undefined>(undefined);

  useEffect(() => {
    const retrieve = async () => {
      try {
        const objects = await func();
        setResult(objects);
      } catch (error) {
        reportError(error);
      }
    }
    retrieve();
  }, [func]);

  return result;
}