import { message } from 'antd';
import { Dispatch } from "redux";
import { ProfileActionTypes } from "./action.types";
import { fetchMe, putMe, postPassword, postDowngrade } from "./server";
import { pending, fetchSuccess, editSuccess, failure, passwordSuccess, downgradeSuccess } from "./action.creators";
import { reportError } from "../core/utils";
import { EditUserParams } from './types';

export const getMe = () => async (dispatch: Dispatch) => {
  dispatch(pending(ProfileActionTypes.FetchPending));
  try {
    const response = await fetchMe();
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(ProfileActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const editMe = (param: EditUserParams) => async (dispatch: Dispatch) => {
  dispatch(pending(ProfileActionTypes.EditPending));
  try {
    const response = await putMe(param);
    message.success(`Profile edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(ProfileActionTypes.EditFailure))
    await reportError(e);
  }
};

export const resetPassword = () => async (dispatch: Dispatch) => {
  dispatch(pending(ProfileActionTypes.PasswordPending));
  try {
    await postPassword();
    message.success(`You have received an email to reset your password`);
    dispatch(passwordSuccess())
  } catch (e) {
    dispatch(failure(ProfileActionTypes.PasswordFailure))
    await reportError(e);
  }
};

export const downgradeMe = () => async (dispatch: Dispatch) => {
  dispatch(pending(ProfileActionTypes.DowngradePending));
  try {
    const response = await postDowngrade();
    message.success(`You are downgraded to an Employee role`);
    dispatch(downgradeSuccess(response))
  } catch (e) {
    dispatch(failure(ProfileActionTypes.DowngradeFailure))
    await reportError(e);
  }
};
