import { api } from '../core/utils';
import { CreateHeatingSystemParam } from './types';


export const postHeatingSystem = async (type: 'experiment' | 'building', parentUuid: string, params: CreateHeatingSystemParam) => {
  if (type === 'experiment') {
    return api(`experiment/${parentUuid}/heating`, 'POST', params)
  }
  return api(`building/${parentUuid}/heating`, 'POST', params)
}

export const putHeatingSystem = async (type: 'experiment' | 'building', parentUuid: string, uuid:string, params: Partial<CreateHeatingSystemParam>) => {
  if (type === 'experiment') {
    return api(`experiment/${parentUuid}/heating/${uuid}`, 'PUT', params);
  }
  return api(`building/${parentUuid}/heating/${uuid}`, 'PUT', params);
}
 
export const delHeatingSystem = async (type: 'experiment' | 'building', parentUuid: string, uuid:string) => {
  if (type === 'experiment') {
    return api(`experiment/${parentUuid}/heating/${uuid}`, 'DELETE');
  }
  return api(`building/${parentUuid}/heating/${uuid}`, 'DELETE');
}