import { HeatingSystemActionTypes } from "./action.types";
import { HeatingSystemAction, ModalType, HeatingSystemState, HeatingSystemPendingActions, HeatingSystemFailureActions } from "./types";
import { HeatingSystem } from "../types";


export const toggleModal = (modalType: ModalType): HeatingSystemAction<HeatingSystemActionTypes.ToggleModal> => ({
  type: HeatingSystemActionTypes.ToggleModal,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      modalType,
      showModal: !state.showModal,
    };
  }
});

export const pending = (type: HeatingSystemPendingActions): HeatingSystemAction<HeatingSystemPendingActions> => ({
  type,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: HeatingSystemFailureActions): HeatingSystemAction<HeatingSystemFailureActions> => ({
  type,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (heatingSystem?: HeatingSystem): HeatingSystemAction<HeatingSystemActionTypes.createSuccess> => ({
  type: HeatingSystemActionTypes.createSuccess,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: false,
      heatingSystem
    }
  }
});

export const createSuccess = (heatingSystem: HeatingSystem): HeatingSystemAction<HeatingSystemActionTypes.createSuccess> => ({
  type: HeatingSystemActionTypes.createSuccess,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: false,
      showModal: false,
      heatingSystem
    }
  }
});

export const editSuccess = (heatingSystem: HeatingSystem): HeatingSystemAction<HeatingSystemActionTypes.EditSuccess> => ({
  type: HeatingSystemActionTypes.EditSuccess,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: false,
      showModal: false,
      heatingSystem
    }
  }
});

export const deleteSuccess = (): HeatingSystemAction<HeatingSystemActionTypes.DeleteSuccess> => ({
  type: HeatingSystemActionTypes.DeleteSuccess,
  reducer(state): HeatingSystemState {
    return {
      ...state,
      loading: false,
      heatingSystem: undefined
    }
  }
});