import React, { FC, FormEvent } from 'react';
import { Row, Form, Input, Switch, Select } from 'antd';

import Col from '../../components/form.col.component';
import Item from '../../components/form.item.component';
import { CreateHeatingSystemParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

const { Option } = Select;
const { TextArea } = Input;

const typeColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
  xl: { span: 8 }
};

const producColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
  xl: { span: 8 }
};

const emissiColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 8 },
  xl: { span: 8 }
};

const descrColumns = {
  xs: { span: 24 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const domesColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const storageColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

type Props = {
  inputs: Partial<CreateHeatingSystemParam>,
  onChange: (event: OnChangeEvent) => void,
  heatingSystemTypes: Field[],
  heatingSystemEmissionSystems: Field[],
  heatingSystemProductionUnits: Field[],
}

const EditHeatingForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value })
  }

  return <>
  <Form layout="vertical">
    <Row>
      <Col {...typeColumns}>
        <Item label="Type" required>
          <Select
            showSearch
            value={props.inputs.type || undefined}
            placeholder="Select a type"
            onSelect={(value: string) => { props.onChange({ name: 'type', value }); }}
          >
            {props.heatingSystemTypes.map(heatingSystemType => 
              <Option key={heatingSystemType.uuid} value={heatingSystemType.uuid}>{heatingSystemType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
      <Col {...producColumns}>
        <Item label="Production unit">
          <Select
            showSearch
            value={props.inputs.productionUnit || undefined}
            placeholder="Select a production unit"
            onSelect={(value: string) => { props.onChange({ name: 'productionUnit', value }); }}
          >
           {props.heatingSystemProductionUnits.map(heatingSystemProductionUnit => 
              <Option key={heatingSystemProductionUnit.uuid} value={heatingSystemProductionUnit.uuid}>{heatingSystemProductionUnit.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
      <Col {...emissiColumns}>
        <Item label="Emission system">
          <Select
            showSearch
            value={props.inputs.emissionSystem || undefined}
            placeholder="Select an emission system"
            onSelect={(value: string) => { props.onChange({ name: 'emissionSystem', value }); }}
          >
            {props.heatingSystemEmissionSystems.map(heatingSystemEmissionSystem => 
              <Option key={heatingSystemEmissionSystem.uuid} value={heatingSystemEmissionSystem.uuid}>{heatingSystemEmissionSystem.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col {...descrColumns}>
        <Item label="Description">
          <TextArea name="description" rows={4} value={props.inputs.description} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...domesColumns} >
        <Item label="Domestic hot water">
          <Switch
            checked={props.inputs.domesticHotWater}
            onChange={(checked: boolean) => { props.onChange({ name: 'domesticHotWater', value: checked }); }}
          />
        </Item>
      </Col>
      <Col {...storageColumns}>
        <Item label="Storage tank">
          <Switch
            checked={props.inputs.storageTank}
            onChange={(checked: boolean) => { props.onChange({ name: 'storageTank', value: checked }); }}
          />
        </Item>
      </Col>
    </Row>
  </Form>
</>
} 

export default EditHeatingForm;