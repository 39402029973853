export enum HeatingSystemActionTypes {
  ToggleModal = 'heatingsystem/TOGGLE_MODAL',
  FetchPending = 'heatingsystem/FETCH_PENDING',
  FetchSuccess = 'heatingsystem/FETCH_SUCCESS',
  FetchFailure = 'heatingsystem/FETCH_FAILURE',
  createPending = 'heatingsystem/CREATE_PENDING',
  createSuccess = 'heatingsystem/CREATE_SUCCESS',
  createFailure = 'heatingsystem/CREATE_FAILURE',
  EditPending = 'heatingsystem/EDIT_PENDING',
  EditSuccess = 'heatingsystem/EDIT_SUCCESS',
  EditFailure = 'heatingsystem/EDIT_FAILURE',
  DeletePending = 'heatingsystem/DELETE_PENDING',
  DeleteSuccess = 'heatingsystem/DELETE_SUCCESS',
  DeleteFailure = 'heatingsystem/DELETE_FAILURE',
}