import { Col } from "antd";
import React, { FC, useEffect, useState } from 'react';

import Modal from '../../components/modal.component';
import FilterView from "../../filter/components/filter.view";
import { Filter } from "../../filter/types";
import userOptions from "../../filter/options/user";
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";
import { User } from "../../types";

const columns = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 8 },
  lg: { span: 8 },
  xl: { span: 8 }
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  users: User[],
  getUsers: () => void,
  participants: User[],
  onAddParticipants: (users: User[]) => void,
}

const ExperimentAddParticipant: FC<Props> = (props) => {
  const getServerUsers = props.getUsers;
  const usersLength = props.users.length;
  useEffect(() => getServerUsers(), [getServerUsers, usersLength]);
  const [selectedParticipants, selectUsers] = useState<User[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);

  const onSubmit = async () => {
    await props.onAddParticipants(selectedParticipants);
    selectUsers([]);
  }

  const onSelect = (user: User) => {
    if (selectedParticipants.includes(user)) {
      selectUsers(selectedParticipants.filter(usr => usr.uuid !== user.uuid));
    } else {
      selectUsers(selectedParticipants.concat([user]));
    }
  }

  const onCancel = () => {
    selectUsers([]);
    props.onCancel();
  }

  const getUsers = () => {
    const experimentParticipantsUids = props.participants.map(usr => usr.uuid);
    const filteredUsers = props.users.filter(usr => !selectedParticipants.includes(usr) && !experimentParticipantsUids.includes(usr.uuid));
    return filters.reduce<User[]>((acc, curr) => {
      return acc.filter(usr => curr.exec(usr))
    }, filteredUsers);
  }
  
  return <> 
    <Modal
      title='Add participants'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >

       {/* Filter */}
       <FilterView
        options={userOptions}
        filters={filters}
        setFilters={setFilters}
      />

      <Table>
        <TableHeader>
          <TableHeaderItem {...columns}>
            FIRSTNAME
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            LASTNAME
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            ROLE
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedParticipants.map(user => (
            <TableItem clickable={1} color="#77B28C" key={user.uuid} onClick={() => onSelect(user)}>
              <Col {...columns}>
                {user.firstName}
              </Col>
              <Col {...columns}>
                {user.lastName}
              </Col>
              <Col {...columns}>
                {user.role}
              </Col>
            </TableItem>
          ))}
        { getUsers().map(user => (
            <TableItem clickable={1} key={user.uuid} onClick={() => onSelect(user)}>
              <Col {...columns}>
                {user.firstName}
              </Col>
              <Col {...columns}>
                {user.lastName}
              </Col>
              <Col {...columns}>
                {user.role}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ExperimentAddParticipant;