import fileOptions from "./file";
import buildingTypeOptions from './buildingType';
import buildingStateOptions from './buildingState';
import heatingSystemOptions from './heatingSystem';
import userOptions from "./user";
import { NodeTypes, InputTypes } from "../types";

const buildingOptions = [
  {
    value: 'type',
    label: 'Type',
    node: NodeTypes.ELEMENT,
    children: buildingTypeOptions,
  },
  {
    value: 'files',
    label: 'File',
    node: NodeTypes.ELEMENT,
    children: fileOptions,
  },
  {
    value: 'state',
    label: 'State',
    node: NodeTypes.ELEMENT,
    children: buildingStateOptions,
  },
  {
    value: 'heatingSystem',
    label: 'Heating system',
    node: NodeTypes.ELEMENT,
    children: heatingSystemOptions,
  },
  {
    value: 'description',
    label: 'Description',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'performanceCalculations',
    label: 'Performance calculations',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'blowerdoor',
    label: 'Blowerdoor',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'accubalance',
    label: 'Accubalance',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'smokeGas',
    label: 'Smoke gas',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'plans',
    label: 'Plans',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'country',
    label: 'Country',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'city',
    label: 'City',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'postal',
    label: 'Postal',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'street',
    label: 'Street',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'number',
    label: 'Number',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'constructionDate',
    label: 'Construction date',
    node: NodeTypes.LEAF,
    input: InputTypes.DATE
  },
  {
    value: 'creator',
    label: 'Creator',
    node: NodeTypes.ELEMENT,
    children: userOptions,
  }
]

export default buildingOptions;