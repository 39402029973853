import { ExperimentState, ExperimentActions, ExperimentModalType } from "./types";
import { ExperimentActionTypes } from "./action.types";

const initialState: ExperimentState = {
  loading: false,
  showModal: false,
  experiment: undefined,
  modalType: ExperimentModalType.EDIT,
}

export default (state: ExperimentState = initialState, action: ExperimentActions): ExperimentState => {
  if (Object.values(ExperimentActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
