import { Col } from 'antd';
import styled from 'styled-components';


const Text = styled(Col)`
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  line-height: 21px;
  margin-top: 10px;
`;

export default Text;
