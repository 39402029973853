import { UserActionTypes } from './action.types';
import { UserAction, UserState, UserPendingActions, UserFailureActions  } from './types';
import { User } from '../types';

export const pending = (type: UserPendingActions): UserAction<UserPendingActions> => ({
  type,
  reducer(state): UserState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: UserFailureActions): UserAction<UserFailureActions> => ({
  type,
  reducer(state): UserState {
    return {
      ...state,
      loading: false,
    }
  }
});

export const fetchSuccess = (user: User): UserAction<UserActionTypes.FetchSuccess> => ({
  type: UserActionTypes.FetchSuccess,
  reducer(state): UserState {
    return {
      ...state,
      user,
      loading: false
    }
  }
});

export const downgradeSuccess = (user: User): UserAction<UserActionTypes.DowngradeSuccess> => ({
  type: UserActionTypes.DowngradeSuccess,
  reducer(state): UserState {
    return {
      ...state,
      user,
      loading: false,
    }
  }
});

export const upgradeSuccess = (user: User): UserAction<UserActionTypes.UpgradeSuccess> => ({
  type: UserActionTypes.UpgradeSuccess,
  reducer(state): UserState {
    return {
      ...state,
      user,
      loading: false,
    }
  }
});