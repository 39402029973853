import moment from 'moment';
import React, { FC } from "react";
import { Row, Col, Typography } from "antd";

import { ProjectModalType } from '../types';
import { sortTime } from '../../core/utils';
import Loading from "../../components/loading.component";
import FloatRight from '../../components/float.right.component';
import ProjectAddExperiment from './project.add.experiment.view';
import ExperimentCom from '../../components/experiment.component';
import ProjectRemoveExperiment from './project.remove.experiment';
import { Container, Button, Table, TableHeader, TableHeaderItem } from '../../components';
import { Experiment } from '../../types';

const { Title } = Typography;

const titleColumns = {
  xs: { span: 12 },
  sm: { span: 8 },
  md: { span: 6 },
  lg: { span: 4 },
  xl: { span: 3 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 16 },
  md: { span: 18 },
  lg: { span: 20 },
  xl: { span: 21 },
}

const nameColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 7 },
}
const typeColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 6 },
}
const startDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const endDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const daysColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 2 },
  xl: { span: 2 },
}
const ownerColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 4 },
  xl: { span: 3 },
}

type Props = {
  loading: boolean,
  showModal: boolean,
  onAddExperiments: (experiments: Experiment[]) => Promise<void>,
  onRemoveExperiments: (experiments: Experiment[]) => Promise<void>,
  modalType: ProjectModalType,
  projectExperiments: Experiment[],
  toggleModal: (modalType: ProjectModalType) => void
}

const ProjectExperiments: FC<Props> = (props) => {
  return <>
  <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Project experiment title } */}
        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Experiments</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              <Button create={1} onClick={() => props.toggleModal(ProjectModalType.ADD_EXPERIMENTS)}>
                add experiments
              </Button>
              { props.projectExperiments.length > 0 && 
                <Button style={{ marginLeft: '10px' }} delete={1} onClick={() => props.toggleModal(ProjectModalType.REMOVE_EXPERIMENTS)}>
                  remove experiments
                </Button>
              }
            </FloatRight>
          </Col>
        </Row>

        {/* { Experiments table } */}
        { props.projectExperiments && props.projectExperiments.length > 0 &&
          <Table>
            <TableHeader>
              <TableHeaderItem {...nameColumns}>
                NAME
              </TableHeaderItem>
              <TableHeaderItem {...typeColumns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...startDateColumns}>
                START DATE
              </TableHeaderItem>
              <TableHeaderItem {...endDateColumns}>
                END DATE
              </TableHeaderItem>
              <TableHeaderItem {...daysColumns}>
                #DAYS
              </TableHeaderItem>
              <TableHeaderItem {...ownerColumns}>
                OWNER
              </TableHeaderItem>
            </TableHeader>
            <div>
            { props.projectExperiments.sort((a,b) => sortTime(Number(a.updatedAt), Number(b.updatedAt))).map(experiment => (
                <ExperimentCom key={experiment.uuid} id={experiment.uuid}>
                  <Col {...nameColumns}>
                    {experiment.name}
                  </Col>
                  <Col {...typeColumns}>
                    {experiment.type.name}
                  </Col>
                  <Col {...startDateColumns}>
                    {experiment.start && moment(Number(experiment.start)).format('DD/MM/YYYY')}
                  </Col>
                  <Col {...endDateColumns}>
                    {experiment.stop && moment(Number(experiment.stop)).format('DD/MM/YYYY')}
                  </Col>
                  <Col {...daysColumns}>
                    {experiment.start && experiment.stop && moment(Number(experiment.stop)).diff(moment(Number(experiment.start)), 'days')}
                  </Col>
                  <Col {...ownerColumns}>
                    {experiment.creator.firstName} {experiment.creator.lastName}
                  </Col>
                </ExperimentCom>
              ))
            }
            </div>
          </Table>
        }

        {/* { Manage experiments } */}
        <ProjectAddExperiment
          loading={props.loading}
          projectExperiments={props.projectExperiments}
          visible={props.showModal && props.modalType === ProjectModalType.ADD_EXPERIMENTS}
          onCancel={() => props.toggleModal(ProjectModalType.ADD_EXPERIMENTS)}
          onAddExperiments={props.onAddExperiments}
        />

        {/* { Remove experiment } */}
        <ProjectRemoveExperiment
          loading={props.loading}
          projectExperiments={props.projectExperiments}
          visible={props.showModal && props.modalType === ProjectModalType.REMOVE_EXPERIMENTS}
          onCancel={() => props.toggleModal(ProjectModalType.REMOVE_EXPERIMENTS)}
          onRemoveExperiments={props.onRemoveExperiments}
        />
      </Container>
    </Loading>
  </>
}

export default ProjectExperiments;