import uuid from 'uuid/v4';
import moment from 'moment';
import React, { FC, useState, FormEvent } from 'react';
import { CascaderOptionType } from 'antd/lib/cascader';
import { Cascader, Row, Tag, Input, Switch, DatePicker } from 'antd';

import { InputTypes, isFilterOptionType, Filter } from '../types';
import { getTextualRepresentationOfValue, createFilterFunction } from '../util';
import { Button } from '../../components';

type Props = {
  filters: Filter[],
  setFilters: (filters: Filter[]) => void,
  options: CascaderOptionType[],
}

const FilterView: FC<Props> = (props) => {

  const [cascaderValue, setCascaderValue] = useState<string[]>();
  const [cascaderOptions, setCascaderOptions] = useState<CascaderOptionType[]>();
  const [inputValue, setInputValue] = useState<string | boolean | moment.Moment | undefined>();
  const [inputType, setInputType] = useState<InputTypes | undefined>();

  const resetValues = () => {
    setInputType(undefined);
    setInputValue(undefined);
    setCascaderValue([]);
  }

  const onCascaderChange = (value: string[], selectedOptions?: CascaderOptionType[]) => {
    if (value.length === 0) {
      resetValues();
    } else if (selectedOptions && selectedOptions.length > 0) {
      setCascaderValue(value);
      const lastOption = selectedOptions[selectedOptions.length - 1];
      if (isFilterOptionType(lastOption) && lastOption.input) {
        setInputType(lastOption.input);
        setCascaderOptions(selectedOptions);
        if (lastOption.input === InputTypes.BOOLEAN) {
          setInputValue(false);
        }
      }
    }
  }

  const onTagClose = (e: Filter) => {
    const newFilters = props.filters.filter(filter => filter.id !== e.id);
    props.setFilters(newFilters);
  }

  const onInputChange = (e: FormEvent<HTMLInputElement>) => {
    setInputValue(e.currentTarget.value);
  }

  const onSwitchChange = (e: boolean) => {
    setInputValue(e);
  }

  const onDateChange = (date: moment.Moment | null, dateString: string) => {
    if (date !== null) {
      setInputValue(date);
    } else {
      setInputValue(undefined);
    }
  }

  const onSaveClick = () => {
    if (cascaderOptions && inputValue !== undefined) {
      const filter: Filter = {
        id: uuid(),
        value: getTextualRepresentationOfValue(inputValue),
        exec: createFilterFunction(cascaderOptions, inputValue),
        label: cascaderOptions.map(value => value.label).join('/'),
      }
      const newFilters = props.filters.concat([filter])
      props.setFilters(newFilters);
    }
    resetValues();
  }

  const generateInput = (inputType: InputTypes) => {
    switch (inputType) {
      case InputTypes.STRING:
        return <Input placeholder="value" style={{ width: '200px', marginLeft: '10px'}} onChange={onInputChange} />
      case InputTypes.BOOLEAN:
        return <Switch style={{ marginLeft: '10px' }} onChange={onSwitchChange} />
      case InputTypes.DATE:
        return <DatePicker format="DD/MM/YYYY" style={{ marginLeft: '10px' }} autoFocus={true} onChange={onDateChange} />
    }
  }

  return <>
  <div>
    <Row>
      <Cascader
        style={{ backgroundColor: '#267899', color: '#ffffff', width: 'max-content' }}
        options={props.options}
        onChange={onCascaderChange}
        placeholder="Filter"
        expandTrigger="hover"
        value={cascaderValue}
      />
      {
        inputType && <>
          {
            generateInput(inputType)
          }
          <Button style={{ marginLeft: '10px' }} onClick={onSaveClick}>
              Save
          </Button>  
        </>
      }
    </Row>
    <Row style={{ marginTop: '20px' }}>
      {
        props.filters.map(filter =>
        <Tag key={filter.id} closable color="#267899" onClose={() => onTagClose(filter)}>{filter.label}: {filter.value}</Tag>
        )
      }
    </Row>
  </div>
        
  </>
}

export default FilterView;