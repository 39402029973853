import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import BuildingView from './building.view';
import { AppState } from '../../core/types';
import { toggleModal } from '../action.creators';
import { getBuilding, editBuilding, deleteBuilding } from '../operations';
import { CreateBuildingParam } from '../../buildings/types';

const mapStateToProps = (state: AppState) => {
  return { 
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: () => {
      dispatch(toggleModal());
    },
    getBuilding: (uuid: string) => {
      dispatch(getBuilding(uuid));
    },
    updateBuilding: async (uuid: string, param: Partial<CreateBuildingParam>) => {
      await dispatch(editBuilding(uuid, param));
    },
    deleteBuilding: async (uuid: string) => {
      await dispatch(deleteBuilding(uuid));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BuildingView);
