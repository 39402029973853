import { message } from 'antd';
import { Dispatch } from "redux";

import { CreateUserParams } from "./types";
import { UsersActionTypes } from "./action.types";
import { fetchUsers, postUser } from "./server";
import { pending, fetchSuccess, createSuccess, failure } from "./action.creators";
import { reportError } from '../core/utils';

export const getUsers = () => async (dispatch: Dispatch) => {
  dispatch(pending(UsersActionTypes.FetchPending));
  try {
    const response = await fetchUsers();
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(UsersActionTypes.FetchFailure));
    await reportError(e);
  }
};

export const createUser = (param: CreateUserParams ) => async (dispatch: Dispatch) => {
  dispatch(pending(UsersActionTypes.CreatePending));
  try {
    const response = await postUser(param);
    message.success(`User ${response.firstName} created`);
    dispatch(createSuccess(response))
  } catch (e) {
    dispatch(failure(UsersActionTypes.CreateFailure));
    await reportError(e);
  }
};