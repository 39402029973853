import { Link } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import { TableItem } from '../../components';

type Props = {
  uuid: string
}

const User: FunctionComponent<Props> = (props) => {

  return <>
    <Link to={`/user/${props.uuid}`}>
      <TableItem clickable={1}>
        {props.children}
      </TableItem>
    </Link>
  </>

}

export default User;