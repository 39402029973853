import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import UsersView from './users.view';
import { toggleModal, updateFilters } from '../action.creators';
import { getUsers, createUser } from '../operations';
import { AppState } from '../../core/types';
import { NAME } from '../constants';
import { CreateUserParams } from '../types';
import { Filter } from '../../filter/types';

const mapStateToProps = (state: AppState) => {
  return { 
    ...state[NAME],
    userRole: state.profile.user && state.profile.user.role
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    getUsers: () => { dispatch(getUsers()); },
    toggleModal: () => { dispatch(toggleModal()); },
    setFilters: (filters: Filter[]) => dispatch(updateFilters(filters)),
    createUser: async (params: CreateUserParams) => {
      await dispatch(createUser(params));
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(UsersView);;