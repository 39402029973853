import { Spin } from 'antd';
import React, { FC } from 'react';

const loader =
  <div className="sk-folding-cube">
    <div className="sk-cube1 sk-cube" />
    <div className="sk-cube2 sk-cube" />
    <div className="sk-cube4 sk-cube" />
    <div className="sk-cube3 sk-cube" />
  </div>;

type Props = {
  spinning?: boolean
}

const Loading: FC<Props> = (props) => {
  return <>
    <Spin spinning={props.spinning ? true : false} indicator={loader} size="large">
      {props.children}
    </Spin>
  </>
}

export default Loading;