import React, { FC, useState, useEffect } from 'react';

import { OnChangeEvent } from '../../core/types';
import Modal from '../../components/modal.component';
import ExperimentEditSensorForm from './experiment.edit.sensor.form';
import { UpdateSensorExperimentParam } from '../types';
import { Field, SensorExperiment } from '../../types';

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  experimentSensor: SensorExperiment,
  locations: Field[],
  positions: Field[],
  onEditSensor: (params: UpdateSensorExperimentParam) => Promise<void>,
}

const ExperimentEditSensorView: FC<Props> = (props) => {

  const init: UpdateSensorExperimentParam = {
    location: props.experimentSensor.location && props.experimentSensor.location.uuid,
    position: props.experimentSensor.position && props.experimentSensor.position.uuid
  }

  const [inputs, setInputs] = useState<Partial<UpdateSensorExperimentParam>>({});

  // eslint-disable-next-line
  useEffect(() => setInputs({ ...init }), [props.experimentSensor.uuid]);

  const onSubmit = async () => {
    if (!disableOkButton()) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.onEditSensor(params);
    }
  }

  const onChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({ ...init});
    props.onCancel(); 
  }

  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
   return disable;
  }

  return <> 
  <Modal
    title={`Edit ${props.experimentSensor.sensor.name} sensor`}
    visible={props.visible}
    onOk={onSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Edit"
    cancelText="Cancel"
    confirmLoading={props.loading}
    disableOkButton={disableOkButton()}
  >
    <ExperimentEditSensorForm
      inputs={inputs}
      onChange={onChange}
      locations={props.locations}
      positions={props.positions}
    />
  </Modal>
</>
}

export default ExperimentEditSensorView;