import { Col } from "antd";
import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import { Building } from "../../types";
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";

const typeColumns = { span: 6 };
const statecolumns = { span: 6 };
const ownerColumns = { span: 4 };
const addressColumns = { span: 8 };

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  experimentBuildings: Building[],
  onRemoveBuildings: (buildings: Building[]) => Promise<void>,
}

const ExperimentRemoveBuilding: FC<Props> = (props) => {

  const [selectedBuildings, selectBuildings] = useState<Building[]>([]);

  const onSubmit = async () => {
    await props.onRemoveBuildings(selectedBuildings);
    selectBuildings([]);
  }

  const onSelect = (building: Building) => {
    if (selectedBuildings.includes(building)) {
      selectBuildings(selectedBuildings.filter(buil => buil.uuid !== building.uuid));
    } else {
      selectBuildings(selectedBuildings.concat([building]));
    }
  }

  const onCancel = () => {
    selectBuildings([]);
    props.onCancel();
  }

  const getBuildings = () => {
    const filteredBuil = props.experimentBuildings.filter(buil => !selectedBuildings.includes(buil));
    return filteredBuil;
  }
  
  return <> 
    <Modal
      title='Remove buildings'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      <Table>
      <TableHeader>
          <TableHeaderItem {...addressColumns}>
            ADDRESS
          </TableHeaderItem>
          <TableHeaderItem {...typeColumns}>
            TYPE
          </TableHeaderItem>
          <TableHeaderItem {...statecolumns}>
            STATE
          </TableHeaderItem>
          <TableHeaderItem {...ownerColumns}>
            OWNER
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedBuildings.map(building => (
            <TableItem clickable={1} color="#DC493A" key={building.uuid} onClick={() => onSelect(building)}>
              <Col {...addressColumns}>
                {building.street} {building.number}, {building.postal} {building.city}, {building.country}
              </Col>
              <Col {...typeColumns}>
                {building.type.name}
              </Col>
              <Col {...statecolumns}>
                {building.state && building.state.name}
              </Col>
              <Col {...ownerColumns}>
                {building.creator.firstName} {building.creator.lastName}
              </Col>
            </TableItem>
          ))}
        { getBuildings().map(building => (
            <TableItem clickable={1} key={building.uuid} onClick={() => onSelect(building)}>
               <Col {...addressColumns}>
                {building.street} {building.number}, {building.postal} {building.city}, {building.country}
              </Col>
              <Col {...typeColumns}>
                {building.type.name}
              </Col>
              <Col {...statecolumns}>
                {building.state && building.state.name}
              </Col>
              <Col {...ownerColumns}>
                {building.creator.firstName} {building.creator.lastName}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ExperimentRemoveBuilding;