import { BuildingStateRedux, BuildingActions } from "./types";
import { BuildingActionTypes } from "./action.types";

const initialState: BuildingStateRedux = {
  loading: false,
  showModal: false,
  building: undefined,
}

export default (state: BuildingStateRedux = initialState, action: BuildingActions): BuildingStateRedux => {
  if (Object.values(BuildingActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
