import { UserState, UserActions } from "./types";
import { UserActionTypes } from "./action.types";

const initialState: UserState = {
  loading: false,
}

export default (state: UserState = initialState, action: UserActions): UserState => {
  if (Object.values(UserActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
