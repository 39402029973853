import React, { FC, FormEvent } from 'react';
import { Form, Input } from 'antd';

import Item from '../../components/form.item.component';
import { EditProjectParams } from '../types';

const { TextArea } = Input;

type Props = {
  inputs: EditProjectParams
  onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const EditProjectForm: FC<Props> = (props) => {
  return <>
  <Form layout="vertical">
    <Item label="Name" required>
      <Input name="name" value={props.inputs.name} onChange={props.onChange}/>
    </Item>
    <Item label="Keywords (before / after retrofit, in-situ / lab, social housing / private housing, PhD + name)">
      <TextArea rows={4} name="keywords" value={props.inputs.keywords} onChange={props.onChange}/>
    </Item>
    <Item label="Description">
      <TextArea rows={4} name="description" value={props.inputs.description} onChange={props.onChange}/>
    </Item>
  </Form>
</>
} 

export default EditProjectForm;