import { message } from 'antd';
import { Dispatch } from "redux";

import { reportError } from '../core/utils';
import { CreateBuildingParam } from "./types";
import { BuildingsActionTypes } from "./action.types";
import { fetchBuildings, postBuilding } from "./server";
import { pending, failure, fetchSuccess, createSuccess } from "./action.creators";


export const getBuildings = () => async (dispatch: Dispatch) => {
  dispatch(pending(BuildingsActionTypes.FetchPending));
  try {
    const response = await fetchBuildings();
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(BuildingsActionTypes.FetchFailure));
    await reportError(e);
  }
};

export const createBuilding = (param: CreateBuildingParam) => async (dispatch: Dispatch) => {
  dispatch(pending(BuildingsActionTypes.CreatePending));
  try {
    const response = await postBuilding(param);
    message.success(`Building created`);
    dispatch(createSuccess(response))
  } catch (e) {
    dispatch(failure(BuildingsActionTypes.CreateFailure));
    await reportError(e);
  }
};