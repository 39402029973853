import { api } from '../core/utils';
import { CreateBuildingParam } from '../buildings/types';

export const fetchBuilding = async (uuid:string) => {
  return api(`building/${uuid}`, 'GET');
}

export const putBuilding = async (uuid:string, params: Partial<CreateBuildingParam>) => {
  return api(`building/${uuid}`, 'PUT', params);
}
 
export const delBuilding = async (uuid:string) => {
  return api(`building/${uuid}`, 'DELETE');
}
