import { api } from '../core/utils';

export const fetchSensor = async (uuid: string) => {
  return api(`sensor/${uuid}`, 'GET');
}

export const putSensor = async (uuid: string, params: any) => {
  return api(`sensor/${uuid}`, 'PUT', params);
}
 
export const delSensor = async (uuid: string) => {
  return api(`sensor/${uuid}`, 'DELETE');
}

export const fetchSensors = async () => {
  return api('sensor', 'GET');
};

export const postSensor = async (params: any) => {
  return api('sensor', 'POST', params);
};