import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

import apiConfig from '../configurations/api.configuration';
import AuthConfig from '../configurations/authentication.configuration';


interface IAuthenticationController {
  getUser: () => Promise<any>;
  login: () => Promise<void>;
  logout: () => Promise<void>;
  isLoggedIn: () => Promise<boolean>;
  handleRedirect: () => Promise<void>;
}

class AuthenticationController implements IAuthenticationController {

  private auth0: Promise<Auth0Client>;

  constructor(authConfig: AuthConfig) {
    this.auth0 = createAuth0Client(authConfig);
  }

  async handleRedirect() {
    const auth0 = await this.auth0;
    await auth0.handleRedirectCallback();
    window.history.replaceState({}, document.title, window.location.pathname);
  }

  async login() {
    const auth0 = await this.auth0;
    await auth0.loginWithRedirect({
      redirect_uri: apiConfig.localUrl,
    });
  }

  async getUser() {
    const auth0 = await this.auth0;
    const isAuthenticated = await this.isLoggedIn();
    if (isAuthenticated) {
      const user = await auth0.getUser();
      return user;
    }
  }

  async logout() {
    const auth0 = await this.auth0;
    auth0.logout({
      returnTo: apiConfig.localUrl
    });

  }

  async isLoggedIn() {
    const auth0 = await this.auth0;
    const isAuthenticated = await auth0.isAuthenticated();
    return isAuthenticated;
  }

  async getToken() {
    const auth0 = await this.auth0;
    const token = await auth0.getTokenSilently();
    return token
  }
}


const authenticationController = new AuthenticationController(AuthConfig);

export default authenticationController;