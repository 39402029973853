export enum FileActionTypes {
  ToggleModal = 'file/TOGGLE_MODAL',
  FetchPending = 'file/FETCH_PENDING',
  FetchSuccess = 'file/FETCH_SUCCESS',
  FetchFailure = 'file/FETCH_FAILURE',
  CreatePending = 'file/CREATE_PENDING',
  CreateSuccess = 'file/CREATE_SUCCESS',
  CreateFailure = 'file/CREATE_FAILURE',
  EditPending = 'file/EDIT_PENDING',
  EditSuccess = 'file/EDIT_SUCCESS',
  EditFailure = 'file/EDIT_FAILURE',
  DeletePending = 'file/DELETE_PENDING',
  DeleteSuccess = 'file/DELETE_SUCCESS',
  DeleteFailure = 'file/DELETE_FAILURE',
}