import userOptions from './user';
import productionUnitOptions from './productionUnitOptions';
import emissionSystemOptions from './emissionSystemOptions';
import heatingSystemTypeOptions from './heatingSystemTypeOptions';
import { InputTypes, NodeTypes, FilterOptionType } from '../types';

const heatingSystemOptions: FilterOptionType[] = [
  {
    value: 'description',
    label: 'Description',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'storageTank',
    label: 'Storage tank',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN
  },
  {
    value: 'domesticHotWater',
    label: 'Domestic hot water ',
    node: NodeTypes.LEAF,
    input: InputTypes.BOOLEAN,
  },
  {
    value: 'type',
    label: 'Type',
    node: NodeTypes.ELEMENT,
    children: heatingSystemTypeOptions,
  },
  {
    value: 'productionUnit',
    label: 'Production unit',
    node: NodeTypes.ELEMENT,
    children: productionUnitOptions,
  },
  {
    value: 'emissionSystem',
    label: 'Emission system',
    node: NodeTypes.ELEMENT,
    children: emissionSystemOptions,
  },
  {
    value: 'creator',
    label: 'Creator',
    node: NodeTypes.ELEMENT,
    children: userOptions,
  }
];

export default heatingSystemOptions;