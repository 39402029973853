import { FilterOptionType, NodeTypes, InputTypes } from "../types";

const experimentTypeOptions: FilterOptionType[] = [
  {
    value: 'name',
    label: 'Name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
];

export default experimentTypeOptions;