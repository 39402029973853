import { Button, Col, Layout, Row, Typography } from 'antd';
import React, { FC } from 'react';

const { Content } = Layout;
const { Title, Paragraph, Text } = Typography;

const NotConnected: FC = () => {
  return <>
  <Layout>
    <Content style={{ padding: '50px 50px' }}>
      <Row type="flex" justify="center">
        <Col style={{ textAlign: "center" }} xs={8}>
        <Title level={1}>Unable to connect to the server</Title>
        <Paragraph>
          The application could not connect to the server, make sure you have an active internet connection.
          When working from home make sure you are connected to <Text strong={true}>Pulse Secure: B-Zone</Text>.
        </Paragraph>
        <Button type="primary" onClick={() => window.location.reload()}>Refresh</Button>
        </Col>
      </Row>
    </Content>
  </Layout>
  </>
}

export default NotConnected;
