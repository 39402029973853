import React, { FC } from "react";
import { Row, Col, Typography } from "antd";

import { ExperimentModalType } from '../types';
import Loading from "../../components/loading.component";
import UserCom from '../../components/user.component';
import FloatRight from '../../components/float.right.component';
import ExperimentAddParticipant from "./experiment.add.participant.view";
import ExperimentRemoveParticipant from "./experiment.remove.participant.view";
import { Container, Button, Table, TableHeader, TableHeaderItem } from "../../components";
import { User } from "../../types";

const { Title } = Typography;

const titleColumns = {
  xs: { span: 12 },
  sm: { span: 8 },
  md: { span: 6 },
  lg: { span: 4 },
  xl: { span: 3 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 16 },
  md: { span: 18 },
  lg: { span: 20 },
  xl: { span: 21 },
}

const columns = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 8 },
  lg: { span: 8 },
  xl: { span: 8 }
}

type Props = {
  loading: boolean,
  showModal: boolean,
  users: User[],
  getUsers: () => void,
  modalType: ExperimentModalType,
  participants: User[],
  toggleModal: (modalType: ExperimentModalType) => void,
  onAddParticipants: (users: User[]) => Promise<void>,
  onRemoveParticipants: (users: User[]) => Promise<void>,
}

const ExperimentParticipants: FC<Props> = (props) => {
  return <>
  <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Experiment participants title } */}
        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Participants</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              { props.participants.length !== props.users.length && 
                <Button create={1} onClick={() => props.toggleModal(ExperimentModalType.ADD_PARTICIPANTS)}>
                  add participants
                </Button>
              }
              { props.participants.length > 0 && 
                <Button style={{ marginLeft: '10px' }} delete={1} onClick={() => props.toggleModal(ExperimentModalType.REMOVE_PARTICIPANTS)}>
                  remove participants
                </Button>
              }
            </FloatRight>
          </Col>
        </Row>

        {/* { Participants table } */}
        { props.participants && props.participants.length > 0 &&
          <Table>
            <TableHeader>
              <TableHeaderItem {...columns}>
                FIRSTNAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                LASTNAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                ROLE
              </TableHeaderItem>
            </TableHeader>
            <div>
            { props.participants.map(participant => (
                <UserCom key={participant.uuid} id={participant.uuid}>
                  <Col {...columns}>
                    {participant.firstName}
                  </Col>
                  <Col {...columns}>
                    {participant.lastName}
                  </Col>
                  <Col {...columns}>
                    {participant.role}
                  </Col>
                </UserCom>
              ))
            }
            </div>
          </Table>
        }

        {/* { Add participants } */}
        <ExperimentAddParticipant
          loading={props.loading}
          users={props.users}
          getUsers={props.getUsers}
          participants={props.participants}
          visible={props.showModal && props.modalType === ExperimentModalType.ADD_PARTICIPANTS}
          onCancel={() => props.toggleModal(ExperimentModalType.ADD_PARTICIPANTS)}
          onAddParticipants={props.onAddParticipants}
        />

        {/* { Remove participants } */}
        <ExperimentRemoveParticipant
          loading={props.loading}
          participants={props.participants}
          visible={props.showModal && props.modalType === ExperimentModalType.REMOVE_PARTICIPANTS}
          onCancel={() => props.toggleModal(ExperimentModalType.REMOVE_PARTICIPANTS)}
          onRemoveParticipants={props.onRemoveParticipants}
        />
      </Container>
    </Loading>
  </>
}

export default ExperimentParticipants;