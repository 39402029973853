import moment, { isMoment, Moment } from 'moment';
import { CascaderOptionType } from 'antd/lib/cascader';
import { isArrayOfFilterOptionTypes, NodeTypes, InputTypes, FilterOptionType } from './types';

export const getTextualRepresentationOfValue = (value: string | boolean | moment.Moment | undefined): string => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'string') {
    return value;
  }
  if (moment.isMoment(value)) {
    return value.format('DD/MM/YYYY');
  }
  return 'Unknown';
}

export const defaultFilterFunction = (...arg: any) => true;

export const createFilterFunction = (cascaderOptions: CascaderOptionType[], value: string | boolean | moment.Moment): (...arg: any) => boolean => {
  if (isArrayOfFilterOptionTypes(cascaderOptions) && cascaderOptions.length > 0) {
    const currentOption = cascaderOptions[0];
    if (currentOption.node === NodeTypes.LEAF) {
      return createLeafFilterFunction(currentOption.input!, currentOption.value!, value);
    } 
    if (currentOption.node === NodeTypes.ELEMENT) {
      return createElementFilterFunction(cascaderOptions.slice(1), currentOption.value!, value);
    }
    if (currentOption.node === NodeTypes.ARRAY) {
      return createArrayFilterFunction(cascaderOptions.slice(1), currentOption.value!, value);
    }
  }
  return defaultFilterFunction;
}

const createLeafFilterFunction = (inputType: InputTypes, key: string, value: string | boolean | moment.Moment) => {
  if (inputType === InputTypes.STRING && typeof value === 'string') {
    return stringFilterFunction(key, value);
  }
  if (inputType === InputTypes.BOOLEAN && typeof value === 'boolean') {
    return booleanFilterFunction(key, value);
  }
  if (inputType === InputTypes.DATE && isMoment(value)) {
    return dateFilterFunction(key, value);
  }  
  return defaultFilterFunction;
}

const createElementFilterFunction = (filterOptions: FilterOptionType[], key: string, value: string | boolean | moment.Moment) => {
  return (object: any) => {
    if (object && object[key]) {
      return createFilterFunction(filterOptions, value)(object[key]);
    }
    if (object && object[key] === 'undefined' && typeof value === 'boolean') {
      return value === false;
    }
    return false;
  }
}

const createArrayFilterFunction = (filterOptions: FilterOptionType[], key: string, value: string | boolean | moment.Moment) => {
  return (object: any) => {
    if (object && object[key] && object[key] instanceof Array && object[key].length > 0) {
      return object[key].filter((el: any) => createFilterFunction(filterOptions, value)(el)).length > 0;
    }
    if (object && (object[key] === 'undefined' || object[key].length === 0) && typeof value === 'boolean') {
      return value === false;
    }
    return false;
  }
}

const stringFilterFunction = (key: string, value: string) => {
  return (object: any) => {
    if (object && object[key] && typeof object[key] === 'string') {
      return object[key].toLowerCase().includes(value.toLocaleLowerCase());
    }
    return false;
  }
}

const booleanFilterFunction = (key: string, value: boolean) => {
  return (object: any) => {
    if (object && object[key] && typeof object[key] === 'boolean') {
      return object[key] === value;
    }
    if (object && object[key] === undefined) {
      return value === false;
    }
    return false;
  }
}

const dateFilterFunction = (key: string, value: Moment) => {
  return (object: any) => {
    if (object && object[key] && typeof object[key] === 'string') {
      const unixTime = Number(object[key]);
      const objDate = moment(unixTime);
      const month = objDate.month() === value.month();
      const day = objDate.day() === value.day();
      const year = objDate.year() === value.year();
      return month && day && year;
    }
    return false;
  }
}
