type Configuration = {
  url: string,
  localUrl: string
}

const stageConfiguration = {
  url: 'https://stage-hestia.herokuapp.com',
  localUrl: 'https://stage--hestiakul.netlify.app',
}

const productionConfiguration = {
  url: 'https://set-p-bwkhestia-hestia-app.cloud-ext.icts.kuleuven.be',
  localUrl: 'https://hestia.bwk.kuleuven.be/',
}

const developmentConfiguration = {
  url: 'https://set-p-bwkhestia-hestia-app.cloud-ext.icts.kuleuven.be',
  localUrl: 'http://localhost:3000',
}

const getConfiguration = (): Configuration => {
  switch (process.env.NODE_ENV) {
    case 'production':
      if (window.location.hostname.includes('stage')) {
        return stageConfiguration;
      }
      return productionConfiguration;
    case 'development':
      return developmentConfiguration;
  }
  return productionConfiguration;
}

export default getConfiguration();