import { Modal } from 'antd'
import React, { FC } from 'react';
import { ModalProps } from 'antd/lib/modal';

const createButtonStyle = {
  color: '#FFFFFF',
  background: '#77B28C',
  borderRadius: '17.5px',
  borderColor: 'transparent',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  minWidth: '92px',
}

const cancelButtonStyle = {
  color: '#FFFFFF',
  background: '#DC493A',
  borderRadius: '17.5px',
  borderColor: 'transparent',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  minWidth: '92px',
}

const createButtonStyleDisable = {
  ...createButtonStyle,
  background: '#9CAFB7',
}

type HestiaModalProps = ModalProps & {
  disableOkButton?: boolean
}

const HestiaModal: FC<HestiaModalProps> = (props) => {

  const okProps = props.disableOkButton ? { style: createButtonStyleDisable, disabled: true } : { style: createButtonStyle }

  return <>
  <Modal
    {...props}
    okButtonProps={okProps}
    cancelButtonProps={{ style: cancelButtonStyle }}
  >
    {props.children}
  </Modal>
  </>
}

export default HestiaModal;