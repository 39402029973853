import React, { FC, useState, ChangeEvent } from 'react';
import { Modal, Form, FormItem } from '../../../components';
import { DataEntry } from '../../../types';
import { postDataEntry } from '../../../server';
import { uploadFile } from '../../../files/server';
import { reportError } from '../../../core/utils';

type Props = {
  expUuid: string,
  onCancel: () => void,
  onSubmit: (dataEntry: DataEntry) => void
}

export const DataUploadModal: FC<Props> = (props) => {
  const [file, setFile] = useState<File | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  const onOk = async () => {
    if (file) {
      try {
        setLoading(true);
        const dataEntry: DataEntry = await postDataEntry(props.expUuid, { name: file.name });
        await uploadFile(dataEntry.link, file);
        props.onSubmit(dataEntry);
      } catch (error) {
        await reportError(error);
        props.onCancel()
      }
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      setFile(event.currentTarget.files[0]);
    }
  }

  const disableSubmit = () => {
    return !file;
  }


  return <>
    <Modal
      onOk={onOk}
      title="Upload data file"
      visible={true}
      onCancel={props.onCancel}
      confirmLoading={loading}
      disablePrimaryButton={disableSubmit()}
      primaryButtonText="Upload"
      secondaryButtonText="Cancel"
    >
      <Form layout="vertical">
        <FormItem label="File" required>
          <input name="file" type="file" onChange={onChange} />
        </FormItem>
      </Form>
    </Modal>
  </>
};

