import { message } from 'antd';
import { Dispatch } from "redux";

import { reportError } from "../core/utils";
import { UserActionTypes } from "./action.types";
import { fetchUser, postDowngrade, postUpgrade } from "./server";
import { pending, fetchSuccess, downgradeSuccess, upgradeSuccess, failure } from "./action.creators";

export const getUser = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(UserActionTypes.FetchPending));
  try {
    const response = await fetchUser(uuid);
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(UserActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const upgradeUser = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(UserActionTypes.UpgradePending));
  try {
    const response = await postUpgrade(uuid);
    message.success(`User upgraded`);
    dispatch(upgradeSuccess(response))
  } catch (e) {
    dispatch(failure(UserActionTypes.UpgradeFailure))
    await reportError(e);
  }
};

export const downgradeUser = (uuid: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(pending(UserActionTypes.DowngradePending));
  try {
    const response = await postDowngrade(uuid);
    message.success(`User downgraded`);
    dispatch(downgradeSuccess(response))
  } catch (e) {
    dispatch(failure(UserActionTypes.DowngradeFailure))
    await reportError(e);
  }
};
