import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import CreateHeatingForm from './create.heating.form';
import { CreateHeatingSystemParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

type Props = {
  loading: boolean,
  showModal: boolean,
  heatingSystemTypes: Field[],
  heatingSystemEmissionSystems: Field[],
  heatingSystemProductionUnits: Field[],
  toggleModal: () => void,
  createHeatingSystem: (param: CreateHeatingSystemParam) => Promise<void>,
}

type Inputs = Partial<CreateHeatingSystemParam>

const CreateHeatingView: FC<Props> = (props) => {

  const [inputs, setInputs] = useState<Inputs>({});
  
  const handleSubmit = async () => {
    if (inputs.type) {
      await props.createHeatingSystem({
        ...inputs,
        type: inputs.type,
      })
      setInputs({});
    }
  }

  const handleInputChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({});
    props.toggleModal(); 
  }

  return <> 
  <Modal
    title='Create heating system'
    visible={props.showModal}
    onOk={handleSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Create"
    cancelText="Cancel"
    confirmLoading={props.loading}
    disableOkButton={!inputs.type}
    width='75%'
  >
    <CreateHeatingForm
      inputs={inputs}
      onChange={handleInputChange}
      heatingSystemTypes={props.heatingSystemTypes}
      heatingSystemEmissionSystems={props.heatingSystemEmissionSystems}
      heatingSystemProductionUnits={props.heatingSystemProductionUnits}
    />
  </Modal>
</>
}

export default CreateHeatingView;