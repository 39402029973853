import { api } from '../core/utils';
import { CreateFileParam, FileParent } from './types';

export const uploadFile = async (link: string, file: File) => {
  const response = await fetch(link, {
    method: 'PUT',
    body: file
  });
  if (!response.ok) {
    const error = await response.text();
    throw new Error(error);
  }
  return await response.text();
}

export const getFile = async (type: FileParent, parentUuid: string, fileUuid: string) => {
  switch (type) {
    case 'experiment':
      return api(`experiment/${parentUuid}/file/${fileUuid}`, 'GET', undefined, 'text');
    case 'building':
      return api(`building/${parentUuid}/file/${fileUuid}`, 'GET', undefined, 'text');
    case 'project':
      return api(`project/${parentUuid}/file/${fileUuid}`, 'GET', undefined, 'text');
  }
}

export const postFile = async (type: FileParent, parentUuid: string, params: CreateFileParam) => {
  switch (type) {
    case 'experiment':
      return api(`experiment/${parentUuid}/file`, 'POST', params);
    case 'building':
      return api(`building/${parentUuid}/file`, 'POST', params);
    case 'project':
      return api(`project/${parentUuid}/file`, 'POST', params);
  }
}

export const putFile = async (type: FileParent, parentUuid: string, fileUuid: string, params: Partial<CreateFileParam>) => {
  switch (type) {
    case 'experiment':
      return api(`experiment/${parentUuid}/file/${fileUuid}`, 'PUT', params);
    case 'building':
      return api(`building/${parentUuid}/file/${fileUuid}`, 'PUT', params);
    case 'project':
      return api(`project/${parentUuid}/file/${fileUuid}`, 'PUT', params);
  }
}
 
export const delFile = async (type: FileParent, parentUuid: string, fileUuid:string) => {
  switch (type) {
    case 'experiment':
      return api(`experiment/${parentUuid}/file/${fileUuid}`, 'DELETE');
    case 'building':
      return api(`building/${parentUuid}/file/${fileUuid}`, 'DELETE');
    case 'project':
      return api(`project/${parentUuid}/file/${fileUuid}`, 'DELETE');
  }
}