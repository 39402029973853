import React, { FC, useEffect, useContext, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'antd/dist/antd.css';

import useAuthentication from './hooks/authentication.hooks';
import { AppState } from './core/types';
import { NAME } from './profile/constants';
import { ThunkDispatch } from 'redux-thunk';
import { getMe } from './profile/operations';
import { connect } from 'react-redux';
import { User as UserModal } from './types';
import { UserContext } from './context';
import Loading from './components/loading.component';
import useConnected from './hooks/connected.hook';
import NotConnected from './routes/404';

const Fields = React.lazy(() => import('./routes/fields'));
const User = React.lazy(() => import('./user/components'));
const Users = React.lazy(() => import('./users/components'));
const Profile = React.lazy(() => import('./profile/components'));
const Sensors = React.lazy(() => import('./routes/sensors/sensors'));
const Sensor = React.lazy(() => import('./routes/sensors/sensor'));
const Projects = React.lazy(() => import('./routes/projects/projects'));
const Project = React.lazy(() => import('./project/components'));
const Building = React.lazy(() => import('./building/components'));
const Buildings = React.lazy(() => import('./buildings/components'));
const Experiment = React.lazy(() => import('./experiment/components'));
const Experiments = React.lazy(() => import('./routes/experiments/experiments'));

const mapStateToProps = (state: AppState) => {
  return {
    user: state[NAME].user
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    getMe: () => { dispatch(getMe()); }
  }
}

type Props = {
  user?: UserModal
  getMe: () => void
}

const App: FC<Props> = (props) => {

  const connected = useConnected();
  const authenticated = useAuthentication();
  const [, setUser ] = useContext(UserContext);

  useEffect(props.getMe, []);
  useEffect(() => {
    if (setUser) {
      setUser(props.user);
    }
  }, [props.user, setUser]);

  return <>
   {authenticated && !connected && <NotConnected />}
   {
    authenticated && connected && !!props.user && (
        <Router>
          <Suspense fallback={<Loading spinning={true}><div style={{width: '100%', height: '100%'}}></div></Loading>}>
            <Switch>
              <Route exact path="/fields" component={Fields} />
              <Route path="/user/:uuid" component={User} />
              <Route exact path="/users" component={Users} />
              <Route exact path="/profile" component={Profile}/>
              <Route exact path="/sensors" component={Sensors}/>
              <Route path="/sensor/:uuid" component={Sensor} />
              <Route exact path="/experiments" component={Experiments}/>
              <Route path="/experiment/:uuid" component={Experiment} />
              <Route exact path="/buildings" component={Buildings}/>
              <Route path="/building/:uuid" component={Building} />
              <Route path="/project/:uuid" component={Project} />
              <Route exact path="/*" component={Projects} />
            </Switch>
          </Suspense>
        </Router>
    )
   }
  </>

}

export default connect(mapStateToProps, mapDispatchToProps)(App);