import { ProfileActionTypes } from './action.types';
import { ProfileState, ProfileAction, ProfilePendingActions, ProfileFailureActions } from './types';
import { User } from '../types';

export const toggleModal = (): ProfileAction<ProfileActionTypes.ToggleModal> => ({
  type: ProfileActionTypes.ToggleModal,
  reducer(state): ProfileState {
    return {
      ...state,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: ProfilePendingActions): ProfileAction<ProfilePendingActions> => ({
  type,
  reducer(state): ProfileState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: ProfileFailureActions): ProfileAction<ProfileFailureActions> => ({
  type,
  reducer(state): ProfileState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (user: User): ProfileAction<ProfileActionTypes.FetchSuccess> => ({
  type: ProfileActionTypes.FetchSuccess,
  reducer(state): ProfileState {
    return {
      ...state,
      user,
      loading: false
    }
  }
});

export const editSuccess = (user: User): ProfileAction<ProfileActionTypes.EditSuccess> => ({
  type: ProfileActionTypes.EditSuccess,
  reducer(state): ProfileState {
    return {
      ...state,
      loading: false,
      showModal: false,
      user
    }
  }
});

export const passwordSuccess = (): ProfileAction<ProfileActionTypes.PasswordSuccess> => ({
  type: ProfileActionTypes.PasswordSuccess,
  reducer(state): ProfileState {
    return {
      ...state,
      loading: false,
    }
  }
});

export const downgradeSuccess = (user: User): ProfileAction<ProfileActionTypes.DowngradeSuccess> => ({
  type: ProfileActionTypes.DowngradeSuccess,
  reducer(state): ProfileState {
    return {
      ...state,
      loading: false,
      user
    }
  }
});