import React, { FC, useState, useEffect } from 'react';

import { OnChangeEvent } from '../../core/types';
import EditHeatingForm from './edit.heating.form';
import Modal from '../../components/modal.component';
import { CreateHeatingSystemParam } from '../types';
import { HeatingSystem, Field } from '../../types';

type Props = {
  loading: boolean,
  showModal: boolean,
  heatingSystem: HeatingSystem
  heatingSystemTypes: Field[],
  heatingSystemEmissionSystems: Field[],
  heatingSystemProductionUnits: Field[],
  toggleModal: () => void,
  editHeatingSystem: (param: Partial<CreateHeatingSystemParam>) => Promise<void>,
}

const EditHeatingView: FC<Props> = (props) => {

  const init: CreateHeatingSystemParam = {
    type: props.heatingSystem.type.uuid,
    storageTank: props.heatingSystem.storageTank,
    description: props.heatingSystem.description,
    domesticHotWater: props.heatingSystem.domesticHotWater,
    productionUnit: props.heatingSystem.productionUnit ? props.heatingSystem.productionUnit.uuid : undefined,
    emissionSystem: props.heatingSystem.emissionSystem ? props.heatingSystem.emissionSystem.uuid : undefined, 
  }

  const [inputs, setInputs] = useState<Partial<CreateHeatingSystemParam>>({ ...init });

  // eslint-disable-next-line
  useEffect(() => setInputs({...init}), [props.heatingSystem]);

  const onSubmit = async () => {
    if (!disableOkButton() && inputs.type) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.editHeatingSystem(params);
    }
  }

  const onChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({ ...init});
    props.toggleModal(); 
  }

  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
   return disable || !inputs.type;
  }

  return <> 
  <Modal
    title='Edit heating system'
    visible={props.showModal}
    onOk={onSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Edit"
    cancelText="Cancel"
    confirmLoading={props.loading}
    disableOkButton={disableOkButton()}
    width='75%'
  >
    <EditHeatingForm
      inputs={inputs}
      onChange={onChange}
      heatingSystemTypes={props.heatingSystemTypes}
      heatingSystemEmissionSystems={props.heatingSystemEmissionSystems}
      heatingSystemProductionUnits={props.heatingSystemProductionUnits}
    />
  </Modal>
</>
}

export default EditHeatingView;