import { api } from "../core/utils";
import { CreateExperimentParam, CreateDataEntry } from "../types";

export const fetchExperiments = async () => {
  return api('experiment', 'GET');
}

export const postExperiment = async (params: CreateExperimentParam) => {
  return api('experiment', 'POST', params);
}

export const fetchDataEntries = async (uuid: string) => {
  return api(`experiment/${uuid}/data`, 'GET');
}

export const delDataEntry = async (expUuid: string, uuid: string) => {
  return api(`experiment/${expUuid}/data/${uuid}`, 'DELETE');
}

export const postDataEntry = async (uuid: string, params: CreateDataEntry) => {
  return api(`experiment/${uuid}/data`, 'POST', params);
}

export const downloadData = async (expUuid: string, uuid: string, name: string) => {
  const downloadLink = await api(`experiment/${expUuid}/data/${uuid}`, 'GET', undefined, 'text');
  const response = await fetch(downloadLink);
  const fileBlob = await response.blob();
  const url = URL.createObjectURL(fileBlob);
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  link.parentNode?.removeChild(link);
}

