
export class ConnectionChangeEvent extends Event {
  public static ConnectionEventType = "connectionchangeevent";

  constructor(public readonly connected: boolean) {
    super(ConnectionChangeEvent.ConnectionEventType);
  }

}

export const isConnectionChangeEvent = (event: Event): event is ConnectionChangeEvent => {
  if (event.type === ConnectionChangeEvent.ConnectionEventType) {
    return true;
  }
  return false;
}