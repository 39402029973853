import React, { FC, useEffect } from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Modal as AntModal, Icon } from 'antd';

import { Button } from './button';

type Props = ModalProps & {
  onOk: () => {},
  primaryButtonText: string,
  secondaryButtonText: string,
  disablePrimaryButton: boolean,
}

export const Modal: FC<Props> = (props) => { 
  const disablePrimaryButton = props.disablePrimaryButton;
  const onOk = props.onOk;
  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && !disablePrimaryButton && !!onOk) {
        event.preventDefault();
        onOk();
      }
    };
    window.addEventListener("keydown", onKeyPress);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
    };
  }, [disablePrimaryButton, onOk]);

  return <>
  <AntModal
    {...props}
    footer={
      <div>
        <Button delete={1} onClick={props.onCancel}>
          {props.secondaryButtonText}
        </Button>
        <Button onKeyPress={() => alert("test")} create={1} onClick={props.onOk} disabled={props.disablePrimaryButton}>
          {props.confirmLoading && <Icon type="loading" />}{props.primaryButtonText}
        </Button>
      </div>
    }
  >
    {props.children}
  </AntModal>
  </>
}
