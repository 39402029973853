import { ModalType, FileAction, FilesState, FilePendingActions, FileFailureActions, File } from "./types";
import { FileActionTypes } from "./action.types";


export const toggleModal = (modalType: ModalType): FileAction<FileActionTypes.ToggleModal> => ({
  type: FileActionTypes.ToggleModal,
  reducer(state): FilesState {
    return {
      ...state,
      modalType,
      showModal: !state.showModal,
    };
  }
});

export const pending = (type: FilePendingActions): FileAction<FilePendingActions> => ({
  type,
  reducer(state): FilesState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: FileFailureActions): FileAction<FileFailureActions> => ({
  type,
  reducer(state): FilesState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (files: File[]): FileAction<FileActionTypes.FetchSuccess> => ({
  type: FileActionTypes.FetchSuccess,
  reducer(state): FilesState {
    return {
      ...state,
      files,
      loading: false,
    }
  }
});

export const createSuccess = (file: File): FileAction<FileActionTypes.CreateSuccess> => ({
  type: FileActionTypes.CreateSuccess,
  reducer(state): FilesState {
    return {
      ...state,
      loading: false,
      showModal: false,
      files: [file].concat(state.files)
    }
  }
});

export const editSuccess = (file: File): FileAction<FileActionTypes.EditSuccess> => ({
  type: FileActionTypes.EditSuccess,
  reducer(state): FilesState {
    return {
      ...state,
      loading: false,
      showModal: false,
      files: [file].concat(state.files.filter(f => f.uuid !== file.uuid))
    }
  }
});

export const deleteSuccess = (fileUuid: string): FileAction<FileActionTypes.DeleteSuccess> => ({
  type: FileActionTypes.DeleteSuccess,
  reducer(state): FilesState {
    return {
      ...state,
      loading: false,
      files: state.files.filter(f => f.uuid !== fileUuid)
    }
  }
});