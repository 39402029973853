import {  ModalType, FilesState, FileActions } from "./types";
import { FileActionTypes } from "./action.types";

const initialState: FilesState = {
  loading: false,
  showModal: false,
  files: [],
  modalType: ModalType.CREATE,
}

export default (state: FilesState = initialState, action: FileActions): FilesState => {
  if (Object.values(FileActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
