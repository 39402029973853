import { ExperimentActionTypes } from './action.types';
import { ExperimentAction, ExperimentState, ExperimentPendingActions, ExperimentFailureActions, ExperimentModalType } from './types';
import { Experiment } from '../types';

export const toggleModal = (modalType: ExperimentModalType): ExperimentAction<ExperimentActionTypes.ToggleModal> => ({
  type: ExperimentActionTypes.ToggleModal,
  reducer(state): ExperimentState {
    return {
      ...state,
      modalType,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: ExperimentPendingActions): ExperimentAction<ExperimentPendingActions> => ({
  type,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: ExperimentFailureActions): ExperimentAction<ExperimentFailureActions> => ({
  type,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.FetchSuccess> => ({
  type: ExperimentActionTypes.FetchSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      experiment,
      loading: false
    }
  }
});

export const editSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.EditSuccess> => ({
  type: ExperimentActionTypes.EditSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
});

export const deleteSuccess = (): ExperimentAction<ExperimentActionTypes.DeleteSuccess> => ({
  type: ExperimentActionTypes.DeleteSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      experiment: undefined
    }
  }
});

export const addBuildingSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.AddBuildingSuccess> => ({
  type: ExperimentActionTypes.AddBuildingSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const removeBuildingSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.RemoveBuildingSuccess> => ({
  type: ExperimentActionTypes.RemoveBuildingSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const addParticipantSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.AddParticipantSuccess> => ({
  type: ExperimentActionTypes.AddParticipantSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const removeParticipantSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.RemoveParticipantSuccess> => ({
  type: ExperimentActionTypes.RemoveParticipantSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const addSensorSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.AddSensorSuccess> => ({
  type: ExperimentActionTypes.AddSensorSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const updateSensorSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.UpdateSensorSuccess> => ({
  type: ExperimentActionTypes.UpdateSensorSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const removeSensorSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.RemoveSensorSuccess> => ({
  type: ExperimentActionTypes.RemoveSensorSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const uploadDataSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.UploadDataSuccess> => ({
  type: ExperimentActionTypes.UploadDataSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})

export const downloadDataSuccess = (): ExperimentAction<ExperimentActionTypes.FetchDataSuccess> => ({
  type: ExperimentActionTypes.FetchDataSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
})

export const removeDataSuccess = (experiment: Experiment): ExperimentAction<ExperimentActionTypes.RemoveDataSuccess> => ({
  type: ExperimentActionTypes.RemoveDataSuccess,
  reducer(state): ExperimentState {
    return {
      ...state,
      loading: false,
      showModal: false,
      experiment
    }
  }
})
