import React, { FC } from "react";
import { Row, Col, Typography } from "antd";

import { sortTime } from '../../core/utils';
import Loading from "../../components/loading.component";
import BuildingCom from '../../components/building.component';
import { Container, Table, TableHeader, TableHeaderItem } from "../../components";
import { Building } from "../../types";

const typeColumns = { span: 6 };
const statecolumns = { span: 6 };
const ownerColumns = { span: 4 };
const addressColumns = { span: 8 };

const { Title } = Typography;

type Props = {
  loading: boolean,
  buildings: Building[]
}

const ProjectBuilding: FC<Props> = (props) => {
  return <>
  <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Project building title } */}
        <Row>
          <Col>
            <Title level={4}>Buildings</Title>
          </Col>
        </Row>

        {/* { Building table } */}
        <Table>
          <TableHeader>
            <TableHeaderItem {...addressColumns}>
              ADDRESS
            </TableHeaderItem>
            <TableHeaderItem {...typeColumns}>
              TYPE
            </TableHeaderItem>
            <TableHeaderItem {...statecolumns}>
              STATE
            </TableHeaderItem>
            <TableHeaderItem {...ownerColumns}>
              OWNER
            </TableHeaderItem>
          </TableHeader>
          <div>
          { props.buildings.sort((a,b) =>sortTime(Number(a.updatedAt), Number(b.updatedAt))).map(building => (
              <BuildingCom key={building.uuid} id={building.uuid}>
                <Col {...addressColumns}>
                  {building.street} {building.number}, {building.postal} {building.city}, {building.country}
                </Col>
                <Col {...typeColumns}>
                  {building.type.name}
                </Col>
                <Col {...statecolumns}>
                  {building.state && building.state.name}
                </Col>
                <Col {...ownerColumns}>
                  {building.creator.firstName} {building.creator.lastName}
                </Col>
              </BuildingCom>
            ))
          }
          </div>
        </Table>
      </Container>
    </Loading>
  </>
}

export default ProjectBuilding;