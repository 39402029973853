import { api } from '../core/utils';
import { EditUserParams } from './types';

export const fetchMe = async () => {
  return api('me','GET');
}

export const putMe = async (params: EditUserParams) => {
  return api(`me`, 'PUT', params);
}

export const postPassword = async () => {
  return api('me/password', 'POST');
}

export const postDowngrade = async () => {
  return api('me/downgrade', 'POST');
}