import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import ProfileView from './profile.view';
import { EditUserParams } from '../types';
import { AppState } from '../../core/types';
import { getMe, editMe, resetPassword, downgradeMe } from '../operations';
import { toggleModal } from '../action.creators';

const mapStateToProps = (state: AppState) => {
  return {
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: () => {
      dispatch(toggleModal());
    },
    getMe: () => {
      dispatch(getMe());
    },
    resetPassword: async () => {
      await dispatch(resetPassword());
    },
    downgradeMe: async () => {
      await dispatch(downgradeMe());
    },
    editMe: async (param: EditUserParams) => {
      await dispatch(editMe(param));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);