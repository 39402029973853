import React, { FC, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

type Props = {
  y?: number;
  windowHeight: number;
}

const Container = styled.div`
  overflow: auto;
  height: ${(props: Props) => props.windowHeight - (props.y || 0) - 20}px;
`;

export const Scrollable: FC = (props) => {
  const [y, setY] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref !== null && ref.current !== null) {
      setY(ref.current.getBoundingClientRect().y);
    } 
  }, [ref])

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <>
    <Container ref={ref} y={y} windowHeight={windowHeight}>
      {props.children}
    </Container>
  </>
}