import thunk from 'redux-thunk';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';

import project from '../project';
import buildings from '../buildings';
import building from '../building';
import experiment from '../experiment';
import heatingsystem from '../heatingsystem';
import files from '../files';
import profile from '../profile';
import users from '../users';
import user from '../user';

declare global {
  interface Window { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any; }
}

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const rootReducer = combineReducers({
  [user.constants.NAME]: user.reducer,
  [users.constants.NAME]: users.reducer,
  [files.constants.NAME]: files.reducer,
  [profile.constants.NAME]: profile.reducer,
  [project.constants.NAME]: project.reducer,
  [building.constants.NAME]: building.reducer,
  [buildings.constants.NAME]: buildings.reducer,
  [experiment.constants.NAME]: experiment.reducer,
  [heatingsystem.constants.NAME]: heatingsystem.reducer,
});

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(thunk))
);

export default store;
