import { ActionBluePrint } from '../core/types';
import { FileActionTypes } from './action.types';
import { User, Field } from '../types';

export type File = {
  uuid: string, 
  name: string,
  type: Field,
  link: string,
  editor: User,
  creator: User,
  createdAt: string,
  updatedAt: string,
}

export type FileParent = 'experiment' | 'building' | 'project';

export type CreateFileParam = {
  type: string,
  name: string,
  [key: string]: string
}

export enum ModalType {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

export type FilesState = {
  loading: boolean,
  showModal: boolean,
  modalType: ModalType,
  files: File[]
}

export type FileAction<Action> = ActionBluePrint<Action, FilesState>;
export type FileActions = ActionBluePrint<FileActionTypes, FilesState>;
export type FileFailureActions =
  | FileActionTypes.FetchFailure
  | FileActionTypes.CreateFailure
  | FileActionTypes.DeleteFailure
  | FileActionTypes.EditFailure;
export type FilePendingActions =
  | FileActionTypes.FetchPending
  | FileActionTypes.CreatePending
  | FileActionTypes.DeletePending
  | FileActionTypes.EditPending;