import React, { FC, FormEvent } from 'react';
import { Row, Form, Input, Select } from 'antd';

import Col from '../../components/form.col.component';
import Item from '../../components/form.item.component';
import { OnChangeEvent } from '../../core/types';
import { CreateFileParam } from '../types';
import { Field } from '../../types';

const { Option } = Select;

type Inputs = Partial<CreateFileParam> & {
  file?: File
};

type Props = {
  inputs: Inputs,
  fileTypes: Field[]
  onChange: (event: OnChangeEvent) => void,
}

const CreateHeatingForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value });
  }

  return <>
  <Form layout="vertical">
    <Row>
      <Col>
        <Item label="Name" required>
          <Input name="name" value={props.inputs.name} onChange={onChange}/>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col>
        <Item label="Type" required>
          <Select
            showSearch
            value={props.inputs.type || undefined}
            placeholder="Select a type"
            onSelect={(value: string) => { props.onChange({ name: 'type', value }); }}
          >
            { props.fileTypes.map(fileType => 
              <Option key={fileType.uuid} value={fileType.uuid}>{fileType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col>
        <Item label="File" required>
          <input name="file" type="file" onChange={event => {
            if (event.currentTarget.files && event.currentTarget.files[0]) {
              props.onChange({ name: 'file', value: event.currentTarget.files[0] })
            }
          }} />
        </Item>
      </Col>
    </Row>
  </Form>
</>
} 

export default CreateHeatingForm;