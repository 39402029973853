import { message } from 'antd';
import { Dispatch } from "redux";
import { Project } from "../types";
import { ProjectActionTypes } from "./action.types";
import { fetchProject, putProject, delProject, addExperiment, removeExperiment } from "./server";
import { pending, fetchSuccess, editSuccess, deleteSuccess, failure, addExperimentSuccess, removeExperimentSuccess } from "./action.creators";
import { reportError } from "../core/utils";
import { Experiment } from '../types';

export const getProject = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(ProjectActionTypes.FetchPending));
  try {
    const response = await fetchProject(uuid);
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(ProjectActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const addExperiments = (uuid: string, experiments: Experiment[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ProjectActionTypes.AddExperimentPending));
  try {
    const asyncFunctions = experiments.map(exp => async () => addExperiment(uuid, exp.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(addExperimentSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ProjectActionTypes.AddExperimentFailure));
    await reportError(e);
  }
};

export const removeExperiments = (uuid: string, experiments: Experiment[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ProjectActionTypes.RemoveExperimentPending));
  try {
    const asyncFunctions = experiments.map(exp => async () => removeExperiment(uuid, exp.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(removeExperimentSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ProjectActionTypes.RemoveExperimentFailure));
    await reportError(e);
  }
};

export const editProject = (uuid: string, param: Pick<Project,'name' | 'keywords' | 'description'>) => async (dispatch: Dispatch) => {
  dispatch(pending(ProjectActionTypes.EditPending));
  try {
    const response = await putProject(uuid, param);
    message.success(`Project edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(ProjectActionTypes.EditFailure))
    await reportError(e);
  }
};

export const deleteProject = (uuid: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(pending(ProjectActionTypes.DeletePending));
  try {
    await delProject(uuid);
    message.success(`Project deleted`);
    dispatch(deleteSuccess())
  } catch (e) {
    dispatch(failure(ProjectActionTypes.DeleteFailure))
    await reportError(e);
  }
};
