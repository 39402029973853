import { api } from "../core/utils";
import { Field } from "../types";

export const fetchFieldType = (path: string): Promise<Field[]> => {
    return api(path, 'GET');
}

export const postFieldType = <T extends Field>(path: string, params: Omit<T, 'uuid'>): Promise<T> => {
    return api(path, 'POST', params);
};

export const putFieldType = <T extends Field>(path: string, uuid: string, param: Pick<T, 'name'>): Promise<T> => {
    return api(`${path}/${uuid}`, 'PUT', param);
};

export const delFieldType = (path: string, uuid: string): Promise<void> => {
    return api(`${path}/${uuid}`, 'DELETE');
};

export const buildingStatesPath = 'building/state';
export const buildingTypePath = 'building/type';
export const experimentHeatingPath = 'experiment/heating';
export const experimentTypePath = 'experiment/type';
export const fileTypePath = 'file/type';
export const heatingEmissionPath = 'heating/emission';
export const heatingProductionPath = 'heating/production';
export const heatingTypePath = 'heating/type';
export const sensorLocationPath = 'sensor/location';
export const sensorPositionPath = 'sensor/position';
