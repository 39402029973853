import React, { FC, useState } from "react";
import { Row, Col, Typography, Icon } from "antd";

import { sortTime } from '../../core/utils';
import { ExperimentModalType, AddSensorExperimentParam, UpdateSensorExperimentParam } from '../types';
import Loading from "../../components/loading.component";
import FloatRight from '../../components/float.right.component';
import ExperimentAddSensor from './experiment.add.sensor.view';
import ExperimentRemoveSensor from './experiment.remove.sensor.view';
import ExperimentEditSensor from './experiment.edit.sensor.view';
import { Container, Button, Table, TableHeader, TableHeaderItem, TableItem, IconButton } from "../../components";
import { Field, SensorExperiment } from "../../types";

const { Title } = Typography;

const titleColumns = {
  xs: { span: 12 },
  sm: { span: 8 },
  md: { span: 6 },
  lg: { span: 4 },
  xl: { span: 3 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 16 },
  md: { span: 18 },
  lg: { span: 20 },
  xl: { span: 21 },
}

const columns = {
  xs: { span: 5 },
  sm: { span: 5 },
  md: { span: 5 },
  lg: { span: 5 },
  xl: { span: 5 }
}

const actionColumns = {
  xs: { span: 4 },
  sm: { span: 4 },
  md: { span: 4 },
  lg: { span: 4 },
  xl: { span: 4 }
}

type Props = {
  loading: boolean,
  showModal: boolean,
  locations: Field[],
  positions: Field[],
  modalType: ExperimentModalType,
  experimentSensors: SensorExperiment[],
  toggleModal: (modalType: ExperimentModalType) => void,
  onAddSensors: (sensors: AddSensorExperimentParam[]) => Promise<void>,
  onEditSensor: (sensorUuid: string, params: UpdateSensorExperimentParam) => Promise<void>,
  onRemoveSensors: (sensors: SensorExperiment[]) => Promise<void>,
}

const ExperimentSensors: FC<Props> = (props) => {

  const [selectedSensor, selectSensor] = useState<SensorExperiment>();

  const onEdit = (sensor: SensorExperiment) => {
    selectSensor(sensor);
    props.toggleModal(ExperimentModalType.UPDATE_SENSOR);
  }

  return <>
  <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Experiment sensors title } */}
        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Sensors</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              <Button create={1} onClick={() => props.toggleModal(ExperimentModalType.ADD_SENSOR)}>
                add sensors
              </Button>
              { props.experimentSensors.length > 0 && 
                <Button style={{ marginLeft: '10px' }} delete={1} onClick={() => props.toggleModal(ExperimentModalType.REMOVE_SENSOR)}>
                  remove sensors
                </Button>
              }
            </FloatRight>
          </Col>
        </Row>

        {/* { Sensor table } */}
        { props.experimentSensors && props.experimentSensors.length > 0 &&
          <Table>
            <TableHeader>
              <TableHeaderItem {...columns}>
                NAME
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                LOCATION
              </TableHeaderItem>
              <TableHeaderItem {...columns}>
                POSITION
              </TableHeaderItem>
              <TableHeaderItem {...actionColumns}>
                ACTIONS
              </TableHeaderItem>
            </TableHeader>
            <div>
            { props.experimentSensors.sort((a,b) =>sortTime(Number(a.updatedAt), Number(b.updatedAt))).map(expSensor => (
                <TableItem clickable={0} key={expSensor.uuid}>
                  <Col {...columns}>
                    {expSensor.sensor.name}
                  </Col>
                  <Col {...columns}>
                    {expSensor.sensor.type}
                  </Col>
                  <Col {...columns}>
                    {expSensor.location && expSensor.location.name}
                  </Col>
                  <Col {...columns}>
                    {expSensor.position && expSensor.position.name}
                  </Col>
                  <Col {...actionColumns}>
                    <IconButton style={{ marginRight: "10px"}} onClick={() => onEdit(expSensor)}>
                      <Icon type="edit" /> edit
                    </IconButton>
                  </Col>
                </TableItem>
              ))
            }
            </div>
          </Table>
        }

        {/* { Add Sensors } */}
        <ExperimentAddSensor
          loading={props.loading}
          experimentSensors={props.experimentSensors}
          visible={props.showModal && props.modalType === ExperimentModalType.ADD_SENSOR}
          onCancel={() => props.toggleModal(ExperimentModalType.ADD_SENSOR)}
          onAddSensors={props.onAddSensors}
        />

        {/* { Remove sensors } */}
        <ExperimentRemoveSensor
          loading={props.loading}
          experimentSensors={props.experimentSensors}
          visible={props.showModal && props.modalType === ExperimentModalType.REMOVE_SENSOR}
          onCancel={() => props.toggleModal(ExperimentModalType.REMOVE_SENSOR)}
          onRemoveSensors={props.onRemoveSensors}
        />

        {selectedSensor && 
         <ExperimentEditSensor
          loading={props.loading}
          locations={props.locations}
          positions={props.positions}
          experimentSensor={selectedSensor}
          visible={props.showModal && props.modalType === ExperimentModalType.UPDATE_SENSOR}
          onCancel={() => props.toggleModal(ExperimentModalType.UPDATE_SENSOR)}
          onEditSensor={(params: UpdateSensorExperimentParam) => props.onEditSensor(selectedSensor.uuid, params)}
         />
        }

      </Container>
    </Loading>
  </>
}

export default ExperimentSensors;