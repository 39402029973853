import React, { FC, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { Layout, Typography, Row, Col, Popconfirm } from 'antd';

import { Iuuid, UserRole } from '../../types';
import Text from '../../components/text.component';
import Menu from '../../core/components/menu.component';
import Label from '../../components/label.component';
import { UserState } from '../types';
import Loading from '../../components/loading.component';
import FloatRight from '../../components/float.right.component';
import { Container, Button } from '../../components';

const { Content } = Layout;
const { Title } = Typography;

const labelColumns = {
  xs: { span: 12},
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 12},
  xl: { span: 12},
}
const titleColumns = {
  xs: { span: 8},
  sm: { span: 11},
  md: { span: 15},
  lg: { span: 17},
  xl: { span: 19},
}
const buttonColumns = {
  xs: { span: 16},
  sm: { span: 13},
  md: { span: 19},
  lg: { span: 7},
  xl: { span: 5},
}


type Props = RouteComponentProps<Iuuid> & UserState & {
  userRole?: UserRole,
  getUser: (uuid: string) => void
  downgradeUser: (uuid: string) => Promise<void>
  upgradeUser: (uuid: string) => Promise<void>
}

const UserView: FC<Props> = ({
  loading,
  user,
  userRole,
  match: {
    params: {
      uuid
    }
  },
  getUser,
  downgradeUser,
  upgradeUser
}) => {

  useEffect(() => getUser(uuid), [getUser, uuid]);

  return <>
    <Layout>
      <Menu selected="user" />
      <Content style={{ padding: '20px 50px' }}>
        <Loading spinning={loading}>
          <Row>
            <Col>
              <Title level={3}>
                {user && `${user.firstName} ${user.lastName}`}
              </Title>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col {...titleColumns}>
                <Title level={4}>
                  General information
                </Title>
              </Col>
              <Col {...buttonColumns}>
                { userRole && userRole === UserRole.admin &&
                  <FloatRight>
                    { user && user.role !== UserRole.student &&
                      <Popconfirm
                        title="Are you sure you want to downgrade this user?"
                        onConfirm={async () => await downgradeUser(uuid)}
                        okText="Yes"
                        cancelText="No"
                        placement="left"
                      >
                        <Button>
                          downgrade user
                        </Button>
                      </Popconfirm>
                    }
                    { user && user.role !== UserRole.admin &&
                      <Popconfirm
                        title="Are you sure you want to upgrade this user?"
                        onConfirm={async () => await upgradeUser(uuid)}
                        okText="Yes"
                        cancelText="No"
                        placement="left"
                      >
                        <Button style={{marginLeft: '10px'}}>
                          upgrade user
                        </Button>
                      </Popconfirm>
                    }
                </FloatRight>
                }
              </Col>
            </Row>
            <Row>
              <Col {...labelColumns}>
                <Row>
                  <Label>
                    ROLE
                  </Label>
                  <Text>
                    {user && user.role}
                  </Text>
                </Row>
              </Col>
              <Col {...labelColumns}>
                <Row>
                  <Label>
                    EMAIL
                  </Label>
                  <Text>
                    {user && user.email}
                  </Text>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col {...labelColumns}>
                <Row>
                  <Label>
                    KULID
                  </Label>
                  <Text>
                    {user && user.kulid}
                  </Text>
                </Row>
              </Col>
              <Col {...labelColumns}>
                <Row>
                  <Label>
                    TELEPHONE
                  </Label>
                  <Text>
                    {user && user.telephone}
                  </Text>
                </Row>
              </Col>
            </Row>
          </Container>
        </Loading>
      </Content>
    </Layout>
  </>
  
} 

export default UserView;