import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import ExperimentView from './experiment.view';
import { AppState } from '../../core/types';
import { toggleModal } from '../action.creators';
import { getExperiment, editExperiment, deleteExperiment, deleteData, addBuildings, removeBuildings, addParticipants, removeParticipants, addSensors, removeSensors, editSensor, uploadData, downloadData } from '../operations';
import { getBuildings } from '../../buildings/operations';
import { ExperimentModalType, AddSensorExperimentParam, UpdateSensorExperimentParam, SelectedColumnSensor } from '../types';
import { getUsers } from '../../users/operations';
import { User, SensorExperiment, Building, CreateExperimentParam } from '../../types';

const mapStateToProps = (state: AppState) => {
  return { 
    ...state[NAME],
    users: state.users.users,
    buildings: state.buildings.buildings
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: (modalType: ExperimentModalType) => {
      dispatch(toggleModal(modalType));
    },
    getExperiment: (uuid: string) => {
      dispatch(getExperiment(uuid));
    },
    getBuildings: () => {
      dispatch(getBuildings());
    },
    getUsers: () => {
      dispatch(getUsers());
    },
    updateExperiment: async (uuid: string, param: Partial<CreateExperimentParam>) => {
      await dispatch(editExperiment(uuid, param));
    },
    deleteExperiment: async (uuid: string) => {
      await dispatch(deleteExperiment(uuid));
    },
    onAddBuildings: async (uuid: string, buildings: Building[]) => {
      await dispatch(addBuildings(uuid, buildings));
    },
    onRemoveBuildings: async (uuid: string, buildings: Building[]) => {
      await dispatch(removeBuildings(uuid, buildings));
    },
    onAddParticipants: async (uuid: string, users: User[]) => {
      await dispatch(addParticipants(uuid, users));
    },
    onRemoveParticipants: async (uuid: string, users: User[]) => {
      await dispatch(removeParticipants(uuid, users));
    },
    onAddSensors: async (uuid: string, sensors: AddSensorExperimentParam[]) => {
      await dispatch(addSensors(uuid, sensors));
    },
    onEditSensor: async (uuid: string, sensorUuid: string, params: UpdateSensorExperimentParam) => {
      await dispatch(editSensor(uuid, sensorUuid, params));
    },
    onRemoveSensors: async (uuid: string, sensors: SensorExperiment[]) => {
      await dispatch(removeSensors(uuid, sensors))
    },
    uploadData: async (uuid: string, fileName: string, parsedData: any, selectedRow: number, selectedSensors: SelectedColumnSensor) => {
      await dispatch(uploadData(uuid, fileName, parsedData, selectedRow, selectedSensors))
    },
    deleteData: async (uuid: string, dataId: string) => {
      await dispatch(deleteData(uuid, dataId))
    },
    downloadData: async (uuid: string, sensors: SensorExperiment[]) => {
      await dispatch(downloadData(uuid, sensors));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperimentView);
