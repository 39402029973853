import { NodeTypes, InputTypes, FilterOptionType } from '../types';

const userOptions: FilterOptionType[] = [
  {
    value: 'firstName',
    label: 'First name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'lastName',
    label: 'Last name',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'role',
    label: 'Role',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING,
  },
  {
    value: 'email',
    label: 'Email',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'kulid',
    label: 'KULeuven ID',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  },
  {
    value: 'telephone',
    label: 'Telephone',
    node: NodeTypes.LEAF,
    input: InputTypes.STRING
  }
];

export default userOptions;