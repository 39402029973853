import { Col } from 'antd';
import styled from 'styled-components';


const Label = styled(Col)`
  font-size: 12px;
  font-weight: bold;
  color: #000000;
  line-height: 14px;
  margin-top: 20px;
`;

export default Label;