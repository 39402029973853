import React, { useState, FC } from 'react';

import Modal from '../../components/modal.component';
import CreateFileForm from './create.file.form';
import { CreateFileParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

type Props = {
  loading: boolean
  showModal: boolean
  fileTypes: Field[]
  toggleModal: () => void
  createFile: (param: CreateFileParam, file: File) => Promise<void>
}

type Inputs = Partial<CreateFileParam> & {
  file?: File 
}

const CreateFileView: FC<Props> = (props) => {

  const [inputs, setInputs] = useState<Inputs>({});

  const handleSubmit = async () => {
    if (inputs.name && inputs.type && inputs.file) {
      await props.createFile({
        name: inputs.name,
        type: inputs.type
      }, inputs.file);
      setInputs({});
    }
  }

  const handleInputChange = (event: OnChangeEvent) => {
    if (event.name === 'file') {
      setInputs({
        ...inputs,
        name: inputs.name || event.value.name,
        file: event.value
      });
    } else {
      setInputs({...inputs, [event.name]: event.value });
    }
  }

  const onCancel = () => {
    setInputs({});
    props.toggleModal();
  }

  return <> 
    <Modal
      title='Add a file'
      visible={props.showModal}
      onOk={handleSubmit}
      onCancel={onCancel}
      destroyOnClose={true}
      okText="Add"
      cancelText="Cancel"
      confirmLoading={props.loading}
      disableOkButton={!inputs.name || !inputs.type || !inputs.file}
    >
      <CreateFileForm 
        inputs={inputs}
        onChange={handleInputChange}
        fileTypes={props.fileTypes}
      />
    </Modal>
  </>

}

export default CreateFileView;