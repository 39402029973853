import React, { useState, useEffect } from 'react';

import authenticationController from '../controllers/authentication.controller';

const verifyAuthentication = async (set: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (window.location.search.includes("code=")) {
    await authenticationController.handleRedirect();
  }
  const isLoggedIn = await authenticationController.isLoggedIn();
  set(isLoggedIn);
  if (!isLoggedIn) {
    await authenticationController.login();
  }
}

const useAuthentication = (): boolean => {
  const [authenticated, setAuthenticated] = useState(false);
  useEffect(() => { verifyAuthentication(setAuthenticated); }, []);
  return authenticated;
};

export default useAuthentication;