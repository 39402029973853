import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { NAME } from '../constants';
import ProjectView from './project.view';
import { AppState } from '../../core/types';
import { Project, Experiment } from '../../types';
import { ProjectModalType } from '../types';
import { toggleModal } from '../action.creators';
import { getProject, editProject, deleteProject, addExperiments, removeExperiments } from '../operations';

const mapStateToProps = (state: AppState) => {
  return {
    ...state[NAME],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>) => {
  return {
    toggleModal: (modalType: ProjectModalType) => {
      dispatch(toggleModal(modalType));
    },
    getProject: (uuid: string) => {
      dispatch(getProject(uuid));
    },
    deleteProject: async (uuid: string) => {
      await dispatch(deleteProject(uuid));
    },
    editProject: async (uuid: string, param: Pick<Project,'name' | 'description' | 'keywords'>) => {
      await dispatch(editProject(uuid, param));
    },
    onAddExperiments: async (uuid: string, experiments: Experiment[]) => {
      await dispatch(addExperiments(uuid, experiments));
    },
    onRemoveExperiments: async (uuid: string, experiments: Experiment[]) => {
      await dispatch(removeExperiments(uuid, experiments));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);