import React, { FC } from 'react';
import { Col, Icon, Popconfirm } from 'antd';

import { File } from '../types';
import { TableItem, IconButton } from '../../components';

const nameTypeColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 7 },
  lg: { span: 8 },
  xl: { span: 8 },
}

const actionColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 10 },
  lg: { span: 8 },
  xl: { span: 8 },
}

type Props = {
  file: File,
  toggleModal: () => void,
  onDelete: (fileUuid: string) => Promise<void>,
  downloadFile: (file: File) => Promise<void>,
}

const FileComponent: FC<Props> = (props) => {
  return <>
    <TableItem clickable={0}>
      <Col {...nameTypeColumn}>
        {props.file.name}
      </Col>
      <Col {...nameTypeColumn}>
        {props.file.type.name}
      </Col>
      <Col {...actionColumn}>
        <IconButton style={{ marginRight: "10px"}} onClick={() => props.toggleModal()}>
          <Icon type="edit" /> edit
        </IconButton>
        <IconButton style={{ marginRight: "10px"}} onClick={async() => await props.downloadFile(props.file)}>
          <Icon type="download" /> download
        </IconButton>
        <Popconfirm
          title="Are you sure you want to delte this file?"
          onConfirm={async () => await props.onDelete(props.file.uuid)}
          okText="Yes"
          cancelText="No"
        >
          <IconButton>
            <Icon type="delete" /> delete
          </IconButton>
        </Popconfirm>
      </Col>
    </TableItem>
  </>
}

export default FileComponent;