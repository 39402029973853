import Papa from 'papaparse';

import { Data } from "./types";
import { SensorExperiment } from '../types';

export const constructCSV = (data: Array<Data[]>, sensors: SensorExperiment[]) => {
  const csv: any = [];
  csv.push(createPropertyRow('name', sensors))
  csv.push(createPropertyRow('type', sensors))
  csv.push(createPropertyRow('unit', sensors))
  csv.push(createPropertyRow('device', sensors))
  csv.push(createPropertyRow('location', sensors))
  csv.push(createPropertyRow('position', sensors))
  return Papa.unparse(csv.concat(createDataRow(data)))
}

const createPropertyRow = (property: string, sensors: SensorExperiment[]) => {
  return sensors.reduce((acc: any, sensor) => {
    if (property === 'location' || property === 'position') {
      return acc.concat([property, ((sensor[property] && sensor[property]!.name) || '')])
    }
    return acc.concat([property, sensor[property] || sensor.sensor[property] || ''])
  }, []);
}

const createDataRow = (data: Array<Data[]>) => {
  const longestData = largestData(data);
  const result: any = [[]];
  data.forEach(() => {
    result[0].push('timestamp', 'value');
  });
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < longestData; index++) {
    const temp: any = [];
    data.forEach(arr => {
      temp.push((!!arr[index] && arr[index].time) || '');
      temp.push((!!arr[index] && arr[index].value) || '');
    });
    result.push(temp);
  }
  return result;
}

const largestData = (data: Array<Data[]>) => {
  let largest = -1;
    data.forEach(arr => {
      if (arr.length > largest) {
        largest = arr.length;
      }
    });
    return largest;
}

export const downloadCSV = (file: string) => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(file)}`;
  hiddenElement.target = '_blank';
  hiddenElement.download = 'data.csv';
  hiddenElement.click();
}