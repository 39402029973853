import { Project } from '../types';
import { api } from '../core/utils';

export const fetchProject = async (uuid:string) => {
  return api(`project/${uuid}`, 'GET');
}

export const putProject = async (uuid:string, params: Pick<Project, 'name' | 'keywords' | 'description'>) => {
  return api(`project/${uuid}`, 'PUT', params);
}
 
export const delProject = async (uuid:string) => {
  return api(`project/${uuid}`, 'DELETE');
}

export const addExperiment = async (uuid: string, expUuid: string): Promise<Project> => {
  return api(`project/${uuid}/experiment/${expUuid}`, 'POST')
}

export const removeExperiment = async (uuid: string, expUuid: string): Promise<Project> => {
  return api(`project/${uuid}/experiment/${expUuid}`, 'DELETE')
}