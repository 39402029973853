import moment from "moment";
import { CascaderOptionType } from "antd/lib/cascader";


export enum InputTypes {
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export enum NodeTypes {
  LEAF = 'leaf',
  ARRAY = 'ARRAY',
  ELEMENT = 'ELEMENT',
}

export type Filter = {
  id: string,
  label: string,
  value: string | boolean | moment.Moment,
  exec: any
}

export type FilterOptionType = CascaderOptionType & {
  node: NodeTypes,
  input?: InputTypes,
}

export const isFilterOptionType = (arg: any): arg is FilterOptionType => {
  return arg.node !== undefined;
}

export const isArrayOfFilterOptionTypes = (arg: any): arg is FilterOptionType[] => {
  if (arg instanceof Array) {
    return arg.reduce((acc, curr) => acc && isFilterOptionType(curr), true);
  }
  return false;
}