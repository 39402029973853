import { ProfileState, ProfileActions } from "./types";
import { ProfileActionTypes } from "./action.types";

const initialState: ProfileState = {
  loading: false,
  showModal: false,
  user: undefined,
}

export default (state: ProfileState = initialState, action: ProfileActions): ProfileState => {
  if (Object.values(ProfileActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
