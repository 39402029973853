import { Button as AntButton } from 'antd';
import styled from 'styled-components'

type ButtonProps = {
  create?: 0 | 1;
  delete?: 0 | 1;
  cancel?: 0 | 1;
}

export const Button = styled(AntButton)`
min-width: 92px;
color: #FFFFFF;
background: ${(props: ButtonProps) => {
  return (props.create && '#77B28C') || (props.delete && '#DC493A') || (props.cancel && '#495867') || '#267899'
}};
border-radius: 17.5px;
border-color: transparent;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
:hover {
  color: #FFFFFF;
  background: ${(props: ButtonProps) => {
    return (props.create && '#77B28C') || (props.delete && '#DC493A') || (props.cancel && '#495867') || '#267899'
  }};
  border-color: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
}
:focus {
  color: #FFFFFF;
  background: ${(props: ButtonProps) => {
    return (props.create && '#77B28C') || (props.delete && '#DC493A') || (props.cancel && '#495867') || '#267899'
  }};
  border-color: transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
}
`;