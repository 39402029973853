import { Spin } from 'antd';
import React, { FC } from 'react';

const loader =
  <div className="sk-folding-cube">
    <div className="sk-cube1 sk-cube" />
    <div className="sk-cube2 sk-cube" />
    <div className="sk-cube4 sk-cube" />
    <div className="sk-cube3 sk-cube" />
  </div>;

type Props = {
  loading?: boolean
}

export const Spinner: FC<Props> = (props) => {
  return <>
    <Spin spinning={props.loading ? true : false} indicator={loader} size="large">
      {props.children}
    </Spin>
  </>
}
