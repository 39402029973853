export enum BuildingActionTypes {
  ToggleModal = 'building/TOGGLE_MODAL',
  FetchPending = 'building/FETCH_PENDING',
  FetchSuccess = 'building/FETCH_SUCCESS',
  FetchFailure = 'building/FETCH_FAILURE',
  EditPending = 'building/EDIT_PENDING',
  EditSuccess = 'building/EDIT_SUCCESS',
  EditFailure = 'building/EDIT_FAILURE',
  DeletePending = 'building/DELETE_PENDING',
  DeleteSuccess = 'building/DELETE_SUCCESS',
  DeleteFailure = 'building/DELETE_FAILURE',
}