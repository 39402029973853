import { message } from 'antd';
import { Dispatch } from "redux";

import { reportError } from "../core/utils";
import { ExperimentActionTypes } from "./action.types";
import { fetchExperiment, putExperiment, postData, delData, delExperiment, addBuilding, removeBuilding, addParticipant, removeParticipant, addSensor, removeSensor, putSensor, getData, postDataEntry } from "./server";
import { pending, failure, fetchSuccess, editSuccess, deleteSuccess, addBuildingSuccess, removeBuildingSuccess, removeParticipantSuccess, addParticipantSuccess, removeSensorSuccess, addSensorSuccess, updateSensorSuccess, uploadDataSuccess, removeDataSuccess, downloadDataSuccess } from "./action.creators";
import { Building, SensorExperiment, CreateExperimentParam } from '../types';
import { AddSensorExperimentParam, UpdateSensorExperimentParam, SelectedColumnSensor, Data } from './types';
import { constructCSV, downloadCSV } from './util';
import { User } from '../types';
import moment from 'moment';

export const getExperiment = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.FetchPending));
  try {
    const response = await fetchExperiment(uuid);
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const addBuildings = (uuid: string, buildings: Building[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.AddBuildingPending));
  try {
    const asyncFunctions = buildings.map(building => async () => addBuilding(uuid, building.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(addBuildingSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.AddBuildingFailure));
    await reportError(e);
  }
};

export const removeBuildings = (uuid: string, buildings: Building[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.RemoveBuildingPending));
  try {
    const asyncFunctions = buildings.map(building => async () => removeBuilding(uuid, building.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(removeBuildingSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.RemoveBuildingFailure));
    await reportError(e);
  }
};

export const editExperiment = (uuid: string, param: Partial<CreateExperimentParam>) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.EditPending));
  try {
    const response = await putExperiment(uuid, param);
    message.success(`Experiment edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.EditFailure))
    await reportError(e);
  }
};

export const deleteExperiment = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.DeletePending));
  try {
    await delExperiment(uuid);
    message.success(`Experiment deleted`);
    dispatch(deleteSuccess())
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.DeleteFailure))
    await reportError(e);
  }
};

export const addParticipants = (uuid: string, users: User[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.AddParticipantPending));
  try {
    const asyncFunctions = users.map(user => async () => addParticipant(uuid, user.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(addParticipantSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.AddParticipantFailure));
    await reportError(e);
  }
};

export const removeParticipants = (uuid: string, users: User[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.RemoveParticipantPending));
  try {
    const asyncFunctions = users.map(user => async () => removeParticipant(uuid, user.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(removeParticipantSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.RemoveParticipantFailure));
    await reportError(e);
  }
};

export const addSensors = (uuid: string, sensors: AddSensorExperimentParam[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.AddSensorPending));
  try {
    const asyncFunctions = sensors.map(sensor => async () => addSensor(uuid, sensor));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(addSensorSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.AddSensorFailure));
    await reportError(e);
  }
};

export const removeSensors = (uuid: string, sensors: SensorExperiment[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.RemoveSensorPending));
  try {
    const asyncFunctions = sensors.map(sensor => async () => removeSensor(uuid, sensor.uuid));
    let result = undefined;
    for (const asFunc of asyncFunctions) {
      result = await asFunc();
    }
    if (result){
      dispatch(removeSensorSuccess(result));
    }
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.RemoveSensorFailure));
    await reportError(e);
  }
};

export const editSensor = (uuid: string, sensorUuid: string, param: UpdateSensorExperimentParam) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.UpdateSensorPending));
  try {
    const response = await putSensor(uuid, sensorUuid, param);
    message.success(`Sensor edited`);
    dispatch(updateSensorSuccess(response))
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.UpdateSensorFailure))
    await reportError(e);
  }
};

export const uploadData = (uuid: string, fileName: string, parsedData: any, selectedRow: number, selectedSensors: SelectedColumnSensor) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.UploadDataPending));
  try {
    const dataEntry = await postDataEntry(uuid, fileName);
    const selectedData = parsedData.slice(selectedRow);
    const dataWithSensor = Object.keys(selectedSensors).map((key: string) => {
      const data: Data[] = selectedData.map((dataRow: string) => ({
        value: dataRow[parseInt(key)],
        time: dataRow[0]
      }));
      return {
        sensor: selectedSensors[parseInt(key)],
        data: filterData(data)
      }
    });
    for (const data of dataWithSensor) {
      await postData(uuid, dataEntry.uuid, data);
    }
    const response = await fetchExperiment(uuid);
    message.success(`Data uploaded`);
    dispatch(uploadDataSuccess(response));
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.UploadDataFailure));
    await reportError(e);
  }
}

const filterData = (data: Data[]): Data[] => {
  return data
  .filter((point: any) => (!isNaN(point.value * 1) || point.value === 'NaN')) // remove non numeric values or NaN
  .filter((point: Data) => moment.parseZone(point.time).isValid()); // remove invalid timestamps
}

export const deleteData = (uuid: string, dataId: string) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.RemoveDataPending));
  try {
    await delData(uuid, dataId);
    const response = await fetchExperiment(uuid);
    message.success('Data removed');
    dispatch(removeDataSuccess(response));
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.RemoveDataFailure));
    await reportError(e);
  }
}

export const downloadData = (uuid: string, sensors: SensorExperiment[]) => async (dispatch: Dispatch) => {
  dispatch(pending(ExperimentActionTypes.FetchDataPending));
  try {
    const data: Array<Data[]> = [];

    for (let index = 0; index < sensors.length; index = index + 1) {
      const sensorData = await getData(uuid, sensors[index].uuid);
      data.push(sensorData);
    }
    const csvFile = constructCSV(data, sensors);
    downloadCSV(csvFile);
    dispatch(downloadDataSuccess());
  } catch (e) {
    dispatch(failure(ExperimentActionTypes.FetchDataFailure));
    await reportError(e);
  }
}
