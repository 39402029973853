import { api } from '../core/utils';

export const fetchUser = async (uuid: string) => {
  return api(`user/${uuid}`, 'GET');
}

export const postUpgrade = async (uuid: string) => {
  return api(`user/${uuid}/upgrade`, 'POST');
}
 
export const postDowngrade = async (uuid: string) => {
  return api(`user/${uuid}/downgrade`, 'POST');
}