import { Col } from "antd";
import moment from 'moment';
import React, { FC, useState } from 'react';

import { Experiment } from "../../types";
import Modal from '../../components/modal.component';
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";

const nameColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 7 },
}
const typeColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 6 },
}
const startDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const endDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const daysColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 2 },
  xl: { span: 2 },
}
const ownerColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 4 },
  xl: { span: 3 },
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  projectExperiments: Experiment[],
  onRemoveExperiments: (experiments: Experiment[]) => Promise<void>,
}

const ProjectRemoveExperiment: FC<Props> = (props) => {

  const [selectedExperiments, selectExperiments] = useState<Experiment[]>([]);

  const onSubmit = async () => {
    await props.onRemoveExperiments(selectedExperiments);
    selectExperiments([]);
  }

  const onSelect = (experiment: Experiment) => {
    if (selectedExperiments.includes(experiment)) {
      selectExperiments(selectedExperiments.filter(exp => exp.uuid !== experiment.uuid));
    } else {
      selectExperiments(selectedExperiments.concat([experiment]));
    }
  }

  const onCancel = () => {
    selectExperiments([]);
    props.onCancel();
  }

  const getExperiments = () => {
    const filteredExp = props.projectExperiments.filter(exp => !selectedExperiments.includes(exp));
    return filteredExp;
  }
  
  return <> 
    <Modal
      title='Remove experiments'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      <Table>
        <TableHeader>
          <TableHeaderItem {...nameColumns}>
            NAME
          </TableHeaderItem>
          <TableHeaderItem {...typeColumns}>
            TYPE
          </TableHeaderItem>
          <TableHeaderItem {...startDateColumns}>
            START DATE
          </TableHeaderItem>
          <TableHeaderItem {...endDateColumns}>
            END DATE
          </TableHeaderItem>
          <TableHeaderItem {...daysColumns}>
            #DAYS
          </TableHeaderItem>
          <TableHeaderItem {...ownerColumns}>
            OWNER
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedExperiments.map(experiment => (
            <TableItem clickable={1} color="#DC493A" key={experiment.uuid} onClick={() => onSelect(experiment)}>
              <Col {...nameColumns}>
                {experiment.name}
              </Col>
              <Col {...typeColumns}>
                {experiment.type.name}
              </Col>
              <Col {...startDateColumns}>
                {experiment.start && moment(Number(experiment.start)).format('DD/MM/YYYY')}
              </Col>
              <Col {...endDateColumns}>
                {experiment.stop && moment(Number(experiment.stop)).format('DD/MM/YYYY')}
              </Col>
              <Col {...daysColumns}>
                {experiment.start && experiment.stop && moment(Number(experiment.stop)).diff(moment(Number(experiment.start)), 'days')}
              </Col>
              <Col {...ownerColumns}>
                {experiment.creator.firstName} {experiment.creator.lastName}
              </Col>
            </TableItem>
          ))}
        { getExperiments().map(experiment => (
            <TableItem clickable={1} key={experiment.uuid} onClick={() => onSelect(experiment)}>
              <Col {...nameColumns}>
                {experiment.name}
              </Col>
              <Col {...typeColumns}>
                {experiment.type.name}
              </Col>
              <Col {...startDateColumns}>
                {experiment.start && moment(Number(experiment.start)).format('DD/MM/YYYY')}
              </Col>
              <Col {...endDateColumns}>
                {experiment.stop && moment(Number(experiment.stop)).format('DD/MM/YYYY')}
              </Col>
              <Col {...daysColumns}>
                {experiment.start && experiment.stop && moment(Number(experiment.stop)).diff(moment(Number(experiment.start)), 'days')}
              </Col>
              <Col {...ownerColumns}>
                {experiment.creator.firstName} {experiment.creator.lastName}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ProjectRemoveExperiment;