import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, Redirect } from 'react-router';
import { Layout, Typography, Row, Col, Popconfirm } from 'antd';

import { Project, Experiment, Building } from '../../types';
import { ProjectState, ProjectModalType } from '../types';
import EditProjectView from './edit.project.view';
import { Iuuid } from '../../types';
import Text from '../../components/text.component';
import Label from '../../components/label.component';
import Menu from '../../core/components/menu.component';
import Loading from '../../components/loading.component';
import FloatRight from '../../components/float.right.component';
import FilesView from '../../files/components/';
import ProjectExperiments from './project.experiment.view';
import ProjectBuildings from './project.building.view';
import { Container, Button } from '../../components';

const { Content } = Layout;
const { Title } = Typography;

type Props = RouteComponentProps<Iuuid> & ProjectState & {
  getProject: (uuid: string) => void
  toggleModal: (modalType: ProjectModalType) => void
  deleteProject: (uuid: string) => Promise<void>
  uploadFile: (uuid: string, file: File, fileType: string, name?: string) => Promise<void>
  editProject: (uuid: string, param: Pick<Project, 'name' | 'description' | 'keywords'>) => Promise<void>
  onAddExperiments: (uuid: string, experiments: Experiment[]) => Promise<void>
  onRemoveExperiments: (uuid: string, experiments: Experiment[]) => Promise<void>
}

const ProjectView: FC<Props> = (props) => {
  const [deleted, setDeleted] = useState(false);
  const getProject = props.getProject;
  const projectUuid = props.match.params.uuid;
  useEffect(() => getProject(projectUuid), [getProject, projectUuid]);
  const onDelete = async () => {
    await props.deleteProject(props.match.params.uuid);
    setDeleted(true);
  }
  const getBuildings = () => {
    if (props.project && props.project.experiments) {
      const elements: Building[] = props.project.experiments.map(exp => exp.buildings || []).flat();
      const setOfElements = elements.reduce((acc: Building[], curr: Building) => {
        const listOfUuid = acc.map(buil => buil.uuid);
        if (!listOfUuid.includes(curr.uuid)) {
          return acc.concat(curr);
        }
        return acc;
      }, [])
      return setOfElements;
    }
    return [];
  }
  return <>
    {deleted && <Redirect to="/projects" />}
    <Layout>
      <Menu selected="projects" />
      <Content style={{ padding: '20px 50px' }}>
        <Loading spinning={props.loading}>
          <Row>
            <Col>
              <Title level={3}>
                {props.project && props.project.name}
              </Title>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col xs={{ span: 7 }} sm={{ span: 10 }} md={{ span: 14 }} lg={{ span: 17 }} xl={{ span: 18 }}>
                <Title level={4}>
                  General information
                </Title>
              </Col>
              <Col xs={{ span: 17 }} sm={{ span: 14 }} md={{ span: 10 }} lg={{ span: 7 }} xl={{ span: 6 }}>
                <FloatRight>
                  <Button style={{marginRight: '10px'}} onClick={() => props.toggleModal(ProjectModalType.EDIT)}>
                    edit project
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this project?"
                    onConfirm={onDelete}
                    okText="Yes"
                    cancelText="No"
                    placement="left"
                  >
                    <Button delete={1}>
                      delete project
                    </Button>
                  </Popconfirm>
                </FloatRight>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Row>
                  <Label>
                    KEYWORDS
                  </Label>
                  <Text>
                    { props.project && props.project.keywords}
                  </Text>
                </Row>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                  <Row>
                    <Label>
                      OWNER
                    </Label>
                    <Text>
                      { props.project && props.project.creator.firstName} { props.project && props.project.creator.lastName}
                    </Text>
                  </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} xl={{ span: 24 }}>
                <Label>
                  DESCRIPTION
                </Label>
                <Text>
                  {props.project && props.project.description}
                </Text>
              </Col>
            </Row>
          </Container>
        </Loading>
        { props.project && 
          <EditProjectView 
            project={props.project}
            loading={props.loading}
            onEdit={props.editProject}
            onCancel={() => props.toggleModal(ProjectModalType.EDIT)}
            visible={props.showModal && props.modalType === ProjectModalType.EDIT}
          />
        }
        { props.project && 
          <FilesView
            type='project'
            project={props.project}
            parentUuid={props.project.uuid}
          />
        }
        { props.project && 
          <ProjectExperiments
            projectExperiments={props.project.experiments || []}
            toggleModal={props.toggleModal}
            loading={props.loading}
            onRemoveExperiments={(experiments: Experiment[]) => props.onRemoveExperiments(props.match.params.uuid, experiments)}
            onAddExperiments={(experiments: Experiment[]) => props.onAddExperiments(props.match.params.uuid, experiments)}
            showModal={props.showModal}
            modalType={props.modalType}
          />
        }
        { getBuildings().length > 0 && 
          <ProjectBuildings
            loading={props.loading}
            buildings={getBuildings()}
          />
        }
      </Content>
    </Layout>
  </>
} 

export default ProjectView;