import React, { FC, useEffect, useState } from 'react';
import { Row, Col, Typography } from 'antd'

import { File as TypeOfFile, ModalType, FilesState, FileParent, CreateFileParam } from '../types';
import EditFileView from './edit.file.view';
import CreateFileView from './create.file.view';
import FileTable from './files.table.component';
import FloatRight from '../../components/float.right.component';
import Loading from '../../components/loading.component';
import { getFile } from '../server';
import { Project, Building, Experiment } from '../../types';
import { Container, Button } from '../../components';
import { useFetchField } from '../../hooks/field';
import { fileTypePath } from '../../server';
import { File as HestiaFile } from '../types';

const { Title } = Typography;

const titleColumns = {
  xs: { span: 17 },
  sm: { span: 18 },
  md: { span: 19 },
  lg: { span: 20 },
  xl: { span: 20 },
}

const buttonColumns = {
  xs: { span: 7 },
  sm: { span: 6 },
  md: { span: 5 },
  lg: { span: 4 },
  xl: { span: 4 },
}

type Props = FilesState & {
  parentUuid: string,
  project?: Project,
  building?: Building,
  experiment?: Experiment,
  type: FileParent,
  toggleModal: (modalType: ModalType) => void,
  getFiles: (files: TypeOfFile[]) => void,
  createFile: (type: FileParent, parentUuid: string, param: CreateFileParam, file: File) => Promise<void>,
  editFile: (type: FileParent, parentUuid: string, fileUuid: string, param: Partial<CreateFileParam>) => Promise<void>,
  deleteFile: (type: FileParent, parentUuid: string, fileUuid: string) => Promise<void>
}


const FilesView: FC<Props> = (props) => {

  const [selectedFile, setSelectedFile] = useState<TypeOfFile>();
  const fileTypes = useFetchField(fileTypePath);
  const getFiles = props.getFiles;
  const experiment = props.experiment;
  const building = props.building;
  const project = props.project;
  const type = props.type;

  useEffect(() => {
    if (type === 'experiment') {
      getFiles(experiment?.files ?? []);
    } else if (type === 'building') {
      getFiles(building?.files ?? []);
    } else {
      getFiles(project?.files ?? []);
    }
  }, [type, building, experiment, project, getFiles]);

  const downloadFile = async (file: HestiaFile) => {
    const downloadLink = await getFile(props.type, props.parentUuid, file.uuid);
    const response = await fetch(downloadLink);
    const fileBlob = await response.blob();
    const url = URL.createObjectURL(fileBlob);
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();
    link.parentNode?.removeChild(link);
  }

  const createFile = async (param: CreateFileParam, file: File) => {
    await props.createFile(props.type, props.parentUuid, param, file);
  }

  const editFile = async (param: Partial<CreateFileParam>, fileUuid: string) => {
    await props.editFile(props.type, props.parentUuid, fileUuid, param);
  }

  const selectFile = (file: TypeOfFile) => {
    setSelectedFile(file);
    props.toggleModal(ModalType.EDIT);
  }

  return <>
    <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { File title } */}
        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Files</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              <Button create={1} onClick={() => props.toggleModal(ModalType.CREATE)}>
                add file
              </Button>
            </FloatRight>
          </Col>
        </Row>

        {/* { File table } */}
        { props.files && props.files.length > 0 &&
          <FileTable
            files={props.files}
            downloadFile={downloadFile}
            deleteFile={async (fileUuid: string) => await props.deleteFile(props.type, props.parentUuid, fileUuid)}
            selectFile={selectFile}
          />
        }

        {/* { Create file } */}
        <CreateFileView 
          loading={props.loading}
          toggleModal={() => props.toggleModal(ModalType.CREATE)}
          createFile={createFile}
          showModal={props.showModal && props.modalType === ModalType.CREATE}
          fileTypes={fileTypes}
        />

        {/* { Edit file } */}
        {selectedFile &&
          <EditFileView
            file={selectedFile}
            loading={props.loading}
            toggleModal={() => props.toggleModal(ModalType.EDIT)}
            editFile={editFile}
            showModal={props.showModal && props.modalType === ModalType.EDIT}
            fileTypes={fileTypes}
          />
        }

      </Container>
    </Loading>
  </>
}

export default FilesView;