import React, { FC } from 'react';

import { File } from '../types';
import FileComponent from './file.component';
import { Table, TableHeader, TableHeaderItem } from '../../components';

const nameTypeColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 7 },
  lg: { span: 8 },
  xl: { span: 8 },
}

const actionColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 10 },
  lg: { span: 8 },
  xl: { span: 8 },
}

type Props = {
  files: File[],
  downloadLink?: string,
  selectFile: (file: File) => void,
  downloadFile: (file: File) => Promise<void>,
  deleteFile: (fileUuid: string) => Promise<void>,
}

const FileTable: FC<Props> = (props) => {
  return <>
    {props.downloadLink && window.open(props.downloadLink, '_blank')}
    <Table>
      <TableHeader>
        <TableHeaderItem {...nameTypeColumn}>
          NAME
        </TableHeaderItem>
        <TableHeaderItem {...nameTypeColumn}>
          TYPE
        </TableHeaderItem>
        <TableHeaderItem {...actionColumn}>
          ACTIONS
        </TableHeaderItem>
      </TableHeader>
      {props.files.map(file => (
        <FileComponent
          file={file}
          key={file.uuid}
          onDelete={props.deleteFile}
          toggleModal={() => { props.selectFile(file); }}
          downloadFile={props.downloadFile}
        />
      ))}
    </Table>
  </>
}

export default FileTable