import { Col } from "antd";
import React, { FC, useState } from 'react';

import Modal from '../../components/modal.component';
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";
import { User } from "../../types";

const columns = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 8 },
  lg: { span: 8 },
  xl: { span: 8 }
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  participants: User[],
  onRemoveParticipants: (users: User[]) => Promise<void>,
}

const ExperimentRemoveParticipant: FC<Props> = (props) => {

  const [selectedUsers, selectUsers] = useState<User[]>([]);

  const onSubmit = async () => {
    await props.onRemoveParticipants(selectedUsers);
    selectUsers([]);
  }

  const onSelect = (user: User) => {
    if (selectedUsers.includes(user)) {
      selectUsers(selectedUsers.filter(usr => usr.uuid !== user.uuid));
    } else {
      selectUsers(selectedUsers.concat([user]));
    }
  }

  const onCancel = () => {
    selectUsers([]);
    props.onCancel();
  }

  const getUsers = () => {
    const filteredUser = props.participants.filter(usr => !selectedUsers.includes(usr));
    return filteredUser;
  }
  
  return <> 
    <Modal
      title='Remove participants'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      <Table>
        <TableHeader>
          <TableHeaderItem {...columns}>
            FIRSTNAME
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            LASTNAME
          </TableHeaderItem>
          <TableHeaderItem {...columns}>
            ROLE
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedUsers.map(user => (
            <TableItem clickable={1} color="#DC493A" key={user.uuid} onClick={() => onSelect(user)}>
              <Col {...columns}>
                {user.firstName}
              </Col>
              <Col {...columns}>
                {user.lastName}
              </Col>
              <Col {...columns}>
                {user.role}
              </Col>
            </TableItem>
          ))}
        { getUsers().map(user => (
            <TableItem clickable={1} key={user.uuid} onClick={() => onSelect(user)}>
               <Col {...columns}>
                {user.firstName}
              </Col>
              <Col {...columns}>
                {user.lastName}
              </Col>
              <Col {...columns}>
                {user.role}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ExperimentRemoveParticipant;