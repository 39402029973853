import { message } from 'antd';
import { Dispatch } from "redux";

import { reportError } from "../core/utils";
import { BuildingActionTypes } from "./action.types";
import { fetchBuilding, putBuilding, delBuilding } from "./server";
import { pending, failure, fetchSuccess, editSuccess, deleteSuccess } from "./action.creators";
import { CreateBuildingParam } from "../buildings/types";

export const getBuilding = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(BuildingActionTypes.FetchPending));
  try {
    const response = await fetchBuilding(uuid);
    dispatch(fetchSuccess(response))
  } catch (e) {
    dispatch(failure(BuildingActionTypes.FetchFailure))
    await reportError(e);
  }
};

export const editBuilding = (uuid: string, param: Partial<CreateBuildingParam>) => async (dispatch: Dispatch) => {
  dispatch(pending(BuildingActionTypes.EditPending));
  try {
    const response = await putBuilding(uuid, param);
    message.success(`Building edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(BuildingActionTypes.EditFailure))
    await reportError(e);
  }
};

export const deleteBuilding = (uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(BuildingActionTypes.DeletePending));
  try {
    await delBuilding(uuid);
    message.success(`Building deleted`);
    dispatch(deleteSuccess())
  } catch (e) {
    dispatch(failure(BuildingActionTypes.DeleteFailure))
    await reportError(e);
  }
};
