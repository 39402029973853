import { ProjectState, ProjectActions, ProjectModalType } from "./types";
import { ProjectActionTypes } from "./action.types";

const initialState: ProjectState = {
  loading: false,
  showModal: false,
  project: undefined,
  modalType: ProjectModalType.EDIT
}

export default (state: ProjectState = initialState, action: ProjectActions): ProjectState => {
  if (Object.values(ProjectActionTypes).includes(action.type)) {
    return action.reducer(state);
  }
  return state;
}
