import styled from 'styled-components';

export const IconButton = styled.div`
  border-radius: 17.5px;
  background: white;
  color: #313131
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  :hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0);
  }
`;
