import { Col } from "antd";
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';

import Modal from '../../components/modal.component';
import experimentOptions from "../../filter/options/experimentOptions";
import FilterView from "../../filter/components/filter.view";
import { Filter } from "../../filter/types";
import { Table, TableHeader, TableHeaderItem, TableItem } from "../../components";
import { fetchExperiments } from "../../server";
import { Experiment } from "../../types";

const nameColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 7 },
}
const typeColumns = {
  xs: { span: 6 },
  sm: { span: 7 },
  md: { span: 8 },
  lg: { span: 6 },
  xl: { span: 6 },
}
const startDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const endDateColumns = {
  xs: { span: 6 },
  sm: { span: 5 },
  md: { span: 4 },
  lg: { span: 3 },
  xl: { span: 3 },
}
const daysColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 2 },
  xl: { span: 2 },
}
const ownerColumns = {
  xs: { span: 0 },
  sm: { span: 0 },
  md: { span: 0 },
  lg: { span: 4 },
  xl: { span: 3 },
}

type Props = {
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  onAddExperiments: (experiments: Experiment[]) => void,
  projectExperiments: Experiment[],
}

const ProjectAddExperiment: FC<Props> = (props) => {
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [selectedExperiments, selectExperiments] = useState<Experiment[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);

  useEffect(() => {
    const retrieveExperiments = async () => {
      const experiments = await fetchExperiments();
      setExperiments(experiments);
    }
    retrieveExperiments();
  }, [experiments.length]);

  const onSubmit = async () => {
    await props.onAddExperiments(selectedExperiments);
    selectExperiments([]);
  }

  const onSelect = (experiment: Experiment) => {
    if (selectedExperiments.includes(experiment)) {
      selectExperiments(selectedExperiments.filter(exp => exp.uuid !== experiment.uuid));
    } else {
      selectExperiments(selectedExperiments.concat([experiment]));
    }
  }

  const onCancel = () => {
    selectExperiments([]);
    props.onCancel();
  }

  const getExperiments = () => {
    const projectExperimentUids = props.projectExperiments.map(exp => exp.uuid);
    const filteredExp = experiments.filter(exp => !selectedExperiments.includes(exp) && !projectExperimentUids.includes(exp.uuid));
    return filters.reduce<Experiment[]>((acc, curr) => {
      return acc.filter(exp => curr.exec(exp))
    }, filteredExp)
  }
  
  return <> 
    <Modal
      title='Add experiments'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Save"
      cancelText="Cancel"
      width='90%'
      confirmLoading={props.loading}
    >
      {/* Filter */}
      <FilterView
        options={experimentOptions}
        filters={filters}
        setFilters={setFilters}
      />

      <Table>
        <TableHeader>
          <TableHeaderItem {...nameColumns}>
            NAME
          </TableHeaderItem>
          <TableHeaderItem {...typeColumns}>
            TYPE
          </TableHeaderItem>
          <TableHeaderItem {...startDateColumns}>
            START DATE
          </TableHeaderItem>
          <TableHeaderItem {...endDateColumns}>
            END DATE
          </TableHeaderItem>
          <TableHeaderItem {...daysColumns}>
            #DAYS
          </TableHeaderItem>
          <TableHeaderItem {...ownerColumns}>
            OWNER
          </TableHeaderItem>
        </TableHeader>
        <div>
          {selectedExperiments.map(experiment => (
            <TableItem clickable={1} color="#77B28C" key={experiment.uuid} onClick={() => onSelect(experiment)}>
              <Col {...nameColumns}>
                {experiment.name}
              </Col>
              <Col {...typeColumns}>
                {experiment.type.name}
              </Col>
              <Col {...startDateColumns}>
                {experiment.start && moment(Number(experiment.start)).format('DD/MM/YYYY')}
              </Col>
              <Col {...endDateColumns}>
                {experiment.stop && moment(Number(experiment.stop)).format('DD/MM/YYYY')}
              </Col>
              <Col {...daysColumns}>
                {experiment.start && experiment.stop && moment(Number(experiment.stop)).diff(moment(Number(experiment.start)), 'days')}
              </Col>
              <Col {...ownerColumns}>
                {experiment.creator.firstName} {experiment.creator.lastName}
              </Col>
            </TableItem>
          ))}
        { getExperiments().map(experiment => (
            <TableItem clickable={1} key={experiment.uuid} onClick={() => onSelect(experiment)}>
              <Col {...nameColumns}>
                {experiment.name}
              </Col>
              <Col {...typeColumns}>
                {experiment.type.name}
              </Col>
              <Col {...startDateColumns}>
                {experiment.start && moment(Number(experiment.start)).format('DD/MM/YYYY')}
              </Col>
              <Col {...endDateColumns}>
                {experiment.stop && moment(Number(experiment.stop)).format('DD/MM/YYYY')}
              </Col>
              <Col {...daysColumns}>
                {experiment.start && experiment.stop && moment(Number(experiment.stop)).diff(moment(Number(experiment.start)), 'days')}
              </Col>
              <Col {...ownerColumns}>
                {experiment.creator.firstName} {experiment.creator.lastName}
              </Col>
            </TableItem>
          ))
        }
        </div>
      </Table>
    </Modal>
  </>  
}

export default ProjectAddExperiment;