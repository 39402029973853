export enum ProjectActionTypes {
  ToggleModal = 'project/TOGGLE_MODAL',
  FetchPending = 'project/FETCH_PENDING',
  FetchSuccess = 'project/FETCH_SUCCESS',
  FetchFailure = 'project/FETCH_FAILURE',
  EditPending = 'project/EDIT_PENDING',
  EditSuccess = 'project/EDIT_SUCCESS',
  EditFailure = 'project/EDIT_FAILURE',
  DeletePending = 'project/DELETE_PENDING',
  DeleteSuccess = 'project/DELETE_SUCCESS',
  DeleteFailure = 'project/DELETE_FAILURE',
  AddExperimentPending = 'project/ADD_EXPERIMENT_PENDING',
  AddExperimentSuccess = 'project/ADD_EXPERIMENT_SUCCESS',
  AddExperimentFailure = 'project/ADD_EXPERIMENT_FAILURE',
  RemoveExperimentPending = 'project/REMOVE_EXPERIMENT_PENDING',
  RemoveExperimentSuccess = 'project/REMOVE_EXPERIMENT_SUCCESS',
  RemoveExperimentFailure = 'project/REMOVE_EXPERIMENT_FAILURE',
}