import { message } from 'antd';
import { Dispatch } from 'redux';

import { HeatingSystem } from '../types';
import { reportError } from '../core/utils';
import { CreateHeatingSystemParam } from "./types";
import { HeatingSystemActionTypes } from "./action.types";
import { postHeatingSystem, putHeatingSystem, delHeatingSystem } from './server';
import { pending, failure, deleteSuccess, editSuccess, createSuccess, fetchSuccess } from './action.creators';

export const getHeatingSystem = (heatingSystem?: HeatingSystem) => async (dispatch: Dispatch) => {
  dispatch(pending(HeatingSystemActionTypes.FetchPending));
  try {
    dispatch(fetchSuccess(heatingSystem))
  } catch (e) {
    dispatch(failure(HeatingSystemActionTypes.FetchFailure))
    await reportError(e);
  }
};


export const createHeatingSystem = (type: 'experiment' | 'building', parentUuid: string, param: CreateHeatingSystemParam) => async (dispatch: Dispatch) => {
  dispatch(pending(HeatingSystemActionTypes.createPending));
  try {
    const response = await postHeatingSystem(type, parentUuid, param);
    message.success(`Heating system created`);
    dispatch(createSuccess(response))
  } catch (e) {
    dispatch(failure(HeatingSystemActionTypes.createFailure));
    await reportError(e);
  }
};

export const editHeatingSystem = (type: 'experiment' | 'building', parentUuid: string, uuid: string, param: Partial<CreateHeatingSystemParam>) => async (dispatch: Dispatch) => {
  dispatch(pending(HeatingSystemActionTypes.EditPending));
  try {
    const response = await putHeatingSystem(type, parentUuid, uuid, param);
    message.success(`Heating system edited`);
    dispatch(editSuccess(response))
  } catch (e) {
    dispatch(failure(HeatingSystemActionTypes.EditFailure))
    await reportError(e);
  }
};

export const deleteHeatingSystem = (type: 'experiment' | 'building', parentUuid: string, uuid: string) => async (dispatch: Dispatch) => {
  dispatch(pending(HeatingSystemActionTypes.DeletePending));
  try {
    await delHeatingSystem(type, parentUuid, uuid);
    message.success(`Heating system deleted`);
    dispatch(deleteSuccess())
  } catch (e) {
    dispatch(failure(HeatingSystemActionTypes.DeleteFailure))
    await reportError(e);
  }
};