import React, { FC, useState, useEffect } from 'react';

import EditFileForm from './edit.file.form';
import Modal from '../../components/modal.component';
import { CreateFileParam, File } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

type Props = {
  file: File
  loading: boolean
  showModal: boolean
  fileTypes: Field[]
  toggleModal: () => void
  editFile: (param: Partial<CreateFileParam>, fileUuid: string) => Promise<void>
}

type Inputs = Partial<CreateFileParam>

const EditFileView: FC<Props> = (props) => {

  const init: CreateFileParam = {
    type: props.file.type.uuid,
    name: props.file.name,
  }

  const [inputs, setInputs] = useState<Inputs>({ ...init });

  useEffect(() => setInputs({
    type: props.file.type.uuid,
    name: props.file.name
  }), [props.file.type.uuid, props.file.name]);

  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
    return disable || !inputs.type || !inputs.name;
  }
  
  const onChange = (event: OnChangeEvent) => {
    setInputs({ ...inputs, [event.name]: event.value })
  }

  const onCancel = () => {
    setInputs({ ...init});
    props.toggleModal();
  }

  const handleSubmit = async () => {
    if (!disableOkButton()) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.editFile(params, props.file.uuid);
    }
  }

  return <> 
  <Modal
    title='Edit file'
    visible={props.showModal}
    onOk={handleSubmit}
    onCancel={onCancel}
    destroyOnClose={true}
    okText="Edit"
    cancelText="Cancel"
    confirmLoading={props.loading}
    disableOkButton={disableOkButton()}
  >
    <EditFileForm
      inputs={inputs}
      onChange={onChange}
      fileTypes={props.fileTypes}
    />
  </Modal>
</>
}

export default EditFileView;