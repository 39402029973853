import React, { FC, FormEvent } from 'react';
import { Row, Form, Input, Select } from 'antd';

import Col from '../../components/form.col.component';
import Item from '../../components/form.item.component';
import { CreateFileParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

const { Option } = Select;

type Props = {
  inputs: Partial<CreateFileParam>
  fileTypes: Field[]
  onChange: (event: OnChangeEvent) => void
}

const EditFileForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value });
  }

  return <>
  <Form layout="vertical">
    <Row>
      <Col>
        <Item label="Name" required>
        <Input name="name" value={props.inputs.name} onChange={onChange}/>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col>
        <Item label="Type" required>
          <Select
            showSearch
            value={props.inputs.type || undefined}
            placeholder="Select a type"
            onSelect={(value: string) => { props.onChange({ name: 'type', value }); }}
          >
            { props.fileTypes.map(fileType => 
              <Option key={fileType.uuid} value={fileType.uuid}>{fileType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
    </Row>
  </Form>
</>
} 

export default EditFileForm;