import React, { FC, useEffect } from 'react';
import { Layout, Row, Col, Typography} from 'antd';

import Menu from '../../core/components/menu.component';
import CreateBuildingView from './create.building.view';
import BuildingView from '../../components/building.component';
import { BuildingsState, CreateBuildingParam } from '../types';
import Loading from '../../components/loading.component';
import { sortTime } from '../../core/utils';
import Scrollable from '../../core/components/scrollable.component';
import buildingOptions from '../../filter/options/building';
import FilterView from '../../filter/components/filter.view';
import { Filter } from '../../filter/types';
import { Button, Table, TableHeaderItem, TableHeader, Empty } from '../../components';
import { Building } from '../../types';
import { useFetchField } from '../../hooks/field';
import { buildingTypePath, buildingStatesPath } from '../../server';

const { Content } = Layout;
const { Title } = Typography;

const typeColumns = { span: 6 };
const statecolumns = { span: 6 };
const ownerColumns = { span: 4 };
const addressColumns = { span: 8 };

type Props = BuildingsState & {
  toggleModal: () => void,
  getBuildings: () => void,
  setFilters: (filters: Filter[]) => void,
  createBuilding: (param: CreateBuildingParam) => Promise<void>,
}

const BuildingsView: FC<Props> = (props) => {

  const buildingTypes = useFetchField(buildingTypePath);
  const buildingStates = useFetchField(buildingStatesPath);
  const getServerBuildings = props.getBuildings;
  useEffect(() => getServerBuildings(), [getServerBuildings])

  const getBuildings = () => {
    return props.filters.reduce<Building[]>((acc, curr) => {
      return acc.filter(buil => curr.exec(buil))
    }, props.buildings)
  }

  return <>
    <Layout>
      <Menu selected="buildings"/>
      <Content style={{ padding: '20px 50px' }}>
        <Row>
          <Col xs={{ span: 16 }} sm={{ span: 17}} md={{ span: 19 }} lg={{ span: 20 }} xl={{ span: 21 }}>
            <Title level={3}>Buildings</Title>
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 7 }} md={{ span: 5 }} lg={{ span: 4 }} xl={{ span: 3 }}>
            <Button style={{float: 'right'}} create={1} onClick={props.toggleModal}>
              create building
            </Button>
          </Col>
        </Row>

        {/* Filter */}
        <FilterView
          options={buildingOptions}
          filters={props.filters}
          setFilters={props.setFilters}
        />

        <Loading spinning={props.loading}>
          <Table>
            <TableHeader>
              <TableHeaderItem {...addressColumns}>
                ADDRESS
              </TableHeaderItem>
              <TableHeaderItem {...typeColumns}>
                TYPE
              </TableHeaderItem>
              <TableHeaderItem {...statecolumns}>
                STATE
              </TableHeaderItem>
              <TableHeaderItem {...ownerColumns}>
                OWNER
              </TableHeaderItem>
            </TableHeader>
            <Scrollable>
              {
                getBuildings().sort((a,b) =>sortTime(Number(a.updatedAt), Number(b.updatedAt))).map(building => (
                  <BuildingView key={building.uuid} id={building.uuid}>
                    <Col {...addressColumns}>
                      {building.street} {building.number}, {building.postal} {building.city}, {building.country}
                    </Col>
                    <Col {...typeColumns}>
                      {building.type.name}
                    </Col>
                    <Col {...statecolumns}>
                      {building.state && building.state.name}
                    </Col>
                    <Col {...ownerColumns}>
                      {building.creator.firstName} {building.creator.lastName}
                    </Col>
                  </BuildingView>
                ))
              }
              { getBuildings().length === 0 && <Empty /> }
            </Scrollable>
          </Table>
        </Loading>
        <CreateBuildingView 
          loading={props.loading}
          showModal={props.showModal}
          buildingTypes={buildingTypes}
          buildingStates={buildingStates}
          toggleModal={props.toggleModal}
          createBuilding={props.createBuilding}
        />
      </Content>
    </Layout>  
  </>
}

export default BuildingsView;