import React, { FC, FormEvent } from 'react';
import { Form, Input } from 'antd';

import Item from '../../components/form.item.component';
import { EditUserParams } from '../types';

type Props = {
  inputs: EditUserParams
  onChange: (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const EditProjectForm: FC<Props> = (props) => {
  return <>
  <Form layout="vertical">
    <Item label="First name" required>
      <Input name="firstName" value={props.inputs.firstName} onChange={props.onChange}/>
    </Item>
    <Item label="Last name" required>
      <Input name="lastName" value={props.inputs.lastName} onChange={props.onChange}/>
    </Item>
    <Item label="Email" required>
      <Input name="email" value={props.inputs.email} onChange={props.onChange}/>
    </Item>
    <Item label="Telephone">
      <Input name="telephone" value={props.inputs.telephone} onChange={props.onChange}/>
    </Item>
    <Item label="KulID">
      <Input name="kulid" value={props.inputs.kulid} onChange={props.onChange}/>
    </Item>
  </Form>
</>
} 

export default EditProjectForm;