import { Project } from '../types';
import { ProjectFailureActions, ProjectModalType } from '../project/types';
import { ProjectActionTypes } from './action.types';
import { ProjectAction, ProjectState, ProjectPendingActions } from './types';

export const toggleModal = (modalType: ProjectModalType): ProjectAction<ProjectActionTypes.ToggleModal> => ({
  type: ProjectActionTypes.ToggleModal,
  reducer(state): ProjectState {
    return {
      ...state,
      modalType,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: ProjectPendingActions): ProjectAction<ProjectPendingActions> => ({
  type,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: ProjectFailureActions): ProjectAction<ProjectFailureActions> => ({
  type,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (project: Project): ProjectAction<ProjectActionTypes.FetchSuccess> => ({
  type: ProjectActionTypes.FetchSuccess,
  reducer(state): ProjectState {
    return {
      ...state,
      project,
      loading: false
    }
  }
});

export const editSuccess = (project: Project): ProjectAction<ProjectActionTypes.EditSuccess> => ({
  type: ProjectActionTypes.EditSuccess,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: false,
      showModal: false,
      project
    }
  }
});

export const deleteSuccess = (): ProjectAction<ProjectActionTypes.DeleteSuccess> => ({
  type: ProjectActionTypes.DeleteSuccess,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: false,
      project: undefined
    }
  }
});

export const addExperimentSuccess = (project: Project): ProjectAction<ProjectActionTypes.AddExperimentSuccess> => ({
  type: ProjectActionTypes.AddExperimentSuccess,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: false,
      showModal: false,
      project
    }
  }
})

export const removeExperimentSuccess = (project: Project): ProjectAction<ProjectActionTypes.RemoveExperimentSuccess> => ({
  type: ProjectActionTypes.RemoveExperimentSuccess,
  reducer(state): ProjectState {
    return {
      ...state,
      loading: false,
      showModal: false,
      project
    }
  }
})
