export enum UserRole {
  admin = 'ADMIN',
  employee = 'EMPLOYEE',
  student = 'STUDENT',
}

export type User = {
  uuid: string,
  role: UserRole,
  lastName: string,
  firstName: string,
  email: string,
  kulid?: string,
  telephone?: string,
  createdAt: string,
  updatedAt: string,
}
