import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { UserProvider, FilterProvider } from './context';

if (process.env.NODE_ENV === 'production' && !window.location.hostname.includes('stage')) {
  Sentry.init({dsn: "https://f5e8b201365443da9a66c531fc1e968d@sentry.io/1777541"});
}

ReactDOM.render(
  <Provider store={store} >
    <UserProvider>
      <FilterProvider>
        <App />
      </FilterProvider>
    </UserProvider>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
