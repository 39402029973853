import React, { FC, FormEvent } from 'react';
import { Row, Form, Input, DatePicker, Select } from 'antd';

import Col from '../../components/form.col.component';
import Item from '../../components/form.item.component';
import { OnChangeEvent } from '../../core/types';
import moment from 'moment';
import { Field, CreateExperimentParam } from '../../types';

const { Option } = Select;
const { TextArea } = Input;

const nameColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const typeColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const startColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const stopColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

type Props = {
  inputs: Partial<CreateExperimentParam>,
  onChange: (event: OnChangeEvent) => void,
  experimentTypes: Field[],
  experimentHeatingTypes: Field[],
}

const EditExperimentForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value })
  }
  
  return <>
  <Form layout="vertical">
    <Row>
      <Col {...nameColumns}>
        <Item label="Name" required>
          <Input name="name" value={props.inputs.name} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...typeColumns}>
        <Item label="Type" required>
          <Select
            showSearch
            value={props.inputs.type || undefined}
            placeholder="Select a type"
            onChange={(value: string) => { props.onChange({ name: 'type', value }); }}
          >
            {props.experimentTypes.map(expType => 
              <Option key={expType.uuid} value={expType.uuid}>{expType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col {...startColumns}>
        <Item label="Start date">
          <DatePicker
            format='DD/MM/YYYY'
            value={props.inputs.start ? moment(Number(props.inputs.start)) : undefined}
            onChange={(date: moment.Moment | null) => {
              if (date) {
                props.onChange({ name: 'start', value: date.valueOf().toString() });
              } else {
                props.onChange({ name: 'start', value: undefined });
              }
            }}/>
        </Item>
      </Col>
      <Col {...stopColumns}>
        <Item label="Stop date">
          <DatePicker
            format='DD/MM/YYYY'
            value={props.inputs.stop ? moment(Number(props.inputs.stop)) : undefined}
            onChange={(date: moment.Moment | null) => {
              if (date) {
                props.onChange({ name: 'stop', value: date.valueOf().toString() });
              } else {
                props.onChange({ name: 'stop', value: undefined });
              }
            }}/>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col {...typeColumns}>
        <Item label="Heating system">
          <Select
            showSearch
            value={props.inputs.heating || undefined}
            placeholder="Select a heating system"
            onChange={(value: string) => { props.onChange({ name: 'heating', value }); }}
          >
            {props.experimentHeatingTypes.map(expHeatType => 
              <Option key={expHeatType.uuid} value={expHeatType.uuid}>{expHeatType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col>
        <Item label="Description">
          <TextArea name="description" rows={4} value={props.inputs.description} onChange={onChange}/>
        </Item>
      </Col>
    </Row>
  </Form>
</>
} 

export default EditExperimentForm;