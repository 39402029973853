import React, { FormEvent, FC, useState, useEffect } from 'react';

import { EditUserParams } from '../types';
import EditProfileForm from './edit.profile.form';
import Modal from '../../components/modal.component';
import { User } from '../../types';

type Props = {
  user: User,
  loading: boolean,
  visible: boolean,
  onCancel: () => void,
  onEdit: (param: EditUserParams) => Promise<void>
}

const EditProfileView: FC<Props> = (props) => {
  const init: EditUserParams = {
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    email: props.user.email,
    kulid: props.user.kulid,
    telephone: props.user.telephone
  }
  const [inputs, setInputs] = useState<EditUserParams>({ ...init });
  useEffect(() => setInputs({
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    email: props.user.email,
    kulid: props.user.kulid,
    telephone: props.user.telephone
  }), [props.user.firstName, props.user.lastName, props.user.email, props.user.kulid, props.user.telephone]);
  const onSubmit = async () => {
    if (!disableOkButton() && props.user.firstName && props.user.lastName && props.user.email) {
      const params = Object.keys(inputs).reduce((acc: any, curr) => {
        if (inputs[curr] !== init[curr]) {
          acc[curr] = inputs[curr];
        }
        return acc;
      }, {});
      await props.onEdit(params);
    }
  }
  const onChange = (event: FormEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInputs({ ...inputs, [event.currentTarget.name]: event.currentTarget.value});
  }
  const onCancel = () => {
    setInputs({ ...init });
    props.onCancel();
  }
  const disableOkButton = () => {
    const disable = Object.keys(init).reduce((acc, curr) => {
      return acc && init[curr] === inputs[curr]
    }, true);
   return disable;
  }
  return <> 
    <Modal
      title='Edit profile'
      onOk={onSubmit}
      onCancel={onCancel}
      visible={props.visible}
      destroyOnClose={true}
      okText="Edit"
      cancelText="Cancel"
      confirmLoading={props.loading}
      disableOkButton={disableOkButton()}
    >
      <EditProfileForm
        inputs={inputs}
        onChange={onChange}
      />
    </Modal>
  </>  
}

export default EditProfileView;