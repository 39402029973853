import React, { FC, useState, Dispatch, SetStateAction } from 'react';
import { User } from '../types';

type UserProvider = [ User | undefined,  Dispatch<SetStateAction<User | undefined>> | undefined];

export const UserContext = React.createContext<UserProvider>([undefined, undefined]);

export const UserProvider: FC = ({ children }) => {

  const [user, setUser] = useState<User|undefined>(undefined);
  
  return <>
    <UserContext.Provider value={[user, setUser]}>
      { children }
    </UserContext.Provider>
  </>
}