export enum ProfileActionTypes {
  ToggleModal = 'profile/TOGGLE_MODAL',
  FetchPending = 'profile/FETCH_PENDING',
  FetchSuccess = 'profile/FETCH_SUCCESS',
  FetchFailure = 'profile/FETCH_FAILURE',
  EditPending = 'profile/EDIT_PENDING',
  EditSuccess = 'profile/EDIT_SUCCESS',
  EditFailure = 'profile/EDIT_FAILURE',
  PasswordPending = 'profile/PASSWORD_PENDING',
  PasswordSuccess = 'profile/PASSWORD_SUCCESS',
  PasswordFailure = 'profile/PASSWORD_FAILURE',
  DowngradePending = 'profile/DOWNGRADE_PENDING',
  DowngradeSuccess = 'profile/DOWNGRADE_SUCCESS',
  DowngradeFailure = 'profile/DOWNGRADE_FAILURE',
}