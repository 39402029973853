import React, { FC, useEffect } from 'react';
import { Row, Col, Typography, Popconfirm } from 'antd'

import EditHeatingView from './edit.heating.view';
import Text from '../../components/text.component';
import { Container, Button } from '../../components';
import Label from '../../components/label.component';
import CreateHeatingView from './create.heating.view';
import Loading from '../../components/loading.component';
import FloatRight from '../../components/float.right.component';
import { Building, HeatingSystem, Experiment } from '../../types';
import { HeatingSystemState, ModalType, CreateHeatingSystemParam } from '../types';
import { useFetchField } from '../../hooks/field';
import { heatingTypePath, heatingEmissionPath, heatingProductionPath } from '../../server';

const { Title } = Typography;

const titleColumns = {
  xs: { span: 24 },
  sm: { span: 5 },
  md: { span: 10 },
  lg: { span: 14 },
  xl: { span: 16 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 19 },
  md: { span: 14 },
  lg: { span: 10 },
  xl: { span: 8 },
}

const createTitleColumns = {
  xs: { span: 12 },
  sm: { span: 15 },
  md: { span: 17 },
  lg: { span: 18 },
  xl: { span: 18 },
}

const createButtonColumns = {
  xs: { span: 12 },
  sm: { span: 9 },
  md: { span: 7 },
  lg: { span: 6 },
  xl: { span: 6 },
}

const typeColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 },
}

const produColumns = {
  xs: { span: 12 },
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 6},
  xl: { span: 6},
}

const emmisColumns = {
  xs: { span: 24},
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 6},
  xl: { span: 6},
}

const descrColumns = {
  xs: { span: 24},
  sm: { span: 24},
  md: { span: 24},
  lg: { span: 12},
  xl: { span: 12},
}

const domeColumns = {
  xs: { span: 12},
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 6 },
  xl: { span: 6 },
}

const storagColumns = {
  xs: { span: 12},
  sm: { span: 12},
  md: { span: 12},
  lg: { span: 6 },
  xl: { span: 6 },
}

type Props = HeatingSystemState & {
  parentUuid: string,
  building?: Building,
  experiment?: Experiment,
  type: 'building',
  toggleModal: (modalType: ModalType) => void,
  getHeatingSystem: (heatingSystem?: HeatingSystem) => void,
  createHeatingSystem: (type: 'building', parentUuid: string, param: CreateHeatingSystemParam) => Promise<void>,
  updateHeatingSystem: (type: 'building', parentUuid: string, uuid: string, param: Partial<CreateHeatingSystemParam>) => Promise<void>,
  deleteHeatingSystem: (type: 'building', parentUuid: string, uuid: string) => Promise<void>,
}

const HeatingSystemView: FC<Props> = (props) => {

  const type = props.type;
  const building = props.building;
  const experiment = props.experiment;
  const getHeatingSystem = props.getHeatingSystem;
  
  useEffect(() => {
    if (type === 'building') {
      building && getHeatingSystem(building.heatingSystem)
    }
  }, [type, building, experiment, getHeatingSystem]);

  const heatingSystemTypes = useFetchField(heatingTypePath);
  const heatingSystemEmissionSystems = useFetchField(heatingEmissionPath);
  const heatingSystemProductionUnits = useFetchField(heatingProductionPath);

  const createHeatingSystem = async (param: CreateHeatingSystemParam) => {
    await props.createHeatingSystem(props.type, props.parentUuid, param)
  }

  const editHeatingSystem = async (param: Partial<CreateHeatingSystemParam>) => {
    if (props.heatingSystem) {
      props.updateHeatingSystem(props.type, props.parentUuid, props.heatingSystem.uuid, param);
    }
  }

  return <>
    <Loading spinning={props.loading}>
      <Container style={{marginTop: '20px'}}>

        {/* { Heating System Title} */}
        { !props.heatingSystem && 
          <Row>
            <Col {...createTitleColumns}>
              <Title level={4}>Heating system</Title>
            </Col>
            <Col {...createButtonColumns}>
              <FloatRight>
                <Button create={1} onClick={() => props.toggleModal(ModalType.CREATE)}>
                  create heating system
                </Button>
              </FloatRight>
            </Col>
          </Row>
        }
        { props.heatingSystem &&
          <Row>
            <Col {...titleColumns}>
              <Title level={4}>Heating system</Title>
            </Col>
            <Col {...buttonColumns}>
              <FloatRight>
                <Button style={{marginRight: '10px'}} onClick={() => props.toggleModal(ModalType.EDIT)}>
                  edit heating system
                </Button>
                <Popconfirm
                  title="Are you sure you want to delete this heating system?"
                  onConfirm={() => props.heatingSystem && props.deleteHeatingSystem(props.type, props.parentUuid, props.heatingSystem.uuid)}
                  okText="Yes"
                  cancelText="No"
                  placement="left"
                >
                  <Button delete={1}>
                    delete heating system
                  </Button>
                </Popconfirm>
              </FloatRight>
            </Col>
          </Row>
        }

        {/* {Heating System content} */}

        { props.heatingSystem && <>
          <Row>
            <Col {...typeColumns}>
              <Row>
                <Label>TYPE</Label>
                <Text>{props.heatingSystem.type.name}</Text>
              </Row>
            </Col>
            <Col {...produColumns}>
              <Row>
                <Label>PRODUCTION UNIT</Label>
                <Text>{props.heatingSystem.productionUnit && props.heatingSystem.productionUnit.name}</Text>
              </Row>
            </Col>
            <Col {...emmisColumns}>
              <Row>
                <Label>EMISSION SYSTEM</Label>
                <Text>{props.heatingSystem.emissionSystem && props.heatingSystem.emissionSystem.name}</Text>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col {...descrColumns}>
              <Row>
                <Label>DESCRIPTION</Label>
                <Text>{props.heatingSystem.description}</Text>
              </Row>
            </Col>
            <Col {...domeColumns}>
              <Row>
                <Label>DOMESTIC HOT WATER</Label>
                <Text>{props.heatingSystem.domesticHotWater ? 'Yes' : 'No'}</Text>
              </Row>
            </Col>
            <Col {...storagColumns}>
              <Row>
                <Label>STORAGE TANK</Label>
                <Text>{props.heatingSystem.storageTank ? 'Yes' : 'No'}</Text>
              </Row>
            </Col>
          </Row>
        </>}
        <CreateHeatingView
          loading={props.loading}
          toggleModal={() => props.toggleModal(ModalType.CREATE)}
          createHeatingSystem={createHeatingSystem}
          showModal={props.showModal && props.modalType === ModalType.CREATE}
          heatingSystemTypes={heatingSystemTypes}
          heatingSystemProductionUnits={heatingSystemProductionUnits}
          heatingSystemEmissionSystems={heatingSystemEmissionSystems}
        />
        {props.heatingSystem &&
          <EditHeatingView
            loading={props.loading}
            heatingSystem={props.heatingSystem}
            toggleModal={() => props.toggleModal(ModalType.EDIT)}
            editHeatingSystem={editHeatingSystem}
            showModal={props.showModal && props.modalType === ModalType.EDIT}
            heatingSystemTypes={heatingSystemTypes}
            heatingSystemProductionUnits={heatingSystemProductionUnits}
            heatingSystemEmissionSystems={heatingSystemEmissionSystems}
          />
        }
      </Container>
    </Loading>
  </>
}

export default HeatingSystemView;