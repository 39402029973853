import moment from 'moment';
import React, { FC, FormEvent } from 'react';
import { Row, Form, Input, DatePicker, Switch, Select } from 'antd';

import Col from '../../components/form.col.component';
import Item from '../../components/form.item.component';
import { CreateBuildingParam } from '../types';
import { OnChangeEvent } from '../../core/types';
import { Field } from '../../types';

const { Option } = Select;
const { TextArea } = Input;

const streetColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 8 }
};

const numberColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 6 },
  lg: { span: 6 },
  xl: { span: 3 }
};

const postalColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 6 },
  lg: { span: 6 },
  xl: { span: 3 }
};

const cityColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 5 }
}

const countryColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 5 }
}

const typeColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const stateColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const perfColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const plansColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const descrColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const mixColumns = {
  xs: { span: 24 },
  sm: { span: 24 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const construColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 6 },
  xl: { span: 6 }
}

const blowerColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const accubalanceColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

const smokeColumns = {
  xs: { span: 12 },
  sm: { span: 12 },
  md: { span: 12 },
  lg: { span: 12 },
  xl: { span: 12 }
}

type Props = {
  inputs: Partial<CreateBuildingParam>,
  onChange: (event: OnChangeEvent) => void,
  buildingTypes: Field[],
  buildingStates: Field[]
}

const CreateBuildingForm: FC<Props> = (props) => {

  const onChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.onChange({ name: event.currentTarget.name, value: event.currentTarget.value })
  }
  
  return <>
  <Form layout="vertical">
    <Row>
      <Col {...streetColumns}>
        <Item label="Street" required>
          <Input name="street" value={props.inputs.street} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...numberColumns}>
        <Item label="Number" required>
          <Input name="number" value={props.inputs.number} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...postalColumns}>
        <Item label="Postal" required>
          <Input name="postal" value={props.inputs.postal} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...cityColumns}>
        <Item label="City" required>
          <Input name="city" value={props.inputs.city} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...countryColumns}>
        <Item label="Country" required>
          <Input name="country" value={props.inputs.country} onChange={onChange}/>
        </Item>
      </Col>
    </Row>
    <Row>
      <Col {...typeColumns}>
        <Item label="Type" required>
          <Select
            showSearch
            value={props.inputs.type || undefined}
            placeholder="Select a type"
            onSelect={(value: string) => { props.onChange({ name: 'type', value }); }}
          >
            {props.buildingTypes.map(buildingType => 
              <Option key={buildingType.uuid} value={buildingType.uuid}>{buildingType.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
      <Col {...stateColumns}>
        <Item label="State">
          <Select
            showSearch
            value={props.inputs.state || undefined}
            placeholder="Select a state"
            onSelect={(value: string) => { props.onChange({ name: 'state', value }); }}
          >
           {props.buildingStates.map(buildingState => 
              <Option key={buildingState.uuid} value={buildingState.uuid}>{buildingState.name}</Option>
            )}
          </Select>
        </Item>
      </Col>
      <Col {...construColumns}>
        <Item label="Construction / renovation date">
          <DatePicker
            format='DD/MM/YYYY'
            value={props.inputs.constructionDate ? moment(Number(props.inputs.constructionDate)) : undefined}
            onChange={(date: moment.Moment | null) => {
              if (date) {
                props.onChange({ name: 'constructionDate', value: date.valueOf().toString() });
              } else {
                props.onChange({ name: 'constructionDate', value: undefined });
              }
            }}/>
        </Item>
      </Col>
      <Col {...perfColumns}>
        <Item label="Performance calculations">
          <Switch
          checked={props.inputs.performanceCalculations}
          onChange={(checked: boolean) => { props.onChange({ name: 'performanceCalculations', value: checked }); }}
          />
        </Item>
      </Col>
    </Row>
    <Row>
      <Col {...descrColumns}>
        <Item label="Description">
          <TextArea name="description" rows={4} value={props.inputs.description} onChange={onChange}/>
        </Item>
      </Col>
      <Col {...mixColumns} style={{ paddingLeft: '0px', paddingRight: '0px'}}>
        <Row>
          <Col {...accubalanceColumns}>
            <Item label="Accubalance measurements">
              <Switch
              checked={props.inputs.accubalance}
              onChange={(checked: boolean) => { props.onChange({ name: 'accubalance', value: checked }); }}
              />
            </Item>
          </Col>
          <Col {...smokeColumns}>
            <Item label="Smoke gas analysis">
              <Switch
              checked={props.inputs.smokeGas}
              onChange={(checked: boolean) => { props.onChange({ name: 'smokeGas', value: checked }); }}
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Col {...blowerColumns}>
            <Item label="Blower door measurements">
              <Switch
              checked={props.inputs.blowerdoor}
              onChange={(checked: boolean) => { props.onChange({ name: 'blowerdoor', value: checked }); }}
              />
            </Item>
          </Col>
          <Col {...plansColumns}>
            <Item label="Plans">
              <Switch
                checked={props.inputs.plans}
                onChange={(checked: boolean) => { props.onChange({ name: 'plans', value: checked }); }}
              />
            </Item>
          </Col>
        </Row>
      </Col>
    </Row>
  </Form>
</>
} 

export default CreateBuildingForm;