import { useState, useEffect, useCallback } from 'react';
import { isConnectionChangeEvent } from '../utils/ConnectionChangeEvent';

const useConnected = (): boolean => {
  const [online, setOnline] = useState<boolean>(true);
  const handleEvent = useCallback((event: Event) => {
    if (isConnectionChangeEvent(event)) {
      setOnline(event.connected);
    }
  }, [setOnline]);
  useEffect(() => {
    window.addEventListener("connectionchangeevent", handleEvent);
    return () => window.removeEventListener("connectionchangeevent", handleEvent);
  }, [handleEvent]);
  return online;
};

export default useConnected;