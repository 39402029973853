import React, { FC, useEffect } from 'react';
import { Layout, Typography, Row, Col, Popconfirm } from 'antd';

import { ProfileState, EditUserParams } from '../types';
import Text from '../../components/text.component';
import Label from '../../components/label.component';
import Menu from '../../core/components/menu.component';
import Loading from '../../components/loading.component';
import FloatRight from '../../components/float.right.component';
import EditProfileView from './edit.profile.view';
import { Container, Button } from '../../components';
import { UserRole } from '../../types';

const { Content } = Layout;
const { Title } = Typography;

type Props = ProfileState & {
  getMe: () => void
  toggleModal: () => void
  editMe: (param: EditUserParams) => Promise<void>
  resetPassword: () => Promise<void>
  downgradeMe: () => Promise<void>
}

const ProfileView: FC<Props> = ({
  user,
  loading,
  getMe,
  toggleModal,
  showModal,
  editMe,
  resetPassword,
  downgradeMe
}) => {
  const doesUserExist = !!user;
  useEffect(() => getMe(), [doesUserExist, getMe]);

  return <>
    <Layout>
      <Menu selected="profile" />
      <Content style={{ padding: '20px 50px' }}>
        <Loading spinning={loading}>
          <Row>
            <Col>
              <Title level={3}>
                {user && `${user.firstName} ${user.lastName}`}
              </Title>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                <Title level={4}>
                  General information
                </Title>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} lg={{ span: 18 }} xl={{ span: 18 }}>
                <FloatRight>
                  <Button onClick={() => toggleModal()}>
                    edit profile
                  </Button>
                  <Popconfirm
                      title="Are you sure you want to reset your password?"
                      onConfirm={async () => await resetPassword()}
                      okText="Yes"
                      cancelText="No"
                      placement="left"
                    >
                    <Button style={{marginLeft: '10px'}}>
                      reset password
                    </Button>
                  </Popconfirm>
                  { user && user.role === UserRole.admin &&
                    <Popconfirm
                      title="Are you sure you want to downgrade to an employee?"
                      onConfirm={async () => await downgradeMe()}
                      okText="Yes"
                      cancelText="No"
                      placement="left"
                    >
                      <Button style={{marginLeft: '10px'}}>
                        downgrade to employee
                      </Button>
                    </Popconfirm>
                  } 
                </FloatRight>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                <Row>
                  <Label>
                    ROLE
                  </Label>
                  <Text>
                    { user && user.role}
                  </Text>
                </Row>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                  <Row>
                    <Label>
                      EMAIL
                    </Label>
                    <Text>
                      { user && user.email }
                    </Text>
                  </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                  <Row>
                    <Label>
                      TELEPHONE
                    </Label>
                    <Text>
                      { user && user.telephone }
                    </Text>
                  </Row>
              </Col>
              <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                  <Row>
                    <Label>
                      KULID
                    </Label>
                    <Text>
                      { user && user.kulid }
                    </Text>
                  </Row>
              </Col>  
            </Row>
          </Container>
        </Loading>
        { user && 
          <EditProfileView 
            user={user}
            loading={loading}
            onEdit={editMe}
            onCancel={() => toggleModal()}
            visible={showModal}
          />
        }
      </Content>
    </Layout>
  </>;
} 



export default ProfileView;