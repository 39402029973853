import { Link } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import { TableItem } from './table';

type Props = {
  id: string
}

const Experiment: FunctionComponent<Props> = (props) => {

  return <>
    <Link to={`/experiment/${props.id}`}>
      <TableItem clickable={1}>
        {props.children}
      </TableItem>
    </Link>
  </>

}

export default Experiment;