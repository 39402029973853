import { UsersActionTypes } from './action.types';
import { UsersAction, UsersState, UsersPendingActions, UsersFailureActions } from './types';
import { Filter } from '../filter/types';
import { User } from '../types';

export const toggleModal = (): UsersAction<UsersActionTypes.ToggleModal> => ({
  type: UsersActionTypes.ToggleModal,
  reducer(state): UsersState {
    return {
      ...state,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: UsersPendingActions): UsersAction<UsersPendingActions> => ({
  type,
  reducer(state): UsersState {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: UsersFailureActions): UsersAction<UsersFailureActions> => ({
  type,
  reducer(state): UsersState {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const updateFilters = (filters: Filter[]): UsersAction<UsersActionTypes.UpdateFilter> => ({
  type: UsersActionTypes.UpdateFilter,
  reducer(state): UsersState {
    return {
      ...state,
      filters,
    }
  }
});

export const fetchSuccess = (users: User[]): UsersAction<UsersActionTypes.FetchSuccess> => ({
  type: UsersActionTypes.FetchSuccess,
  reducer(state): UsersState {
    return {
      ...state,
      users,
      loading: false
    }
  }
});

export const createSuccess = (user: User): UsersAction<UsersActionTypes.CreateSuccess> => ({
  type: UsersActionTypes.CreateSuccess,
  reducer(state): UsersState {
    return {
      ...state,
      loading: false,
      showModal: false,
      users: [user].concat(state.users),
    }
  }
});