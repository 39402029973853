import { BuildingActionTypes } from './action.types';
import { BuildingAction, BuildingStateRedux, BuildingPendingActions, BuildingFailureActions } from './types';
import { Building } from '../types';

export const toggleModal = (): BuildingAction<BuildingActionTypes.ToggleModal> => ({
  type: BuildingActionTypes.ToggleModal,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      showModal: !state.showModal
    };
  }
});

export const pending = (type: BuildingPendingActions): BuildingAction<BuildingPendingActions> => ({
  type,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      loading: true
    }
  }
});

export const failure = (type: BuildingFailureActions): BuildingAction<BuildingFailureActions> => ({
  type,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      loading: false,
      showModal: false,
    }
  }
});

export const fetchSuccess = (building: Building): BuildingAction<BuildingActionTypes.FetchSuccess> => ({
  type: BuildingActionTypes.FetchSuccess,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      building,
      loading: false
    }
  }
});

export const editSuccess = (building: Building): BuildingAction<BuildingActionTypes.EditSuccess> => ({
  type: BuildingActionTypes.EditSuccess,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      loading: false,
      showModal: false,
      building
    }
  }
});

export const deleteSuccess = (): BuildingAction<BuildingActionTypes.DeleteSuccess> => ({
  type: BuildingActionTypes.DeleteSuccess,
  reducer(state): BuildingStateRedux {
    return {
      ...state,
      loading: false,
      building: undefined
    }
  }
});