import React, { FC, useState } from 'react';
import { DataEntry } from '../../../types';
import useSWR, { mutate } from 'swr';

import { fetchDataEntries, delDataEntry, downloadData } from '../../../server';
import Loading from '../../../components/loading.component';
import { Container } from '../../../components/layout/container';
import { Row, Col, Icon, Popconfirm } from 'antd';
import { Title, Button, Table, TableHeader, TableHeaderItem, TableItem, IconButton } from '../../../components';
import FloatRight from '../../../components/float.right.component';
import moment from 'moment';
import { reportError } from '../../../core/utils';
import { DataUploadModal } from './data.modal';

const titleColumns = {
  xs: { span: 12 },
  sm: { span: 8 },
  md: { span: 6 },
  lg: { span: 4 },
  xl: { span: 3 },
}

const buttonColumns = {
  xs: { span: 24 },
  sm: { span: 16 },
  md: { span: 18 },
  lg: { span: 20 },
  xl: { span: 21 },
}

const nameColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 7 },
  lg: { span: 8 },
  xl: { span: 8 },
}

const createdColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 7 },
  lg: { span: 8 },
  xl: { span: 8 },
}

const actionColumn = {
  xs: { span: 8 },
  sm: { span: 8 },
  md: { span: 10 },
  lg: { span: 8 },
  xl: { span: 8 },
}

export type Props = {
  experimentUuid: string
}

export const Data: FC<Props> = (props) => {

  const [modalVisible, showModal] = useState<boolean>(false);
  const { data, isValidating } = useSWR<DataEntry[]>(`api/experiment/${props.experimentUuid}/data`, async () => fetchDataEntries(props.experimentUuid), { revalidateOnFocus: false });

  const deleteDataEntry = async (uuid: string) => {
    if (data) {
      try {
        await delDataEntry(props.experimentUuid, uuid);
        mutate(`api/experiment/${props.experimentUuid}/data`, data.filter(el => el.uuid !== uuid));
      } catch (error) {
        await reportError(error);
      }
    }
  };

  const downloadAll = async () => {
    if (data) {
      try {
        await Promise.all(data.map(dataEntry => downloadData(props.experimentUuid, dataEntry.uuid, dataEntry.name)));
      } catch (error) {
        await reportError(error);
      }
    }
  }

  const onAddDataEntry = async (dataEntry: DataEntry) => {
    mutate(`api/experiment/${props.experimentUuid}/data`, (data ?? []).concat([dataEntry]));
    showModal(false);
  }

  return <>
    <Loading spinning={isValidating}>
      <Container style={{ marginTop: '20px ' }}>

        <Row>
          <Col {...titleColumns}>
            <Title level={4}>Data</Title>
          </Col>
          <Col {...buttonColumns}>
            <FloatRight>
              <Button create={1} onClick={() => showModal(true)}>
                upload data
              </Button>
              { data && data.length > 1 &&
                <Button style={{ marginLeft: '10px' }} delete={1} onClick={async () => await downloadAll()}>
                  download all
                </Button>
              }
            </FloatRight>
          </Col>
        </Row>

        {
          data && data.length > 0 && 
          <Table>
            <TableHeader>
              <TableHeaderItem {...nameColumn}>
                NAME
              </TableHeaderItem>
              <TableHeaderItem {...createdColumn}>
                CREATED
              </TableHeaderItem>
              <TableHeaderItem {...actionColumn}>
                ACTION
              </TableHeaderItem>
            </TableHeader>
            <div>
              { data.map(dataEntry => (
                  <TableItem clickable={0} key={dataEntry.uuid} id={dataEntry.uuid}>
                    <Col {...nameColumn}>
                      {dataEntry.name}
                    </Col>
                    <Col {...createdColumn}>
                      {moment(Number(dataEntry.createdAt)).format('DD/MM/YYYY')}
                    </Col>
                    <Col {...actionColumn}>
                    <IconButton style={{ marginRight: "10px"}} onClick={async () => await downloadData(props.experimentUuid, dataEntry.uuid, dataEntry.name)}>
                      <Icon type="download" /> download
                    </IconButton>
                    <Popconfirm
                      title="Are you sure you want to delete this data?"
                      onConfirm={async () => await deleteDataEntry(dataEntry.uuid)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <IconButton>
                        <Icon type="delete" /> delete
                      </IconButton>
                    </Popconfirm>
                    </Col>
                  </TableItem>
                ))
              }
            </div>
          </Table>
        }

        {modalVisible &&
          <DataUploadModal
            expUuid={props.experimentUuid}
            onCancel={() => showModal(false)}
            onSubmit={onAddDataEntry}
          />
        }

      </Container>
    </Loading>
  </>
}
